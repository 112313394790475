export class NoticeBoard {    
    public id: string;
    public title: string;
    public description: string;
    public createdById: string; 
    public lastUpdatedDate: Date;
    public lastUpdatedBy: string;
    public urlKey: string;
    public expiredDate: Date;
    public businessId: string;
    public isArchived: boolean = false;
    public createdBy : createdBy;
    public createdDate: Date;
    public document: File;
    public postDocuments: File[];    
    public attchementName: string;
    public attchementURL: string;
    public attachments : attachments[];  
    public isPublic: boolean;
 }

 export class NoticeBoardEdit { 
    public description: string;
    public createdById: string; 
    public lastUpdatedBy: string;
    public urlKey: string;
    public expiredDate: Date;
    public title: string;
    public businessId: string;
    public attchementName: string;
    public attchementURL: string;
    public isPublic: boolean;
    public attachments : attachments[];

    public lastUpdatedDate: Date;
    public createdBy : createdBy;
    public createdDate: Date;
    public document: File;
    public postDocuments: File[];     
 }

 export class createdBy{
    public firstName: string;
    public id: string;
    public lastName: string;
}
 
export class attachments{
    public id: string;
    public name: string;
    public url: string;
    public displayName: string;
}

 export class NoticeBoardViewModel extends NoticeBoard{
    public businessId: string;
 }
 
 export class NoticeBoardEditModel extends NoticeBoardEdit{
    
 }