<div class="list-group list-group-flush borderside">    
    <img *ngIf="selectedMenuItem && selectedMenuItem.profileImageUrl !=null " [src]="selectedMenuItem.profileImageUrl" alt="" class="logosidebar1 img-responsive logoside_newe">
  <ul class="m-0 p-0 sidebar_ul d-flex flex-sm-row flex-md-column">
     
    <!-- [src]="selectedMenuItem.url" -->
      <li class="sidebar_z">
          <a routerLink="/dashboard" [queryParams]="{business: business_id}" class="sidebar_nav " routerLinkActive="active" >
            <i class="fa fa-podcast pl-1 pr-1"></i> 
            <span class="res_icon">Notice Board</span>
            <i *ngIf="path=='dashboard'" class="fa fa-angle-right float-right right_arrow_side" aria-hidden="true"></i>
          </a> 
      </li>

      <li class="mt-3 resp_sidebar sidebar_z">
        <!-- <li *ngIf="selectedMenuItem && selectedMenuItem.name != 'Fit For Life'" class="mt-3 resp_sidebar sidebar_z"> -->
            <a routerLink="/library" [class.active]="path=='document'" [queryParams]="{business: business_id}"
             routerLinkActive="active" class="sidebar_nav mt-1 "> 
                <i class="fa fa-file-text-o pl-1 pr-1"></i>
                <span class="res_icon">Documents</span> 
                <i *ngIf="path=='library' || path=='document'" class="fa fa-angle-right float-right right_arrow_side" aria-hidden="true"></i>
            </a>
      </li>   

      <li *ngIf="viewPortalPermission('ViewMyFortressAdmin') && selectedMenuItem && selectedMenuItem.name==businessName" class="mt-3 resp_sidebar sidebar_z">
          <a href="javascript:void(0);" (click)="openRedirectDialog(template99);" class="sidebar_nav">
              <i class="fa fa-television pl-1 pr-1"></i> 
                  <span class="res_icon">Client App Portal</span>
                  <!-- <i *ngIf="path=='document-1234'" class="fa fa-angle-right float-right right_arrow_side" aria-hidden="true"></i> -->
          </a>           
      </li>                    
      
      <!-- *ngIf="selectedMenuItem.name != 'Fit For Life'" -->
      <!--<ng-container *ngIf="selectedMenu && !selectedMenu.isNoticesReadOnly">-->
        <ng-container *ngIf="selectedMenu">
        <li  class="mt-4 mr-4 pt-4 sidebar_trash resp_sidebar sidebar_z"> 
            <a routerLink="/trash" [queryParams]="{business: business_id}" class="sidebar_nav " routerLinkActive="active">
                <i class="fa fa-trash-o pl-1 pr-1" aria-hidden="true"></i>
                    <span class="res_icon">Trash</span>
                <!-- <i class="fa fa-chevron-right side_drop mr-0" aria-hidden="true"></i> -->
                <i *ngIf="path=='trash'" class="fa fa-angle-right float-right right_arrow_side_t" aria-hidden="true"></i>
            </a> 
        </li>
      </ng-container>
  </ul>
</div>  

<ng-template #template99>
    <!-- <div class="modal-body text-center pb-0">
        <h4 class="del_text font-weight-bold">Do you want to delete this folder?</h4>
    </div>
    <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-4" >
        <button (click)="deleteFolder()" type="button" class="clr_button_delete font-weight-bold" style="font-size: 12px;">YES,DELETE</button> &nbsp; OR &nbsp;
        <button (click)="decline()" type="button" class="pl-0 pr-0 nb_can_btn"  data-dismiss="modal">CANCEL</button>
    </div> -->

    <!-- <div class="modal fade" id="clientapp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content"> -->
                <div class="modal-body text-center">
                    <h4 class="clientapp_text font-weight-normal">{{currentUser.userName}}, You are about to visit MyFortress Portal</h4> 
                </div>
                <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-5">
                    <a href="{{portalUrl+'/dashboard'}}?business_id={{business_id}}" target="_blank" (click)="decline();" class="btn clr_button trash_bn font-weight-normal" style="font-size: 12px;">YES, PROCEED</a> &nbsp; OR &nbsp;
                    <!-- <button (click)="gotoPortal();" type="button" class="btn clr_button trash_bn font-weight-bold" style="font-size: 12px;">YES, PROCEED</button> &nbsp; OR &nbsp; -->
                    <button (click)="decline();" type="button" class="pl-0 pr-0 nb_can_btn" >CANCEL</button>
                </div>
            <!-- </div>
        </div>
    </div> -->

</ng-template>