import { Component, OnInit ,AfterViewInit  } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit, AfterViewInit  {

  constructor(private authService: AuthService,
    private router: Router,    

    private route: ActivatedRoute,) { }

  ngOnInit(): void {
  //  let isAutheniticated=this.authService.isAuthenticated()  
  //   if(isAutheniticated){
  //     this.authService.redirectIfAuth();
  //   }else{
      
  //   }
  }
  ngAfterViewInit(): void {
    this.authService.logout();
    //this.router.navigate(['/login']);
  }

}
