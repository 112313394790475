<form [formGroup]="changePasswordForm" novalidate class="form " (ngSubmit)="onSubmit()" >
    <div class="modal-content">
        <div class="modal-header border-bottom-0 pb-0">                   
            <button type="button" class="close" data-dismiss="modal" (click)="modalRehide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body pt-0 pb-0 pl-4 pr-4">
            <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">Update password</h4>
        
            <div class="form-group">
                <label for="recipient-oldPassword" class="col-form-label d-flex font-weight-normal">Current Password</label>
                <!-- <div _ngcontent-lut-c7="" class="input-group"> -->
                    <input matInput type="password" required formControlName="oldPassword" [(ngModel)]='passwordChangeModel.oldPassword' class="form-control" id="oldPassword" placeholder="Type here">
                    <!-- <div _ngcontent-sff-c11="" class="input-group-prepend" (click)="hide1 = !hide1" >
                        <span _ngcontent-sff-c11="" class="input-group-text" style="background-color: #ced4da !important;">
                            <i _ngcontent-sff-c11=""  [class]="hide1 ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                        </span>
                    </div>
                </div> -->
                <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.oldPassword.errors.required">Current password is required</div>
                    <!-- <div *ngIf="f.oldPassword.errors.minlength" >Current password must contain at least 8 characters. </div> 
                    <div *ngIf="f.oldPassword.errors.maxlength" >Current password must be below 25 characters. </div>  -->
                </div>  
            </div>

            <div class="form-group">
                <label for="message-newPassword" class="col-form-label d-flex font-weight-normal">New Password</label>
                <div _ngcontent-lut-c7="" class="input-group">
                    <input [type]="hide2 ? 'password' : 'text'" required minlength="8" maxlength="25" formControlName="newPassword" [(ngModel)]='passwordChangeModel.newPassword' class="form-control border-right-0 bg-white ng-pristine ng-invalid ng-touched" id="newPassword" placeholder="Type here">
                    <div _ngcontent-sff-c11="" class="input-group-prepend" (click)="hide2 = !hide2" >
                        <span _ngcontent-sff-c11="" class="input-group-text" style="background-color: #ffffff !important;">
                            <i _ngcontent-sff-c11=""  [class]="hide2 ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.newPassword.errors.required">New password is required</div>
                    <div *ngIf="f.newPassword.errors.minlength" > New password must contain at least 8 characters.</div>  
                    <div *ngIf="f.newPassword.errors.maxlength" > New password must be below 25 characters.</div> 
                </div>
                 
            </div>

            <div class="modal-footer justify-content-start border-top-0 nb-footer pb-4" style="margin-left: -22px;">
                <div class="form-group">
                    <div *ngIf="!Isvalid"   class="form_alert">
                            <span  class="ft-alert-circle"></span>
                            Please fill in all the required fields correctly.
                    </div>
                    <div *ngIf="errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                        {{errors}}
                    </div>
                </div>
                <button class="btn clr_button upload_button font-weight-normal text-uppercase" style="font-size: 12px;border-radius: 100px; padding: 7px 15px;">Save</button> &nbsp; OR &nbsp;                    
                <button type="button" class="btn pl-0 pr-0 nb_can_btn text-uppercase" (click)="modalRehide()" data-dismiss="modal">Cancel</button>
            </div>

        </div>
    </div>
</form>
<ngx-ui-loader  ></ngx-ui-loader>