<div class="list-group list-group-flush borderside">
        <ul class="m-0 p-0 sidebar_ul d-flex flex-sm-row flex-md-column">
            <li>
                <a routerLink="/edit-profile" [queryParams]="{business: 'edit-profile'}" class="sidebar_nav" routerLinkActive="active" >
                    <i class="fa fa-user-circle-o  pl-1 pr-1"></i> 
                    <span class="res_icon">General</span>
                    <i *ngIf="path=='edit-profile'" class="fa fa-angle-right float-right right_arrow_side" aria-hidden="true"></i>
                </a> 
            </li>
      
            <li class="mt-3 resp_sidebar">
                <a routerLinkActive="active" routerLink="/account" routerLinkActive="active" [queryParams]="{business: 'edit-profile'}" class="sidebar_nav mt-1 "> 
                  <i class="fa fa-address-card-o pl-1 pr-1"></i>
                  <span class="res_icon">Account</span> 
                  <i  *ngIf="path=='account'" class="fa fa-angle-right float-right right_arrow_side" aria-hidden="true"></i>

              </a>
            </li> 
        </ul>
</div>  