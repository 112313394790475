import { Injectable, ɵLocaleDataIndex } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
//import { element } from '@angular/core/src/render3';
import { strictEqual } from 'assert';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User, VerifiedUser, LogIn } from '../shared/models/user.model';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { Response } from '../shared/common/response';
// import {MessagingService} from "../shared/messaging.service";
import { CookieService } from 'ngx-cookie-service';
import { Domain } from 'domain';
import * as jwt_decode from 'jwt-decode';
import { ChangePasswordResponse } from '../shared/models/response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  baseUrl: String;
  authUrl: String;
  domain: string;

  jwtHelper = new JwtHelperService();
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public LogIn:  LogIn;

  constructor(
    private http: HttpClient, 
    private cookieService: CookieService,
    private router: Router,
    ) {
    this.baseUrl = environment.getBaseURL;
    this.authUrl = environment.getAuthURL;
    this.domain = environment.getAuthDomain;
    
    //this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    //this.currentUser = this.currentUserSubject.asObservable()

    localStorage.setItem('accessmfa', '0');
    this.cookieService.set('accessmfa','0');
    let user = new User(); //{'userName': currentUserCookie.userName,'userType': "1"};
    this.currentUserSubject = new BehaviorSubject<User>(user);
    user.userName = cookieService.get('userName');
    user.userType = parseInt(cookieService.get('userType'));
    if(user.userName){
      this.currentUserSubject = new BehaviorSubject<User>(user);
      this.currentUser = this.currentUserSubject.asObservable()
    }

  }

  public get getCurrentUserValue(): User {
  // console.log(this.currentUserSubject? this.currentUserSubject.value :null);
    return this.currentUserSubject.value ;
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('currentUserToken');
    // Check whether the token is expired and return true or false
    return !this.jwtHelper.isTokenExpired(token);
  }


  

  Login(login :LogIn) {

    return this.http.post<any>(this.authUrl + '/Token', login).pipe(map(data => {
      localStorage.removeItem('currentUserToken');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUser1');
      localStorage.removeItem('CurrentUserId');
      localStorage.removeItem('fireBaseToken');
      localStorage.removeItem('accessmfa');

      this.cookieService.delete('currentUserToken');
      this.cookieService.delete('userName');
      this.cookieService.delete('userType');
      this.cookieService.delete('currentUser');
      this.cookieService.delete('CurrentUserId');
      this.cookieService.delete('companyCode');
      this.cookieService.delete('companyId');

      if (data.isSuccess) {
        console.log(data);
        if(data.data && data.data.id)
        //localStorage.setItem('LoginName', JSON.stringify(userName));
        //var currentUser = {"userName":userName, "userType":1};
        localStorage.setItem('currentUser', JSON.stringify(data.data));
        localStorage.setItem('CurrentUserId', JSON.stringify(data.data.id));

        let islogged = this.cookieService.get('loggedIn');
        let accessmfa = this.cookieService.get('accessmfa');

        let host = this.domain;

        this.cookieService.set('logindataset',data.data)
        this.cookieService.set( 'companyId', data.data.companyId, 365,'/',host );
        this.cookieService.set( 'companyCode', login.companyCode, 365,'/',host );
        this.cookieService.set( 'CurrentUserId', data.data.id, 365,'/',host );
        let currentUserCookie = data.data;
        this.cookieService.set( 'currentUser', currentUserCookie, 365,'/',host );
        this.cookieService.set( 'firstName', currentUserCookie.firstName, 365,'/',host );
        this.cookieService.set( 'userName', currentUserCookie.firstName, 365,'/',host );
        this.cookieService.set( 'lastName', currentUserCookie.lastName, 365,'/',host ); 

        
        // this.cookieService.set( 'loggedIn', islogged, 365,'/',host );
        // this.cookieService.set( 'accessmfa', accessmfa, 365,'/',host );

        this.cookieService.set( 'userType', "1", 365,'/',host );
        this.cookieService.set( 'currentUserToken', data.authToken, 365,'/',host );

        let user = new User();
        user.userName = currentUserCookie.firstName;
        user.userType = 1;
        
        this.currentUserSubject = new BehaviorSubject<User>(user);


      } else{
        return data;
      }
    return data;
    }));
  }

  verifyUser(accessCode: String,userId:String) {  
    return this.http.post<any>(this.baseUrl + '/Token/VeryfyAccessCode',{ "userId": userId,"accessCode": accessCode} ).pipe(map(verifiedUser => {       
      // if (verifiedUser.token) { "userId": "7hzYMOgGrUqsRAAAzvw63w",  "accessCode": "1865"
      //   console.log(verifiedUser) 
      //   localStorage.setItem('currentUserToken', JSON.stringify(verifiedUser.token));
      //   //localStorage.setItem('currentUser', JSON.stringify(verifiedUser.authUser));   
      //   this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      //   localStorage.setItem('CurrentUserId', JSON.stringify(verifiedUser.authUser.id));
      // }

      if (verifiedUser.success && verifiedUser.response.token) {
        //console.log(verifiedUser) 
        localStorage.setItem('loggedIn', '1');
        
        // this.cookieService.set('loggedIn','1');
        let currentUser = localStorage.getItem('currentUser1');
        localStorage.setItem('currentUser', currentUser);
        localStorage.setItem('currentUserToken', JSON.stringify(verifiedUser.response.token));
       // this.messagingService.requestPermission(userId);
        //localStorage.setItem('currentUser', JSON.stringify(verifiedUser.authUser));   
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        
        //localStorage.setItem('CurrentUserId', JSON.stringify(verifiedUser.authUser.id));
      } else{
        return verifiedUser;
      }

      return verifiedUser;
    }));
  }

  appInstallation(firebase_token: String,UserId: String){
    //console.log(100000000000000000);
    //const params = new HttpParams().set('fireBaseToken', firebase_token).set('UserId', UserId).set('operatingSystem', '3').set('version', 'v1'); 
    return this.http.post<any>(this.baseUrl + '/AppInstallation',{'fireBaseToken': firebase_token,'UserId': UserId,'operatingSystem': '3','version': 'v1'} ).pipe(map(appinstallation => { 
      return appinstallation;  
    }));
  }
  

  logout() {
    // remove user from local storage to log user out
    //"userId": "string",
    //"fireBaseToken": "string"
    //console.log(555555555);
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('accessmfa');
    localStorage.removeItem('currentUserToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('CurrentUserId');
    localStorage.removeItem('fireBaseToken');
    localStorage.removeItem('currentUser1');
    localStorage.removeItem('menuList');
    localStorage.removeItem('departmentList');
    localStorage.removeItem('mfastatus');
    localStorage.removeItem('selectedMenu');
    localStorage.removeItem('mfastatus');

    this.cookieService.delete('currentUserToken');
      this.cookieService.delete('userName'); 
      this.cookieService.delete('userType');   
      this.cookieService.delete('currentUser');
      this.cookieService.delete('CurrentUserId');
      this.cookieService.delete('firstName');
      this.cookieService.delete('lastName');
      this.cookieService.delete('companyCode');
      this.cookieService.delete('companyId');
      this.cookieService.delete('loggedIn');
      this.cookieService.delete('accessmfa');

      this.cookieService.deleteAll();

      this.cookieService.delete('currentUserToken','/', this.domain );
      this.cookieService.delete('userName','/', this.domain );
      this.cookieService.delete('userType','/', this.domain );
      this.cookieService.delete('currentUser','/', this.domain );
      this.cookieService.delete('CurrentUserId','/', this.domain );
      this.cookieService.delete('firstName','/', this.domain );
      this.cookieService.delete('lastName','/', this.domain );
      
      this.cookieService.delete('loggedIn','/', this.domain );
      this.cookieService.delete('accessmfa','/', this.domain );

      window.location = window.location;
      // this.router.navigate(['dashboard']);
  // let firebase_token = localStorage.getItem('fireBaseToken');
  // let UserId = JSON.parse(localStorage.getItem('CurrentUserId'));
  // return this.http.post<any>(this.baseUrl + '/Token/Logout',{'fireBaseToken': firebase_token,'UserId': UserId} ).pipe(map(logout => { 
  //   localStorage.removeItem('currentUserToken');
  //   localStorage.removeItem('currentUser');
  //   localStorage.removeItem('CurrentUserId');
  //   localStorage.removeItem('fireBaseToken');
  //   localStorage.removeItem('currentUser1');

  //   this.cookieService.delete('currentUserToken');
  //     this.cookieService.delete('userName'); 
  //     this.cookieService.delete('userType');
  //     this.cookieService.delete('currentUser');
  //   //console.log(66666666);

  //   return logout;  
  // }));
  
   
    //his.currentUserSubject.next(null);
  }


  clean() {
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('accessmfa');
    localStorage.removeItem('currentUserToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('CurrentUserId');
    localStorage.removeItem('fireBaseToken');
    localStorage.removeItem('currentUser1');
    localStorage.removeItem('menuList');
    localStorage.removeItem('departmentList');
    localStorage.removeItem('selectedMenu');
    localStorage.removeItem('mfastatus');

    this.cookieService.delete('currentUserToken');
      this.cookieService.delete('userName'); 
      this.cookieService.delete('userType');   
      this.cookieService.delete('currentUser');
      this.cookieService.delete('CurrentUserId');
      this.cookieService.delete('firstName');
      this.cookieService.delete('lastName');
      this.cookieService.delete('companyCode');
      this.cookieService.delete('companyId');
      this.cookieService.delete('loggedIn');
      this.cookieService.delete('accessmfa');
      this.cookieService.deleteAll();

      this.cookieService.delete('currentUserToken','/', this.domain );
      this.cookieService.delete('userName','/', this.domain );
      this.cookieService.delete('userType','/', this.domain );
      this.cookieService.delete('currentUser','/', this.domain );
      this.cookieService.delete('CurrentUserId','/', this.domain );
      this.cookieService.delete('firstName','/', this.domain );
      this.cookieService.delete('lastName','/', this.domain );
      
      this.cookieService.delete('loggedIn','/', this.domain );
      this.cookieService.delete('accessmfa','/', this.domain );

  }
   


   
 currentUserClaims(){
   var tokenObj = localStorage.getItem('currentUserToken');
 }

//  ResetPassword(userName: String) {
//   return this.http.post<any>(this.baseUrl + '/User/ForgetPassword', {userName}).pipe(map(data => {
    
//     }));
//   }

  ResetPassword(userName: string) {  
    const params = new HttpParams().set('userName', userName); 
    return this.http.get<any>(this.authUrl+'/User/ForgotPassword?userName='+userName,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public handleError(error) {
    return throwError(error);
  }

  public get userToken(){
    return this.cookieService.get('currentUserToken');
    //return JSON.parse(localStorage.getItem('currentUserToken'));
  }

  getMenuList(){
    return this.http.get<any>(this.baseUrl + '/Businesss').pipe(map(data => {
      localStorage.removeItem('menuList');      
      if (data.success) {
        if(data.response)       
        localStorage.setItem('menuList', JSON.stringify(data.response));
      } else{
        return data;
      }
    return data;
    }));
  }

  getDepartmentList(companyId){
    //GetAllDepartments
    const params = new HttpParams().set('companyId', companyId); 
    //return this.http.get<any>(this.authUrl + '/Department/GetByCompanyId',{params}).pipe(map(data => {
    return this.http.get<any>(this.authUrl + '/Department/GetAllDepartments',{params}).pipe(map(data => {
        localStorage.removeItem('departmentList');      
      if (data.isSuccess) {
        if(data.data) { localStorage.setItem('departmentList', JSON.stringify(data.data));}      
        
      } else{
        return data;
      }
    return data;
    }));
  }

  redirectIfAuth(){    
    let menuList = JSON.parse(localStorage.getItem('menuList'));
    let mfaStatus=JSON.parse(localStorage.getItem('mfastatus'));
    if(menuList[0] && menuList[0].id){
      //console.log(58963);      
      //console.log(menuList);   
if(mfaStatus===1){
  this.router.navigate(['dashboard'], { queryParams: { business: menuList[0].id }});
}else{
  this.router.navigate(['twofactorsettings'], { queryParams: { business: menuList[0].id }});
}
      
    }  
  }

  redirectIfAuthWithoutMFA(){    

    let menuList = JSON.parse(localStorage.getItem('menuList'));
    if(menuList[0] && menuList[0].id){
      //console.log(58963);      
      console.log("hi" ,menuList);      
      this.router.navigate(['twofactorsettings'], { queryParams: { business: menuList[0].id }});
    }  
  }

  getMenuName(business_id){
    let menuList = JSON.parse(localStorage.getItem('menuList'));
    let item = menuList.find(x => x.id == business_id);
    if(item){
      return item.name;
    }
    return "";
  }

  viewPortalPermission(permission,business_id){
    let token = this.userToken;
    const decoded = jwt_decode(token);
    //console.log("====decoded====0");
    //console.log(decoded[permission]);

    if (decoded === undefined || decoded.scope=="") return null;
    let token_decoded = decoded;
    if (token_decoded[permission] === undefined || token_decoded[permission]=="") return null;

    // console.log(token_decoded);
    // console.log("====decoded====1");
    
    // console.log("====decoded====2");
    if( typeof token_decoded == 'string') return null;
    let search = token_decoded[permission].split(",");

    // console.log("====search====");
    // console.log(permission);
    // console.log(search);

    if(permission=="Head_of_Department"){
      if(search.length >0){
        return true;
      }
    }   

    let menuList = JSON.parse(localStorage.getItem('menuList'));
    let item = menuList.find(x => x.id == business_id);
    // console.log(item);
    // console.log("====item====1");

    if (!item) return null;

    //console.log(search.find(x => x.trim() == item.code));
    return search.find(x => x.trim() == item.code);
  }

  updatePassword(passwordChangeModel){
    passwordChangeModel.userId = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    
    return this.http.post<ChangePasswordResponse>(this.authUrl +'/User/PassowrdUpdate', passwordChangeModel).pipe(
      catchError(this.handleError)
    ); 
  }

}


