import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from './auth/login/login.component';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { Globals } from './utility/globals';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
//import { MessagingService } from './shared/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { Component } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LottieAnimationViewModule } from 'ng-lottie';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule } from 'ngx-ui-loader';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  overlayColor: 'rgba(255,255,255,.66)'
};

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContainerComponent } from './container/container.component';
//import { LayoutRoutingComponent } from './layout-routing/layout-routing.component';
import { SidebarComponent } from './sidebar/sidebar.component';
//import { PasswordNewComponent } from './auth/password-new/password-new.component';
import { TrashComponent } from './trash/trash.component';
import { DataTablesModule } from 'angular-datatables';
import { LibraryComponent } from './library/library.component';
import { DocumentComponent } from './document/document.component';
import { PostComponent } from './post/post.component';
import { NoticeBoardComponent } from './notice-board/notice-board.component';
import { NoticeBoardDetailsComponent } from './notice-board-details/notice-board-details.component';
import { SendCodeComponent } from './auth/send-code/send-code.component';
import { CookieService } from 'ngx-cookie-service';
//import { ListViewComponent } from './notice-board/list-view/list-view.component';

import { AddLibraryComponent } from './library/add-library/add-library.component';
import { EditLibraryComponent } from './library/edit-library/edit-library.component';
import { JwtInterceptorService } from './shared/jwt-interceptor.service';
import { ErrorInterceptor } from './shared/error.interceptor';
import { AddDocumentComponent } from './document/add-document/add-document.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { AddNoticeComponent } from './notice-board/add-notice/add-notice.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { SidebarProfileComponent } from './sidebar-profile/sidebar-profile.component';
import { AccountComponent } from './edit-profile/account/account.component';
import { SanitizeHtmlPipe } from './utility/sanitize-html.pipe';
import { DateAgoPipe } from './utility/date-ago.pipe';
import { LimitToPipe } from './utility/limit-to.pipe';
import { HttpModule } from '@angular/http';
import { FavouritesComponent } from './favourites/favourites.component';
import { AddFavouiteComponent } from './favourites/add-favouite/add-favouite.component';
import { ViewDocumentComponent } from './document/view-document/view-document.component';
//import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxDocViewerComponent } from './document/document-viewer/document-viewer.component';
import { CalendarComponentView } from './out-of-office/calendar/calendar.component';
import { LeaveComponent } from './out-of-office/leave/leave.component';
import { SidebarOfficeComponent } from './out-of-office/sidebar-office/sidebar-office.component';
import { LeaveListComponent } from './out-of-office/leave-list/leave-list.component';
import { SidebarAdminpanelComponent } from './out-of-office/sidebar-adminpanel/sidebar-adminpanel.component';
import { LeaveCalanderComponent } from './out-of-office/leave-calander/leave-calander.component';
import { AddLeaveComponent } from './out-of-office/leave/add-leave/add-leave.component';
import { MomentModule } from 'ngx-moment';
import { TruncatePipe } from './utility/limitTo';
//import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
//import { FullCalendarModule } from 'ng-fullcalendar';

import { FullCalendarModule } from 'ng-fullcalendar';
//import { PopoverComponent } from './popover/popover.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { AddSubFolderComponent } from './sub-document/add-sub-folder/add-sub-folder.component';
import { SubDocumentComponent } from './sub-document/sub-document.component';
import { AddSubDocumentComponent } from './sub-document/add-sub-document/add-sub-document.component';
import { AddFolderComponent } from './document/add-folder/add-folder.component';
import { MultifactorModalComponent } from './auth/multifactor-modal/multifactor-modal.component';
import { MaltifactorAuthComponent } from './settings/maltifactor-auth/maltifactor-auth.component';
import { SetingsidebarComponent } from './settings/setingsidebar/setingsidebar.component';
import { LogoutComponent } from './auth/logout/logout.component';
//import { AddLinkComponent } from './document/add-link/add-link.component';
//import { FilePondModule, registerPlugin } from 'ngx-filepond';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// registerPlugin(FilePondPluginFileValidateType);


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PasswordResetComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    ContainerComponent,
    //LayoutRoutingComponent,
    SidebarComponent,
    //PasswordNewComponent,
    TrashComponent,
    LibraryComponent,
    DocumentComponent,
    PostComponent,
    NoticeBoardComponent,
    NoticeBoardDetailsComponent,
    SendCodeComponent,
    //ListViewComponent,
    AddLibraryComponent,
    EditLibraryComponent,
    AddDocumentComponent,
    AddNoticeComponent,
    ImageCropperComponent,
    NotFoundComponent,
    EditProfileComponent,
    SidebarProfileComponent,
    AccountComponent,
    SanitizeHtmlPipe,
    DateAgoPipe,
    LimitToPipe,
    FavouritesComponent,
    AddFavouiteComponent,
    ViewDocumentComponent,
    NgxDocViewerComponent,
    CalendarComponentView,
    LeaveComponent,
    SidebarOfficeComponent,
    LeaveListComponent,
    SidebarAdminpanelComponent,
    LeaveCalanderComponent,
    AddLeaveComponent,
    TruncatePipe,
    ChangePasswordComponent,
    AddSubFolderComponent,
    SubDocumentComponent,
    AddSubDocumentComponent,
    AddFolderComponent,
    MultifactorModalComponent,
    MaltifactorAuthComponent,
    SetingsidebarComponent,
    LogoutComponent,
    //AddLinkComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule,
    // AngularFireMessagingModule,
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    //AngularFireModule.initializeApp(environment.firebase),
    NgSelectModule,
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    LottieAnimationViewModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), 
    //TruncatePipe,
    BrowserAnimationsModule,
    DataTablesModule,
    RichTextEditorAllModule,
    HttpModule,
    MomentModule,
    FullCalendarModule, // for FullCalendar!
    //FilePondModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Globals, AsyncPipe, CookieService, BsModalService, BsModalRef
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddLibraryComponent,
    AddDocumentComponent,
    AddNoticeComponent,
    AddFavouiteComponent,
    ViewDocumentComponent,
    AddLeaveComponent,
    ChangePasswordComponent,
    AddSubFolderComponent,
    AddFolderComponent,
    MultifactorModalComponent,
    //AddLinkComponent
  ]
})
export class AppModule { }
