<div class="scroll_post pl-3 pt-0 mt-3">
    <div class="row pt-4 mb-4">
        <div class="media nb_width">
            <div class="media-left pr-1">
                <a href="#">
                    <span class="avatar avatar-online"><img src="../../assets/img/user.png" alt="avatar" style="height: 50px; margin-left: 1rem; margin-right: 15px;"></span>
                </a>
            </div>
            <div class="media-body">
                <p class="float-left mb-0 font-weight-bold">
                    Louie, Leave a reply
                </p>
                <textarea class="w-100 mt-2 text_area_s" name="" id="" cols="30" rows="5"></textarea>
                <p>
                    <a href="" class="float-right btn-sm btn-color ml-2 mt-2">Post</a>
                </p>
            </div>
        </div>
    </div>
        
    <div class="row mt-5">
        <div class="media">
            <div class="media-left pr-1">
                <a href="#">
                    <span class="avatar avatar-online"><img src="../../assets/img/user.png" alt="avatar" style="height: 50px; margin-left: 1rem; margin-right: 15px;"></span>
                </a>
            </div>
            <div class="media-body">
                <p class="float-left mb-0 font-weight-bold">Jason Ansley&nbsp;</p>
                <br class="nb_br">
                <span class="f_size">2 min ago</span>
                <p class="float-right mb-1 fist_latest">
                    <span class="lates_r">Latest Replies First</span>
                    <span class="float-right latest_icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                </p>
                <p class="p_fs mt-1">Lorem Ipsum is simply dummy text of the pr make but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="media">
            <div class="media-left pr-1">
                <a href="#">
                    <span class="avatar avatar-online"><img src="../../assets/img/user.png" alt="avatar" style="height: 50px; margin-left: 1rem; margin-right: 15px;"></span>
                </a>
            </div>
            <div class="media-body">
                <p class="float-left mb-0 font-weight-bold">Jason Ansley&nbsp;</p>
                <span class="f_size">2 min ago</span>
                <p class="p_fs mt-1">Lorem Ipsum is simply dummy text of the pr make but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="media">
            <div class="media-left pr-1">
                <a href="#">
                    <span class="avatar avatar-online"><img src="../../assets/img/user.png" alt="avatar" style="height: 50px; margin-left: 1rem; margin-right: 15px;"></span>
                </a>
            </div>
            <div class="media-body">
                <p class="float-left mb-0 font-weight-bold">Jason Ansley&nbsp;</p>
                <span class="f_size">2 min ago</span>
                <p class="p_fs mt-1">Lorem Ipsum is simply dummy text of the pr make but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="media">
            <div class="media-left pr-1">
                <a href="#">
                    <span class="avatar avatar-online"><img src="../../assets/img/user.png" alt="avatar" style="height: 50px; margin-left: 1rem; margin-right: 15px;"></span>
                </a>
            </div>
            <div class="media-body">
                <p class="float-left mb-0 font-weight-bold">Jason Ansley&nbsp;</p>
                <span class="f_size">2 min ago</span>
                <p class="p_fs mt-1">Lorem Ipsum is simply dummy text of the pr make but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
        </div>
    </div>
</div>