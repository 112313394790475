<!-- <div class="container-fluid pl-4 pr-4 mb-5">
    
    <div class="row">
        <div class="col-md-2" style="margin-top: 104px;">
        </div>
        <div class="col-md-10">
            <div class="content-header row mt-4 mb-2 header_namer">
                
                <div class="content-header-right text-md-right col-md-6 col-12 mt-2 headerbn">
                    <div class="btn-group">
                        <button type="button" (click)="openAddModal()"  class="btn btn-round font-weight-normal clr_button upload_button"> <i class="fa fa-plus fa-1x" aria-hidden="true"></i> &nbsp; Add favourite link</button>
                    </div>
                    
                </div>
            </div>
            <div class="table-responsive tbl_res pt-3 doc_sm_tb">
                
                <table *ngIf="isLoaded"  class="table " style="width: 100%;" id="mydatatable">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Url</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let favourite of favList; let i = index">
                        <tr > 
                            <td class="table_new_data" title="{{ favourite.name }}">                                 
                                &nbsp;  {{favourite.name | limitTo : '100'}} 
                            </td>
                            <td class="table_datan" title="{{ favourite.url }}">
                                {{ favourite.url  | limitTo : '100' }}
                            </td>                            
                            <td class="btn_table table_datan">
                                <button type="button" (click)="openDeleteDialog(template,favourite)" class="btn btntabel_icon"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                            </td>
                        </tr>
                        </ng-container>
                        
                    </tbody>
                </table>

            </div>
        </div>
    </div>
</div> -->

<ng-template #template>
        <div class="modal-body text-center">
                <h4 class="del_text font-weight-normal">Do you want to delete this favourite link?</h4>
            </div>
            <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-5" >
                <button (click)="deleteFavourite()" type="button" class="clr_button_delete font-weight-normal text-uppercase" style="font-size: 12px;">Delete</button> &nbsp; OR &nbsp;
                <button (click)="decline()" type="button" class="pl-0 pr-0 nb_can_btn text-uppercase"  data-dismiss="modal">Cancel</button>
            </div>
    </ng-template>


    <table class="table mb-0">
            <tbody>

                <ng-container *ngFor="let favourite of favList; let i = index">
                    <tr>
                        <td title="{{ favourite.name }}" class="pl-3 cf_text text-truncateX">
                            <!-- <img src="../../assets/img/news_doc.png" class="fvimg" alt=""> -->
                            




                            <div style="display: inline-flex;">
                                <!-- <img *ngIf="favourite && favourite.docType=='1'" src="../../assets/img/news_pdf.png" alt="pdf" class="fvimg">
                                <img *ngIf="favourite && favourite.docType=='4'" src="../../assets/img/news_doc.png" alt="doc" class="fvimg">
                                <img *ngIf="favourite && favourite.docType=='2'" src="../../assets/img/news_img.png" alt="img" class="fvimg">
                                <img *ngIf="favourite && favourite.docType=='3'" src="../../assets/img/news_link.png" alt="link" class="fvimg">
                                &nbsp;  -->
                                <i class="fa fa-caret-right favi_icon_n" aria-hidden="true" ></i>
                                <a target="__blank" class="fav_link wrap-class" (click)="openFavouriteUrl(favourite)" href="javascript:void(0);">{{favourite.name | limitTo : '1122'}} </a>
                                <i  class="fa fa fa-trash-o del-ico pull-right" (click)="openDeleteDialog(template,favourite)" style="visibility: visible;"></i>
                                <!-- <ng-container *ngIf="selectedMenu && !selectedMenu.isNoticesReadOnly">
                                <i  class="fa fa fa-trash-o cf_trash pull-right" (click)="openDeleteDialog(template,favourite)" style="visibility: visible;"></i>
                                </ng-container> -->
                            </div>

                            <!-- <img *ngIf="favourite && favourite.docType=='1'" src="../../assets/img/news_pdf.png" alt="pdf" class="fvimg">
                            <img *ngIf="favourite && favourite.docType=='4'" src="../../assets/img/news_doc.png" alt="doc" class="fvimg">
                            <img *ngIf="favourite && favourite.docType=='2'" src="../../assets/img/news_img.png" alt="img" class="fvimg">
                            <img *ngIf="favourite && favourite.docType=='3'" src="../../assets/img/news_link.png" alt="link" class="fvimglink">

                            &nbsp; 
                            
                            
                            <a target="__blank" class="fav_link" (click)="openFavouriteUrl(favourite)" href="javascript:void(0);">{{favourite.name | limitTo : '20'}} </a> -->
                            
                        </td>

                        <!-- <button type="button" (click)="openDeleteDialog(template,favourite)" class="btn btntabel_icon"><i class="fa fa-trash-o cf_trash" aria-hidden="true"></i></button> -->
<!-- 
                        <td class="text-right pr-3" (click)="openDeleteDialog(template,favourite)">
                            <i  class="fa fa fa-trash-o cf_trash" ></i>
                        </td> -->
                    </tr>
                </ng-container>   

                <ng-container *ngIf="isLoaded && favList.length == 0"> 
                    <img src="../../assets/img/empty_fav.png" alt="" class="img-fluid mx-auto d-block" style="height: 73px; margin-top: 25px; margin-bottom: 16px;">
                    <span class="mx-auto d-block" style="text-align: center;text-align: center; color: #464855;  font-size: 14px;  padding-bottom: 35px;">Currently No Favourites Added</span>
                </ng-container>

                <!-- <tr>
                    <td class="pl-3 cf_text"><i class="fa fa-caret-right cf_icon"></i> &nbsp; Calin</td>
                    <td class="text-right pr-3"><i class="fa fa fa-trash-o cf_trash" data-toggle="modal" data-target="#exampleModal"></i>
                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-body text-center">
                                        <h4 class="del_text font-weight-normal">Do you want to delete this item?</h4>
                                    </div>
                                    <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-5" >
                                        <button type="button" class="clr_button_delete font-weight-normal text-uppercase" style="font-size: 12px;">Delete</button> &nbsp; OR &nbsp;
                                        <button (click)="decline()" type="button" class="pl-0 pr-0 nb_can_btn text-uppercase"  data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="pl-3 cf_text"><i class="fa fa-caret-right cf_icon"></i> &nbsp; DCFS - Budget</td>
                    <td class="text-right pr-3"><i class="fa fa fa-trash-o cf_trash"></i></td>
                </tr>
                <tr>
                    <td class="pl-3 cf_text"><i class="fa fa-caret-right cf_icon"></i> &nbsp; DCFS - Isiplan</td>
                    <td class="text-right pr-3"><i class="fa fa fa-trash-o cf_trash"></i></td>
                </tr
                ><tr>
                    <td class="pl-3 cf_text"><i class="fa fa-caret-right cf_icon"></i> &nbsp; Management</td>
                    <td class="text-right pr-3"><i class="fa fa fa-trash-o cf_trash"></i></td>
                </tr>
                <tr>
                    <td class="pl-3 cf_text"><i class="fa fa-caret-right cf_icon"></i> &nbsp; MLC - Masterkey My Clients</td>
                    <td class="text-right pr-3"><i class="fa fa fa-trash-o cf_trash"></i></td>
                </tr>
                <tr>
                    <td class="pl-3 cf_text"><i class="fa fa-caret-right cf_icon"></i> &nbsp; NAB Margin Lending</td>
                    <td class="text-right pr-3"><i class="fa fa fa-trash-o cf_trash"></i></td>
                </tr>
                <tr>
                    <td class="pl-3 cf_text"><i class="fa fa-caret-right cf_icon"></i> &nbsp; Passwords - General</td>
                    <td class="text-right pr-3"><i class="fa fa fa-trash-o cf_trash"></i></td>
                </tr>
                <tr>
                    <td class="pl-3 cf_text"><i class="fa fa-caret-right cf_icon"></i> &nbsp; Phone Extension List - Dalle Cort</td>
                    <td class="text-right pr-3"><i class="fa fa fa-trash-o cf_trash"></i></td>
                </tr>
                <tr>
                    <td class="pl-3 cf_text"><i class="fa fa-caret-right cf_icon"></i> &nbsp; XPLAN</td>
                    <td class="text-right pr-3"><i class="fa fa fa-trash-o cf_trash"></i></td>
                </tr> -->
                
            </tbody>
        </table>    
        <!-- <ngx-ui-loader  ></ngx-ui-loader> -->