export const environment = {
  production: true,

  // test
  companyCode:'DCF01',
  getBusinessName:'My Fortress',
  commonCompanyCode:'DCF01',
  getBaseURL: 'https://api.staff.fortress.xigenix.com/api',
  getAuthURL: 'https://api.staffauth.fortress.xigenix.com/api',
  getPortalURL: 'https://clientportal.fortress.xigenix.com',
  getAuthDomain: '.fortress.xigenix.com',
};
