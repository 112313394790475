
<form [formGroup]="addFavouriteForm" novalidate class="form " (ngSubmit)="onSubmit()" >
        <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">                   
            <button type="button" class="close" data-dismiss="modal" (click)="modalRehide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>

            <div class="modal-body pt-0 pb-0 pl-4 pr-4">
            <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">Create new favourite</h4>
            
                <div class="form-group">
                <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Favourite Item Name</label>
                <input type="text" required formControlName="name" [(ngModel)]='favouriteModel.name' class="form-control" id="name" placeholder="Type here">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Name is required</div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="message-text" class="col-form-label d-flex font-weight-normal">Favourite Type</label>
                    <select id="documentType" formControlName="docType1" [(ngModel)]='favouriteModel.docType1' (change)="onFavouriteTypeChange($event.target.value)" class="form-control" >
                        <option value="0">Please select</option>
                        <option value="1">Link</option>
                        <option value="2">Document</option>
                    </select>
                    <input type="hidden" formControlName="docType" [(ngModel)]='favouriteModel.docType' >
                    <div *ngIf="submitted && f.docType.errors" class="invalid-feedback">
                        <div *ngIf="f.docType.errors.required">Favourite Type is required</div>
                    </div>
                </div>

                <!-- <select [attr.disabled]=" disableDocumentType ? '' : null" formControlName="docType" required id="documentType" (change)="onDocumentTypeChange($event.target.value)" [(ngModel)]='documentModel.docType' class="form-control" >
                        <option value="0">Please select</option>                      
                        <option value="1">PDF</option>
                        <option value="2">Image</option>
                        <option value="3">URL</option>
                        <option value="4">Doc</option>
                    </select> -->               
               
                <div class="form-group" *ngIf="showUrlField" >
                <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Link</label>
                <!-- pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$" -->
                <input type="text" required formControlName="url"  [(ngModel)]='favouriteModel.url' class="form-control" id="url" placeholder="https://www.sample.com">
                <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                        <div *ngIf="f.url.errors.required">Link is required</div>
                        <div *ngIf="f.url.errors.pattern">Link must be a valid site url</div>
                    </div>
                </div>

                <div class="form-group" *ngIf="showFilesearchField">
                        <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Search in 
                            <ng-container *ngIf="selectedMenu && selectedMenu.name" > 
                                ({{selectedMenu.name}}) 
                            </ng-container>                           
                            Documents</label>
                    <input type="text" class="form-control header_search" formControlName="searchText1" (click)="showOptionList()" (ngModelChange)="searchDocuments($event)" [(ngModel)]="searchStr" placeholder="Search documents..." >
                    <div class="search_b" *ngIf="searchText.length > 0 && showOptions">
                        <ul class="search_ul">
                            <ng-container *ngIf="3 > searchText.length "> 
                                <li class="search_pad text-center" style="list-style-type: none;font-size: 13px; color: #424242;">Please type at least 3 characters.</li>
                            </ng-container>
                            <ng-container *ngIf="!searchLoaded && searchText.length > 2 "> 
                                <li class="search_pad text-center" style="list-style-type: none;font-size: 13px; color: #424242;">Loading...</li>
                            </ng-container>
                            <ng-container *ngIf="searchText.length > 2 && searchLoaded && searchResults.length == 0"> 
                                <li class="search_pad text-center" style="list-style-type: none;font-size: 13px; color: #424242;">No result found</li>
                            </ng-container>
                            <ng-container *ngIf="searchLoaded && searchResults.length >0" > 
                                <ng-container *ngFor="let document of searchResults; let j = index">  

                                    <a href="javascript:void(0);" *ngIf="document && document.s3Key && !document.isFavorite" (click)="setDocumentDocument(document)" class="seach_a newaclr">                                           
                                    <span class="search_span">
                                        <img *ngIf="document && document.docType=='1'" src="../../assets/img/news_pdf.png" alt="pdf" height="21">
                                        <img *ngIf="document && document.docType=='4'" src="../../assets/img/news_doc.png" alt="doc" height="21">
                                        <img *ngIf="document && (document.docType=='2')" src="../../assets/img/news_img.png" alt="img" height="21">
                                        <img *ngIf="document && document.docType=='3'" src="../../assets/img/news_link.png" alt="link" height="21">

                                        <li class="search_pad" style="padding-left: 10px; font-size: 14px;">{{document.fileName}}</li>
                                    </span>    
                                    </a>

                                    <a href="javascript:void(0);" *ngIf="document && document.s3Key && document.isFavorite" class="seach_a newaclr">                                           
                                    <span class="search_span already_selected">
                                        <img *ngIf="document && document.docType=='1'" src="../../assets/img/news_pdf.png" alt="pdf" height="21">
                                        <img *ngIf="document && document.docType=='4'" src="../../assets/img/news_doc.png" alt="doc" height="21">
                                        <img *ngIf="document && (document.docType=='2')" src="../../assets/img/news_img.png" alt="img" height="21">
                                        <img *ngIf="document && document.docType=='3'" src="../../assets/img/news_link.png" alt="link" height="21">
                                        <li class="search_pad" style="padding-left: 10px; font-size: 14px;">{{document.fileName}} - (ALREADY IN FAVOURITE LIST)</li>
                                    </span>    
                                    </a>
                                </ng-container>
                            </ng-container>
                        </ul>                
                    </div>

                    <div *ngIf="submitted && f.searchText1.errors" class="invalid-feedback">
                        <div *ngIf="f.searchText1.errors.required">Search text is required</div>
                    </div>

                    <!-- <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Search in Documents</label>
                    <input type="text" required formControlName="name" [(ngModel)]='favouriteModel.name' class="form-control" id="name" placeholder="Type here">
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Name is required</div>
                        </div>
                    </div> -->
            
            </div>

            <div class="modal-footer justify-content-start border-top-0 nb-footer pb-4" style="margin-left: -22px;">
                <div class="form-group">
                    <div *ngIf="!Isvalid"   class="form_alert">
                            <span  class="ft-alert-circle"></span>
                            Please fill in all the required fields correctly.
                    </div>
                    <div *ngIf="errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                        {{errors}}
                    </div>
                </div>
                <button class="btn clr_button upload_button font-weight-normal text-uppercase" style="font-size: 12px;border-radius: 100px; padding: 7px 15px;">Create Now</button> &nbsp; OR &nbsp;                    
                <button type="button" class="btn pl-0 pr-0 nb_can_btn text-uppercase" (click)="modalRehide()" data-dismiss="modal">Cancel</button>
            </div>


        </div>
    </div>
</form>
    <ngx-ui-loader  ></ngx-ui-loader>