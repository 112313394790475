import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { FolderEditModel } from '../../shared/models/folder.model';
import { Router } from '@angular/router';
import { LibraryService } from '../library.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { FolderResponse } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-add-library',
  templateUrl: './add-library.component.html',
  styleUrls: ['./add-library.component.css']
})
export class AddLibraryComponent implements OnInit {

  folderModel : FolderEditModel ;
  title;
  data;
  errors;
  addLibraryForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Create Now";
  headingText: string ="Create";
  folder_id : string = null;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private libraryService: LibraryService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.folderModel = new FolderEditModel();  

    if(this.data.library !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      this.headingText = "Update";
      Object.assign(this.folderModel, this.data.library);
    }

    if(this.data.business_id !=null){
      this.business_id = this.data.business_id;
    }

    this.addLibraryForm = this.formBuilder.group({
      name: ['', Validators.required],
    });

    if(this.data.folder_id !=null){
      this.folder_id = this.data.folder_id;
      
    }
  }

  get f() { return this.addLibraryForm.controls; }

onSubmit(){
 this.submitted = true;

 let title = this.folderModel.name;

 if (this.addLibraryForm.invalid || (title && title.trim()=="")) {

  if(title && title.trim()==""){
    this.addLibraryForm.controls['name'].setErrors({'required': true});
  }
  // console.log(this.f);
  // console.log(this.addLibraryForm.invalid);
  return;
}

// if(form.invalid) {

//   if(form.pristine){
//   }
//   this.Isvalid = false;

// }

else{
  this.folderModel.name = title.trim();

if(this.data.library !=null){
//   this.ngxService.start();
// //console.log("Edditre");
  this.libraryService.libraryEdit(this.folderModel, this.business_id).pipe(first())
  .subscribe((data:FolderResponse)=>{
    this.ngxService.stop();
    
    if(data.error && !data.success){
      this.toastr.error('Error on updating library.');
    }
    else{
      this.toastr.success('Library updated successfully.');
      this.event.emit('OK');
      this.modalRef.hide();
    }

    },err=>{ 
      this.ngxService.stop();  
      this.toastr.error('Error on updating library.');
    });
    
    //this.router.navigate(['agents']);
    

}else{
  
  this.ngxService.start();
  this.libraryService.libraryAdd(this.folderModel, this.business_id)
  .subscribe((data:FolderResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){
      
      // console.log(123456);
      // console.log(data.error);
      // console.log(data.success);

      if(data.error.message){
        this.errors = data.error.message;
      }
      else{
        this.errors = Utility.GetLocalErrorString(data.error.errorCode);
      }

      this.toastr.error('Error on saving library.');
      
    }
    else{
      this.toastr.success('Library added successfully.');
      this.event.emit('OK');
     // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving library.');
    });

  }

  

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addLibraryForm.reset();
}

}
