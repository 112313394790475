export class LeaveMOdel {    
    public id: string;    
    public userId: string;
    public createdById: string;
    public startDate: Date;
    public endDate: Date;
    public createdAt: Date;
    public lastUpdatedDate: Date;
    public reason: number;
    public extraDetails : string;
    public lastUpdatedById: string;
    public leaveType: any;
    public leaveStatus: number;
    public createdBy: userInfo;
    public lastUpdatedBy: userInfo;
    public departmentId: string;
 }
 
 export class LeaveViewModel extends LeaveMOdel{
    
 }
 
 export class LeaveEditModel extends LeaveMOdel{
    
 }

export class AllLeaveViewModel extends LeaveMOdel{
    public id: string;    
    public createdBy: userInfo;
    public lastUpdatedBy: userInfo;
    public isArchived: boolean;
    public createdAt: Date;
    public lastUpdatedDate: Date;
    public leaveStatus: number;
    public createdById: string;
    public lastUpdatedById: string;
    public userId: string;
    public startDate: Date;
    public endDate: Date;
    public reason: number;
    public extraDetails : string;
    public leaveType: string;
    public departmentId: string;

    public setLeaveStatus: number;
}

export class userInfo {
    public  id : string;
    public  firstName : string;
    public  lastName : string;
    public lastUpdatedDate : Date;
}

export class CalendarList {    
    public title: string;
    public start: string;
    public end: string;
    public color: string;
}