import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { LeaveViewModel } from '../../../shared/models/leave.model';
import { Router } from '@angular/router';
import { LeaveService } from '../../leave.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { FolderResponse, LeaveListResponse } from '../../../shared/models/response.model';
import { Utility } from '../../../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-add-leave',
  templateUrl: './add-leave.component.html',
  styleUrls: ['./add-leave.component.css']
})
export class AddLeaveComponent implements OnInit {

  leaveModel : LeaveViewModel ;
  //title;
  data;
  errors;
  addLeaveForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  minDate: Date;
  endDate: Date;
  title: string = "Add Leave";
  departmentList: any;
  editLeave:boolean = false;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private leaveService: LeaveService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
    ) { 
      // this.minDate = new Date();
      // this.minDate.setDate(this.minDate.getDate() + 1);

      // this.endDate = new Date();
      // this.endDate.setDate(this.endDate.getDate() + 1);
    }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.leaveModel = new LeaveViewModel();  
    this.leaveModel.leaveType = '0';

    if(this.data.leave !=null){
      
      Object.assign(this.leaveModel, this.data.leave);

      

       let startDat = new Date(this.leaveModel.startDate+'Z');
       let endDat = new Date(this.leaveModel.endDate+'Z');
       let start =startDat.getFullYear()+'-'+("0"+(startDat.getMonth()+1)).slice(-2)+'-'+("0"+(startDat.getDate())).slice(-2);  
       let end =endDat.getFullYear()+'-'+("0"+(endDat.getMonth()+1)).slice(-2)+'-'+("0"+(endDat.getDate())).slice(-2);

       this.leaveModel.startDate = startDat; 
       this.leaveModel.endDate = endDat;

      //  console.log("Edit Leave----");
      //  console.log(this.leaveModel);
       this.title = "Edit Leave";
      this.editLeave = true;
    }

    if(this.data.business_id !=null){
      this.business_id = this.data.business_id;
    }

    this.addLeaveForm = this.formBuilder.group({
      leaveType: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      extraDetails: [''], //, Validators.required
      departmentId: ['', Validators.required],
    });

    this.departmentList = JSON.parse(localStorage.getItem('departmentList'));

  }

  get f() { return this.addLeaveForm.controls; }

onSubmit(){
 this.submitted = true;

 let extraDetails = this.leaveModel.extraDetails;
 let startDate = this.leaveModel.startDate;
 let endDate = this.leaveModel.endDate;

//  console.log(this.f);
//  console.log(this.addLeaveForm.invalid);
 //console.log(this.leaveModel);

 if (this.addLeaveForm.invalid || (extraDetails && extraDetails.trim()=="") || (startDate > endDate) || this.leaveModel.leaveType=='0' ) {

  if(extraDetails && extraDetails.trim()==""){
    this.addLeaveForm.controls['extraDetails'].setErrors({'required': true});
  }

  if(this.leaveModel.leaveType=='0'){
    this.addLeaveForm.controls['leaveType'].setErrors({'required': true});
  }

  if(startDate > endDate){
    this.addLeaveForm.controls['endDate'].setErrors({'minimum': true});
  }
  // console.log(this.f);
  // console.log(this.addLeaveForm.invalid);
  return;
}
else{
  if(extraDetails){
    this.leaveModel.extraDetails = extraDetails.trim();
  }

  let startDat = new Date(this.leaveModel.startDate);
  let endDat = new Date(this.leaveModel.endDate);
  let start =startDat.getFullYear()+'-'+("0"+(startDat.getMonth()+1)).slice(-2)+'-'+("0"+(startDat.getDate())).slice(-2);  
  let end =endDat.getFullYear()+'-'+("0"+(endDat.getMonth()+1)).slice(-2)+'-'+("0"+(endDat.getDate())).slice(-2);

  this.leaveModel.startDate = new Date(start+' 00:00:00 am'+" UTC"); 
  this.leaveModel.endDate = new Date(end+' 00:00:01 am'+" UTC");
  
  this.leaveModel.leaveType = parseInt(this.leaveModel.leaveType)
if(this.data.leave !=null){
  this.leaveService.leaveEdit(this.leaveModel, this.data.leave.id)
  .subscribe((data:LeaveListResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){     
      if(data.error.message){
        this.errors = data.error.message;
      }
      else{
        this.errors = Utility.GetLocalErrorString(data.error.errorCode);
      }
      this.toastr.error('Error on saving leave.');      
    }
    else{
      this.toastr.success('Leave updated successfully.');
      this.event.emit('OK');
    // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving leave.');
    });
    

}else{
  
  this.ngxService.start();

  // console.log('add leave model');
  // console.log(this.leaveModel);



  this.leaveService.leaveAdd(this.leaveModel)
  .subscribe((data:LeaveListResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      if(data.error.message){
        this.errors = data.error.message;
      }
      else{
        this.errors = Utility.GetLocalErrorString(data.error.errorCode);
      }
      this.toastr.error('Error on saving leave.');
    }
    else{
      this.toastr.success('Leave added successfully.');
      this.event.emit('OK');
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving leave.');
    });

  }

}

}

modalRehide(): void {
  this.modalRef.hide();
}

}