import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FolderResponse, FavouriteResponse, UpdateDocumentFavouriteResponse, FavouritePublicResponse } from '../shared/models/response.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FolderEditModel } from '../shared/models/folder.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FavouritesService {

  baseUrl:String;
  authUrl:String;
  
  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = environment.getBaseURL;
    this.authUrl = environment.getAuthURL;
  }   

  public favouriteList(business_id){ 
    let userId = this.cookieService.get('CurrentUserId'); 
    const params = new HttpParams().set('businessId', business_id).set('userId', userId); 

    return this.http.get<FavouriteResponse>(this.baseUrl+'/Favourite/GetByBusinessId',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public favouritePublicList(commonCompanyCode){  
    const params = new HttpParams().set('companyCode', commonCompanyCode); 

    return this.http.get<FavouritePublicResponse>(this.authUrl+'/PublicBusiness/Favourite',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public addFavourite(favouriteModel, business_id, documentId){
    favouriteModel.userId = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    favouriteModel.businessId = business_id;
    return this.http.post<FavouriteResponse>(this.baseUrl +'/Favourite', favouriteModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public handleError(error) {
    return throwError(error);
  }

  public deleteFavourite(favouriteDelete){
    const params = new HttpParams().set('id', favouriteDelete);     
    // console.log(favouriteDelete);
    // return this.http.delete<Response>(this.baseUrl +'/Trash/PermanentDelete', fileDelete).pipe(
    //   catchError(this.handleError)
    //   );    

      // const options = {
      //   headers: new HttpHeaders({
      //     'Content-Type': 'application/json',
      //   }),
      //   body: fileDelete,
      // };

      // const header: HttpHeaders = new HttpHeaders()
      //           .append('Content-Type', 'application/json; charset=UTF-8');
      //       const httpOptions = {
      //           headers: header,
      //           body: fileDelete
      //       };
      
      // return this.http
      // .delete(this.baseUrl +'/Trash/PermanentDelete', httpOptions)
      // .pipe(
      //     catchError(this.handleError)
      //     );

    return this.http.delete<Response>(this.baseUrl +'/Favourite', {params}).pipe(
      catchError(this.handleError)
      );     
  }

  public noticeIsfavourite(id:string, isFavorite) {      
    const params = new HttpParams().set('id', id).set('isFavorite', isFavorite); 
    return this.http.patch<UpdateDocumentFavouriteResponse>(this.baseUrl+'/Document/SetFavorite',{},{params}).pipe(
      catchError(this.handleError)
      );
  }

}
