import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FavouritesService } from './favourites.service';
import { DocumentViewModel } from '../shared/models/document.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FavouriteResponse, FavouritePublicResponse } from '../shared/models/response.model';
import { AddFavouiteComponent } from './add-favouite/add-favouite.component';
import { FileUploadService } from '../file-upload/file-upload.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileDelete } from '../shared/models/file-upload.model'; 
import { TrashService } from '../trash/trash.service';
import { AuthService } from '../auth/auth.service';
import { FavouriteAddModel } from '../shared/models/favourite.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.css']
})
export class FavouritesComponent implements OnInit {

  baseUrl:String;
  dtOptions: DataTables.Settings = {};
  favList:FavouriteAddModel[]=[];
  favPublicList:FavouriteAddModel[]=[];
  favourite:FavouriteAddModel;
  isLoaded :boolean;
  business_id : string;
  //folder_id : string;
  //document_id : string;
  //folder_name : string;
  //fileDelete : FileDelete;
  menuName : string;
  commonCompanyCode : string;
  menuList:any;
  selectedMenu:any ='';

  constructor(
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private favouritesService: FavouritesService,
    public modalRef: BsModalRef,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private fileUploadService: FileUploadService,
    private trashService: TrashService,
    private toastr: ToastrService,
    private authService: AuthService,
    ) {
      this.business_id = this.route.snapshot.queryParams['business']; 
      //this.folder_id = this.route.snapshot.queryParams['folder']; 
      //this.folder_name = this.route.snapshot.queryParams['folder_name']; 
      //this.document_id = this.route.snapshot.queryParams['document_id'];
      this.router.routeReuseStrategy.shouldReuseRoute = function() { 
      return false;
    };
    this.baseUrl = environment.getBaseURL;
    this.commonCompanyCode = environment.commonCompanyCode;
   }

  ngOnInit() {
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    this.selectedMenu = this.menuList.find(x => x.id == this.business_id);
    
    if(this.selectedMenu.isPublic) {
      this.favouritePublicList(this.business_id);
    }
    else {
      this.favouriteList(this.business_id);
    } 
    
    this.dtOptions = {
      //pagingType: 'full_numbers',
      //ajax: this.baseUrl+'/FortressAdmins',
      pageLength: 10,
      processing: true,
      order : []
    };  
    //this.fileDelete = new FileDelete(); 
    this.menuName = this.authService.getMenuName(this.business_id);
  }

  favouriteList(business_id){
    this.favouritesService.favouriteList(business_id).subscribe((data:FavouriteResponse)=>{     
      this.favList = data.response;

      this.favList.sort(function(a, b)
      {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
      });

      //console.log(this.favList);
      this.isLoaded = true;      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }

  favouritePublicList(business_id){
    this.favouritesService.favouritePublicList(this.commonCompanyCode).subscribe((data:FavouritePublicResponse)=>{     
      this.favPublicList = data.data;

      this.favPublicList.sort(function(a, b)
      {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
      });

      if(this.favPublicList && this.favPublicList.length > 0) {
        
        for(let i=0;i< this.favPublicList.length;i++)
        { 
          if(this.favPublicList[i].businessId == business_id) {
            this.favList.push(this.favPublicList[i]);
          }
        }
      }

      //console.log(this.favPublicList);
      this.isLoaded = true;      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }

  openAddModal() {    
    this.modalRef = this.modalService.show(AddFavouiteComponent,  {
      initialState: {
        title: 'Create new favourite link', 
        business_id:this.business_id,
        //folder_id:this.folder_id,
        data: {          
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.favouriteList(this.business_id);
        //this.router.navigate(['document'], { queryParams: {business: this.business_id, folder: this.folder_id }});
      }
    });
  }
  
  public openDeleteDialog(template: TemplateRef<any>,favourite) {
    this.favourite = favourite;
    this.modalRef = this.modalService.show(template, { 
      //class: 'modal-sm', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  deleteFavourite(){
    this.modalRef.hide();
    // console.log(this.favourite);
    
    this.favouritesService.deleteFavourite(this.favourite.id).subscribe((data:any)=>{   
      if(data.error && !data.success){
        this.toastr.error('Error on deleting favourite link.');
      }
      else{
        this.favouriteList(this.business_id);
        this.toastr.success('Favourite link deleted successfully.');     
        //this.router.navigate(['document'], { queryParams: {business: this.business_id }});   
      }
              
      },
      (err: HttpErrorResponse)=>{
        this.toastr.error('Error on deleting document.');
        console.log("Error"+ err.message);
      });         
  }

  decline(): void {
    this.modalRef.hide();
  }

  openFavouriteUrl(favourite){    
    //  console.log(favourite);
    // console.log(url.search("http")); favourite.url
    if(favourite && (favourite.docType == '1' || favourite.docType == '2' || favourite.docType == '4') && favourite.url){      
      this.ngxService.start();
      let document = {s3Key:""};
      document.s3Key = favourite.url;

      this.fileUploadService.getFileUrl(document).subscribe((data)=>{ 
        this.ngxService.stop(); 
        let url = data.response; 
       
        this.fileUploadService.downloadFile(url,favourite.name);

        // if(url.search("http") != -1){
        //   window.open(url,'_blank');
        // }
        // else{
        //   window.open("http://"+url,'_blank');
        // }

        },
        (err: HttpErrorResponse)=>{
          this.ngxService.stop(); 
          this.toastr.error('Unable to show document.'); 
          console.log("Error"+ err.message);
        });

    }
    else{
      let url = favourite.url;
      if(url.search("http") != -1){
        window.open(url,'_blank');
      }
      else{
        window.open("http://"+url,'_blank');
      }
    }
    
  }

}
