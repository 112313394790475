import { Component, OnInit, TemplateRef, EventEmitter } from '@angular/core';
import { LeaveViewModel, LeaveEditModel, AllLeaveViewModel } from 'src/app/shared/models/leave.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LeaveService } from '../leave.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LeaveListResponse, AllLeaveListResponse } from '../../shared/models/response.model';
// import { FolderViewModel } from '../../shared/models/folder.model';
// import { AddLibraryComponent } from './add-library/add-library.component';
// import { EditLibraryComponent } from './edit-library/edit-library.component';
import { ToastrService } from 'ngx-toastr';
//import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-leave-list',
  templateUrl: './leave-list.component.html',
  styleUrls: ['./leave-list.component.css']
})
export class LeaveListComponent implements OnInit {

  baseUrl:String;
  dtOptions: DataTables.Settings = {};
  leaveList:LeaveViewModel[];
  folder:LeaveEditModel;
  isLoaded :boolean; 
  business_id : string;
  menuName : string;
  viewLeave: boolean = false; 
  leave:AllLeaveViewModel;
  //leaveEdit:AllLeaveViewModel;
  Isvalid:boolean;
  submitted:boolean;
  data;
  event: EventEmitter<any> = new EventEmitter();
  leaveViewModel: AllLeaveViewModel;
  setLeaveStatusForm: FormGroup;
  departmentList:any;

  constructor(
    private router: Router,
    private leaveService: LeaveService,
    public modalRef: BsModalRef,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    //private authService: AuthService,
    ) {
    this.business_id = this.route.snapshot.queryParams['business']; 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.baseUrl = environment.getBaseURL;
  }

  ngOnInit() {
    this.leaveViewModel = new AllLeaveViewModel();
    
    //this.leaveEdit = new AllLeaveViewModel();
    this.Isvalid = true;
    this.getLeaveList(); 
    this.departmentList = JSON.parse(localStorage.getItem('departmentList'));
    this.dtOptions = {
      //pagingType: 'full_numbers',
      //ajax: this.baseUrl+'/FortressAdmins',
      pageLength: 10,
      processing: true,
      order : []
    };  
    
    this.setLeaveStatusForm = this.formBuilder.group({
      setLeaveStatus: ['', Validators.required],
    });

    
    //this.menuName = this.authService.getMenuName(this.business_id);
  }

  getLeaveList(){
    this.leaveService.getAllLeaveList().subscribe((data:AllLeaveListResponse)=>{     
      this.leaveList = data.response;
      // this.agentsCount = this.agentList.length;
      // this.assignCopy();
      this.isLoaded = true;      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }


  decline(): void {
    this.modalRef.hide();
  }

  openViewDialog(template1: TemplateRef<any>, leave:any) {
    this.viewLeave = true;
    this.leave = leave;

    this.leave.setLeaveStatus = 0;
    //Object.assign(this.leaveEdit, leave);
    this.modalRef = this.modalService.show(template1, { 
      class: 'modal-lg', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      // initialState: {
      //   data: {
      //     leave:leave
      //   }
      // },
    });

    //this.idToBeDeleted = id;
  }

  get f() { return this.setLeaveStatusForm.controls; }

  onSubmit(){
     this.submitted = true;
    // if (this.patientAddForm.invalid) {
    //   return;
    // }
  

    if(this.setLeaveStatusForm.invalid || this.leave.setLeaveStatus == 0  ) {
      if(this.leave.setLeaveStatus == 0 ){
        this.setLeaveStatusForm.controls['setLeaveStatus'].setErrors({'required': true});
      }
        
      // if(form.pristine){
      // }
      // this.Isvalid = false;
      //console.log(this.setLeaveStatusForm.invalid);  
      //console.log(this.Isvalid);
      return;
    }

    
    else{
      this.ngxService.start();
      
      //  this.patientModel.clinicId = this.clinics[0].id;
      //  this.patientModel.clinicianId = JSON.parse( localStorage.getItem('CurrentUserId'));
      
      if(this.leave !=null){
        // console.log("Edditre -----------");
        // console.log(this.leave.setLeaveStatus);
      
        this.leaveService.updateLeaveStatus(this.leave.id,this.leave.setLeaveStatus).pipe(first())
        .subscribe((data:AllLeaveListResponse)=>{
          this.ngxService.stop();
          if(data.error && !data.success){      
            this.toastr.error('Error on updating leave.');
          }
          else{
            this.toastr.success('Leave updated successfully.');
            this.event.emit('OK');
            this.modalRef.hide();
            this.router.navigate(['leave-list'], { queryParams: { business: 'leave' }});
            //this.router.navigate(['leave-list?business=leave']);
          }
      
          // this.event.emit('OK');
          // this.modalRef.hide();
          },err=>{   
            this.ngxService.stop();
            this.toastr.error('Error on updating leave.');
          });
          
      
      }
    }
  }

}
