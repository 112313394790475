export class Utility {
    static GetLocalErrorString(errorCode:any){

        if(!errorCode)return;
    
        switch(true){
            case errorCode == 1001:return 'Specified user not found';
            case errorCode == 2001:return 'Please fill required fields';
            case errorCode == 2002:return 'Password not match';
            case errorCode == 2003:return 'Some fileds are invalid';
            case errorCode == 2004:return 'Contact number is already in use';
            case errorCode == 2005:return 'Email address is already in use';
            case errorCode == 2006:return 'Input is not valid';
            case errorCode == 2007:return 'User account is already exist';
            case errorCode == 3001:return 'Server error,Please contact support';
            default:return 'ServerError'
        }
    }
}
