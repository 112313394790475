<app-header></app-header>
<div class="container-fluid pl-4 pr-4 mb-5">
    <div class="content-header row mt-4 mb-2">     
        <div class="content-header-left col-md-6 col-12">
            <h3 class="content-header-title mb-0 font-weight-normal">My Profile Edit</h3>
            <div class="breadcrumb-wrapper">
                <ol class="breadcrumb bredcrumb_new">
                    <li class="breadcrumb-item"><a routerLink="/edit-profile" [queryParams]="{business: 'edit-profile'}" >MY FORTRESS</a>
                    </li>
                    <!-- <li class="breadcrumb-item"><a href="#">DOCUMENTS</a>
                    </li> -->
                    <li class="breadcrumb-item active">ACCOUNT
                    </li>
                </ol>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-2">
            <app-sidebar-profile></app-sidebar-profile>
        </div>
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-12 res_side">
                    <div class="card">
                        <div class="row pl-4 pr-4 pt-4 pb-4">
                            <div class="col-md-12">
                                <div class="form-body">
                                    <span class="font-weight-normal mb-2">General Information</span>
                                    <form [formGroup]="editProfileForm" novalidate class="form " (ngSubmit)="onSubmit()" >
                                    <div class="row mt-2" style="margin-bottom: 5px;">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="email" class="font-weight-normal">Email Address</label>
                                                <!-- <input autocomplete="off" maxlength="75" id="firstName" required minlength="2" type="text" class="form-control" name="firstName" placeholder="Email Address"> -->
                                                <input autocomplete="off" maxlength="75" formControlName="email" id="email" required pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" type="text" [(ngModel)]="agentModel.email" class="form-control" name="email" placeholder="Email Address">
                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                                        <div *ngIf="f.email.errors.minlength">First Name must be at least 2 characters in length</div>
                                                        <div *ngIf="f.email.errors.pattern">
                                                            Email must be a type of email.
                                                            </div>
                                                    </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="lastName" class="font-weight-normal">Username</label>
                                                <input autocomplete="off" maxlength="75" formControlName="userName" id="userName" minlength="2" required type="text" [(ngModel)]="agentModel.userName" class="form-control" name="userName" placeholder="Username">
                                                <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                                                    <div *ngIf="f.userName.errors.required">Username is required</div>
                                                    <div *ngIf="f.userName.errors.minlength">Username must be atleast 2 characters in length</div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="primaryMobile" class="font-weight-normal">Current Password</label>
                                                <input autocomplete="off" type="text" pattern="^(\+)?\d{11}$" required class="form-control" id="primaryMobile" name="primaryMobile" placeholder="Current Password">
                                            </div>
                                        </div> -->
                                        <br>
                                        <div class="col-md-12">
                                            <a href="javascript:void(0)" (click)="openPasswordModal(template, agentModel)" class="text-decoration-none c_password_a"><span class="c_password ">Change Password?</span></a>
                                        </div>
                                        
                                    </div>
                                    <button type="submit"  class="btn clr_button upload_button font-weight-normal mt-3" style="font-size: 12px; border-radius: 100px;">UPDATE DETAILS</button>
                                    </form>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #template>
        
        <!-- <div class="modal-header border-bottom-0 pb-0">                   
            <button type="button" class="close" data-dismiss="modal" (click)="modalRehide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="changePasswordForm" novalidate class="form " (ngSubmit)="onSubmitPassword()" >
            <div class="modal-body pt-0 pb-0 pl-4 pr-4">
                <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">Change Password</h4>
                
                <div class="form-group">
                    <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Old Password</label>

                    <input autocomplete="off" maxlength="75" formControlName="oldPassword" id="oldPassword" required type="password" [(ngModel)]="passwordModel.email" class="form-control" name="oldPassword" placeholder="Old Password">
                    <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.oldPassword.errors.required">New password is required</div>

                    </div>
                </div>

                <div class="form-group">
                    <label for="recipient-name" class="col-form-label d-flex font-weight-normal">New Password</label>

                    <input autocomplete="off" maxlength="75" formControlName="newPassword" minlength="8" id="newPassword" required type="password" [(ngModel)]="passwordModel.newPassword" class="form-control" name="newPassword" placeholder="New Password">
                    <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.newPassword.errors.required">New password is required</div>
                        <div *ngIf="f.newPassword.errors.minlength">New password must be atleast 8 characters in length</div>                        
                    </div>
                </div>

                <div class="form-group">
                    <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Confirm New Password</label>
                    <input type="password" required formControlName="passwordConfirm" class="form-control" id="name" placeholder="Confirm Password">
                    <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.passwordConfirm.errors.required">Password confirmation is required</div>
                            <div *ngIf="f.passwordConfirm.errors.mustmatch">Password confirmation must be equal to password</div>
                            <div *ngIf="f.passwordConfirm.errors.minlength">New password must be atleast 8 characters in length</div>                        
                        </div>
                </div>
                
            </div>

            <div class="modal-footer justify-content-start border-top-0 nb-footer pb-4">
                <button class="btn clr_button upload_button font-weight-normal" style="font-size: 12px;">Change Password</button> &nbsp; OR &nbsp;                    
                <button type="button" class="btn pl-0 pr-0 nb_can_btn" data-dismiss="modal">CANCEL</button>
            </div>  
        </form>        -->

    </ng-template>

<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">                   
                <button type="button" class="close" data-dismiss="modal" (click)="modalRehide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body pt-0 pb-0 pl-4 pr-4">
                <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">Change Password</h4>
                
                <div class="form-group">
                    <label for="recipient-name" class="col-form-label d-flex font-weight-normal">New Password</label>
                    <input type="password" required formControlName="name" class="form-control" id="name" placeholder="Type here">
                </div>

                <div class="form-group">
                    <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Confirm New Password</label>
                    <input type="password" required formControlName="name" class="form-control" id="name" placeholder="Type here">
                </div>

                
            </div>

            <div class="modal-footer justify-content-start border-top-0 nb-footer pb-4">
                <button class="btn clr_button upload_button font-weight-normal" style="font-size: 12px;">Change Password</button> &nbsp; OR &nbsp;                    
                <button type="button" class="btn pl-0 pr-0 nb_can_btn" data-dismiss="modal">CANCEL</button>
            </div>                                        
        </div>
    </div>
</div> -->

<app-footer></app-footer>