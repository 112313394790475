<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document"> -->

            <form [formGroup]="addDocumentForm" novalidate class="form " (ngSubmit)="onSubmit()" >
            <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
                <button type="button" class="close"  (click)="modalRehide()" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
            </div>
            <div class="modal-body pt-0 pb-0 pl-4 pr-4">
                <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">Upload new file</h4>
                <!-- <h6 class="d-flex mt-1">Upload Library : <span class="d_link"><a href="" class="text-decoration-none"> Go-To-Meeting List</a></span></h6> -->
                    <div class="form-group">
                        <label for="recipient-name" class="col-form-label d-flex font-weight-normal">File Name</label>
                        <!-- <input type="text" class="form-control" id="posttitle" placeholder="Type here"> </div> -->
                        <input type="text" required formControlName="fileName" [(ngModel)]='documentModel.fileName' class="form-control" placeholder="Type here">
                        <div *ngIf="submitted && f.fileName.errors" class="invalid-feedback">
                            <div *ngIf="f.fileName.errors.required">File Name is required</div>
                            <div *ngIf="f.fileName.errors.minlength">File Name must be atleast 3 characters in length</div>
                        </div>
                    </div> 

                    <div class="form-group">
                        <label for="message-text" class="col-form-label d-flex font-weight-normal">Please Select File Type</label>
                        <select [attr.disabled]=" disableDocumentType ? '' : null" formControlName="docType" required id="documentType"  (change)="onDocumentTypeChange($event.target.value)" [(ngModel)]='documentModel.docType' class="form-control" >
                            <option value="0">Please select</option>
                            <!-- <option value="1">Jpg</option>
                            <option value="2">Png</option>
                            <option value="3">Pdf</option>
                            <option value="4">Doc</option>
                            <option value="5">URL</option> -->
                            <option value="1">PDF</option>
                            <option value="2">Image</option>
                            <option value="3">URL</option>
                            <option value="4">Doc</option>
                        </select>
                        <div *ngIf="submitted && f.docType.errors" class="invalid-feedback">
                            <div *ngIf="f.docType.errors.required">File Type is required</div>
                        </div>
                    </div>
                    
                    <div *ngIf="!uploadDocumentEnable" class="form-group">
                        <label for="message-text" class="col-form-label d-flex font-weight-normal">Upload Your File</label>
                        <div class="custom-file">
                            <!-- <input type="file" class="custom-file-input" id="validatedCustomFile" required> -->
                            <input required name="document" formControlName="document" [disabled]="uploadDocumentEnable" accept="{{acceptDocType}}" (change)="onFileChange($event)" type="file" [(ngModel)]="documentModel.document" id="inputGroupFile01" class="custom-file-input">
                            <label class="custom-file-label d-flex text-truncate" [ngClass]="[attachmentAddStyle ? 'file-upload-color' : '']" for="validatedCustomFile">{{documentLabel}}</label>
                        </div>

                        <div *ngIf="submitted && f.document.errors" class="invalid-feedback">
                            <div *ngIf="f.document.errors.required">File is required</div>
                        </div>

                    </div>

                    <div *ngIf="!addUrlEnable" class="form-group">
                        <label for="message-text" class="col-form-label d-flex font-weight-normal">Add Your URL</label>
                        <div class="custom-file">
                            <!-- <input type="text" required formControlName="url" [(ngModel)]='documentModel.url' class="form-control" placeholder="Type here"> -->
                            <input type="text" required id="url" name="url" formControlName="url"  [(ngModel)]='documentModel.url' class="form-control" placeholder="Type here">
                        </div>

                        <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                            <div *ngIf="f.url.errors.required">Url is required</div>
                            <div *ngIf="f.url.errors.pattern">Url must be a valid site url</div>
                        </div>

                    </div>

                    <!-- <div class="form-row">
                        <div class="custom-control custom-checkbox custom-control-inline" style="margin-left: 5px; margin-top: 10px; margin-bottom: 10px;">
                          <input type="checkbox" class="custom-control-input" id="customCheck1">
                          <label class="custom-control-label ccnew" for="customCheck1">Make this favourite</label>
                        </div>
                    </div> -->


                
                <div class="modal-footer justify-content-start border-top-0 nb-footer pl-0 pb-4">
                   
                    <button class="btn clr_button upload_button font-weight-normal text-uppercase" style="font-size: 12px; border-radius: 100px;  padding: 7px 15px;">Upload Now</button> &nbsp; OR &nbsp;
                    <button type="button" class="btn pl-0 pr-0 nb_can_btn text-uppercase" (click)="modalRehide()" data-dismiss="modal">Cancel</button>
                    <div *ngIf="!Isvalid"   class="form_alert">
                            <span  class="ft-alert-circle"></span>
                            Please fill in all the required fields correctly.
                    </div>
                    <div *ngIf="errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                        {{errors}}
                    </div>

                </div>
            </div>
            </div>
            </form>
    <!-- <ngx-ui-loader  ></ngx-ui-loader> -->

    <!-- </div>
</div> -->