import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { DocumentEditModel } from '../shared/models/document.model';
import { Router, ActivatedRoute } from '@angular/router'; 
import { ProfileService } from './profile.service';
import { FileUploadService } from '../file-upload/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { DocumentResponse, DocumentAddResponse, FileUploadResponse, UserProfileResponse } from '../shared/models/response.model';
import { Utility } from '../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileUploadAddModel } from 'src/app/shared/models/file-upload.model';
import { UserAgentAddModel } from '../shared/models/agent.model';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../shared/models/user.model';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  title;
  data;
  errors;
  editProfileForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  folder_id: string;
  uploadDocumentEnable:boolean = true;
  addUrlEnable:boolean = true;
  disableDocumentType:boolean = false;
  acceptDocType : string;
  documentLabel = "Choose file";
  fileUploadAddModel:FileUploadAddModel;
  attachmentAddStyle:boolean = false;
  agentModel : UserAgentAddModel ;
  domain: string;
  
  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private profileService: ProfileService,
    private authService: AuthService,
    public modalRef: BsModalRef,
    private fileUploadService: FileUploadService,
    private toastr: ToastrService
    ) { 
      this.business_id = this.route.snapshot.queryParams['business']; 
      this.router.routeReuseStrategy.shouldReuseRoute = function() { 
        return false;
      };
    }

  ngOnInit() {
    this.Isvalid = true;    
    this.agentModel = new UserAgentAddModel();
    let user = JSON.parse(localStorage.getItem('currentUser'));
    this.agentModel = user;
    this.domain = environment.getAuthDomain;
    
    // if(this.data.agent !=null){
    //   Object.assign(this.agentModel, this.data.agent);
    // }

    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }
    // if(this.data.folder_id !=null){
    //   this.folder_id = this.data.folder_id;
    // }

    this.editProfileForm = this.formBuilder.group({  
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      prefferedName: ['', Validators.required],
      primaryMobile: [''],
      contactNumber: [''],
      //title: ['', Validators.required],
    });

    // this.documentModel.docType = "0";
    // this.fileUploadAddModel = new FileUploadAddModel();
  }

  get f() { return this.editProfileForm.controls; }

  onSubmit(){
    this.submitted = true;
      
    if (this.editProfileForm.invalid ) {
    //  console.log(this.f);
    //  console.log(this.editProfileForm.invalid);
     return;
    }
    else{
      this.ngxService.start();
      this.profileService.updateProfile(this.agentModel)
      .subscribe((data:UserProfileResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.isSuccess){     
          if(data.error.message){
            this.errors = data.error.message;
          }
          else{
            this.errors = Utility.GetLocalErrorString(data.error.errorCode);
          }
          this.toastr.error('Error on updating profile.');      
        }
        else{
          localStorage.setItem('currentUser', JSON.stringify(data.user));
          localStorage.setItem('CurrentUserId', JSON.stringify(data.user.id));
          let host = this.domain;
          this.cookieService.set( 'CurrentUserId', data.user.id, 365,'/',host );
          let currentUserCookie = data.user;
          this.cookieService.set( 'currentUser', JSON.stringify(currentUserCookie), 365,'/',host );
          this.cookieService.set( 'firstName', currentUserCookie.firstName, 365,'/',host );
          this.cookieService.set( 'userName', currentUserCookie.firstName, 365,'/',host );
          this.cookieService.set( 'lastName', currentUserCookie.lastName, 365 ); 
          this.cookieService.set( 'userType', JSON.stringify(currentUserCookie.userType), 365,'/',host ); 

          let user = new User();
          user.userName = currentUserCookie.firstName;
          user.userType = 1;
          
        // this.authService.currentUserSubject = new BehaviorSubject<User>(user);
          
          this.toastr.success('Profile updated successfully.');
          //this.event.emit('OK');
          //this.modalRef.hide();
          this.router.navigate(['edit-profile'], { queryParams: {business: this.business_id }}); //folder_name
        }      
      },err=>{   
        console.log(err);
        this.ngxService.stop();
        this.toastr.error('Error on updating data.');
      });
          
   }
   
   }

   onFileChange(event){
    // this.ngxService.startLoader('loader-01');
    // if(event.target.files[0]){
    //   console.log(this.fileUploadNotice);
    //   this.fileUploadNotice.file = <File>event.target.files[0];
    //   console.log(this.fileUploadNotice.file);
    //   this.documentLabel = event.target.files[0].name;

    //   var reader = new FileReader(); 
    //   reader.readAsDataURL(event.target.files[0]); 
    //   reader.onload = (_event) => { 
    //     this.imagePreview = <string>reader.result; 
    //   }

    //   this.imageAddStyle = true; 
    //   this.ngxService.stopLoader('loader-01');
    //   this.coverExist = true;
    // } 
    // else{
    //   this.documentLabel = "Choose file";
    //   this.imagePreview = "../../../assets/img/blank_img.png";
    //   this.fileUploadNotice.file = null;
    //   this.imageAddStyle = false;
    //   this.ngxService.stopLoader('loader-01');
    //   this.coverExist = false;
    // }  
  }

}
