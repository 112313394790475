<!-- <app-header></app-header> -->
<div class="container-fluid pl-4 pr-4 mb-5">
        <div class="content-header row mt-4 mb-2">     
            <div class="content-header-left col-md-6 col-12">
                <h3 class="content-header-title mb-0 font-weight-normal">Admin Panel</h3>
                <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb bredcrumb_new">
                        <li class="breadcrumb-item"><a href="/">ADMIN PANEL</a>
                        </li>
                        <!-- <li class="breadcrumb-item"><a href="#">DOCUMENTS</a>
                        </li> -->
                        <li class="breadcrumb-item active">LEAVE CALENDAR
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    
        <div class="row">
            <div class="col-md-2">
                <app-sidebar-adminpanel></app-sidebar-adminpanel>
            </div>
            <div class="col-md-10 doc_sm_tb">
                <div class="row">
                    <div class="col-md-12 res_side">
                        <div class="card">
                            <div class="row pl-4 pr-4 pt-4 pb-4">
                                <div class="col-md-12">
                                    <div class="form-body">
                                        <span class="font-weight-normal mb-2">General Information</span>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    
    
    <app-footer></app-footer>