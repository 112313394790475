import { Component, EventEmitter, OnInit } from '@angular/core';

import { MultifactorAuthenticationModel} from 'src/app/shared/models/mfa.model';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { MfaserviceService } from 'src/app/shared/common/services/mfaservice.service';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { MfaVerifyModel, MultifactorVerifyModel } from 'src/app/shared/models/mfaVerify.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
//const totp = require("totp-generator");

@Component({
  selector: 'app-maltifactor-auth',
  templateUrl: './maltifactor-auth.component.html',
  styleUrls: ['./maltifactor-auth.component.css']
})
export class MaltifactorAuthComponent implements OnInit {
  multifactorAuthenticationModel:MultifactorAuthenticationModel;
  multiFactorRegisterForm: FormGroup;
  Isvalid:boolean;
  submitted: boolean;
  mfaVerifyValue:MfaVerifyModel
isComeFromLogin=false;
  name = '';
  name2 = 0;
  secretkey = "pokhara";
  // secret = 'pokhara';
  secret = {ascii: "Mks",
  base32: "JVVXG",
  hex: "4d6b73",
  otpauth_url: "otpauth://totp/SecretKey?StaffPortral="+this.secretkey};
  status=false;
  mysecret;
  errors;
  manualEntryKey="";
 qrpath;
mfaconfig="0";

  constructor(  private formBuilder: FormBuilder,private api:MfaserviceService,private router: Router,private toastr: ToastrService,private location: Location,private route: ActivatedRoute,  private authService: AuthService,) { 

  }

  

  ngOnInit() {
    this.multifactorAuthenticationModel = new MultifactorAuthenticationModel();  
    this.Isvalid = true; 
this.mfaconfig=localStorage.getItem("mfastatus")?localStorage.getItem("mfastatus"):"0";
 this.mfaVerifyValue=new MultifactorVerifyModel();
    
    this.api.geMfaRegisterData();
  
    this.api.getMfaListener()
    .subscribe(res=>{
 
    console.log(res);
  this.qrpath=res.data.qrUrl;
  console.log(this.qrpath);
  this.manualEntryKey=res.data.manualEntryKey;
  document.querySelector("#img").innerHTML = '<img src="' + this.qrpath + '">';
    })
    this.multiFactorRegisterForm = this.formBuilder.group({  
      mfaKey: ['', [Validators.required,Validators.minLength(6),Validators.maxLength(8)]],    
     
    }); 
  //  this.qrpath="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMMAAADDAQAAAAAXBJgHAAADsElEQVR4nO2YSw6rOBBFC3nAjGwAiW0w85ZgA3w2QLbkmbeBxAbIjIFF9THwPj3ollJv+qIoSjhRbOpz6zqi//FY5S/53xjsIuOhZ7uKNKp1H9ZXkMJKPprGuC2+mcrmlDS07h25aCWB306V1F2ZxpAKlVeU/g/IUNaFNmz8FbeP6vvPSB82jesg0rVpkO2PiKaeQB7uLFPXrv2xjr+i8zUhP32s//X8lbmvyfVIFdkmosoizfv3zvqSnL7uRCe/aZChdKcnEjVfsREN2yTuE1If3dQ2S+nmqLOVUH2VdxM/L+4dWFb30r2tRPOn1B/ppU6jjKGu/I8dfE92T5K3OdSdl0Hyrnch/0ZybXPtQ/MODQtWVwzu+zEQDWuv6IHOYTuFSlSNP2LwPflcb0/fLH6bvOqxdk8MLIQy7MpcL13eu1u8VH596vp7wn415G2OUQafpNw+sX5Zye7R0YZXPhWUNl887OQs3UITa074Jag6tbceWMgua5WbLI0HGsNqCOFTVQbyUVrEfWIqaL52HYPOvLcSZIDqQ7GkFGSPkjzbex0LOX0ePkSRcBYHySHV26NVFiLi6d26aqXPQSUMj7oYyCdsb03FkTrZFkmv6ObgrnUsRJXNZs2bs47SKHwl15GN0G0MNARmQeMPxlozK08j4e77PAzJTy2oskdd7GRvG4rxhUkpMRTpGt3urmsLKYnBWkT2zkjc8ryVpxsNBI3nvjE7e8uuCS2LPPdjIFT0K9Ac7n1kYX6T6rCZCb9KaVMsRayHlvZ1OwPETGJN7dAo2JwKXTlwBNSmkZxtHjhMRdYhBnvulUfFDCRfO5C9BjtWRLe0DPBnYhgIdT0fOpW6CFOoFkFp7hhYSHZ2qN1BSa4VE5vMYz+t5MQ6tUmy2uWITnmyYdutJNvqhhCSaoz2pfH1raMGsmfL35zlSnIWz8Z/xsBELhdMt11K4AgGMb5qx0IwFJMwtClArjHAEfs7BhbCiMCCzQdNLAyNlyI2j/e3EEXmqW76OGepwxQzIc0kj69aSsSPuOYaZzbeVWUguzRTNmKCBdPsKfApOfk2ghcbr95FR4cWI0bP3TGwETaOEaaiSVE95Pb9ecr5mpANznDMnIX5wzExG4Hbw1oIHTx4yMrJFZNyHebuSjSRPGZJL6fMfLCr6OBnbhvJNfxR4nyqEO7geE78NjJix7IE0sG54Xg+3sVALuVbWo5KzcIiIXXXOLKR/N9G5AKnE8Ys+0VHHxdgIH//lRJDDP4BKsmzJKOnnQ8AAAAASUVORK5CYII="

    
//curently not using this.

    this.route.queryParams.subscribe(params => {
      if (params['login'] === '1') {
        this.isComeFromLogin = true;
      } else {
        this.isComeFromLogin = false;
      }
    });
  
  

  }

resetMfa(){
  this.api.restMfa();

  this.api.resetMfaListener().subscribe(res=>{
    
    if(res.isSuccess)
   {  localStorage.setItem("mfastatus","0")
     this.toastr.success("Your Reset  is Completed");
//this.reloadSamePage();
this.authService.logout();
    // this.router.navigate(['/dashboard']);
   } else{
     this.toastr.error("Some thing went wrong!");
   }
   
})
}
  


reloadSamePage(){
  let menuList = JSON.parse(localStorage.getItem('menuList'));
  if(menuList[0] && menuList[0].id){
    //console.log(58963);      
    //console.log(menuList); 
    //Special request on aprial 23 reload to the dashbord after config     
   // this.router.navigate(['twofactorsettings'], { queryParams: { business: menuList[0].id }});

    this.router.navigate(['/dashboard'], { queryParams: { business: menuList[0].id }});
    //window.location.reload();
  } else{
   this.router.navigate(['/dashboard']);
  } 
}
  onSubmit(){
    this.submitted = true;
    let mfaKey= this.multifactorAuthenticationModel.mfaKey;

    
    if (this.multiFactorRegisterForm.invalid || (mfaKey ==null)  ) {
   
     if(mfaKey ==null){
       this.multiFactorRegisterForm.controls['mfaKey'].setErrors({'required': true});
     }
   
    var err=1;
     return;
   }
   else{
    var isInitialVerification=true;
 
   this.api.VerifyPin(mfaKey.toString(),isInitialVerification);

   this.api.verifyPinListener().subscribe(res=>{
    
 if(res.isSuccess)
{  localStorage.setItem("mfastatus","1")
  this.toastr.success("Your Configuration is Completed");
  this.reloadSamePage();
} else{
  this.toastr.error("Your Entered PIN does not valid.Please Check and Enter again!");
}


})
   
   }
   
   }

   get f() { return this.multiFactorRegisterForm.controls; }

}
