import { Component, OnInit, ViewChild } from '@angular/core';
import { LeaveViewModel, LeaveEditModel, CalendarList } from 'src/app/shared/models/leave.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LeaveService } from '../leave.service';
import { HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { LeaveListResponse } from '../../shared/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
//import { AddLeaveComponent } from './add-leave/add-leave.component';
import { OptionsInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarComponent } from 'ng-fullcalendar';
import timeGrigPlugin from '@fullcalendar/timegrid';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponentView implements OnInit {

  baseUrl:String;
  userToken : String;
  dtOptions: DataTables.Settings = {};
  leaveList:LeaveViewModel[];
  leave:LeaveViewModel;
  folder:LeaveEditModel;
  calendarList: any = [];
  isLoaded :boolean; 
  business_id : string;
  menuName : string;
  startDate: string;
  endDate: string;
  defaultDate : Date = new Date();
  viewLeave: boolean = false; 
  eventLimit: boolean = true;
  that = this;
  calendarPlugins = [dayGridPlugin, interactionPlugin, timeGrigPlugin]; // important!
  defaultView: string = "dayGridMonth";
  showCollapseButton: boolean = true;

  options: OptionsInput;
  eventsModel: any;
  @ViewChild('fullcalendar') fullcalendar: CalendarComponent;

  constructor(
    private http:HttpClient,
    private router: Router,
    private leaveService: LeaveService,
    public modalRef: BsModalRef,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private cookieService: CookieService
    //private authService: AuthService,
    ) {
    this.business_id = this.route.snapshot.queryParams['business']; 
     
    // console.log('expand');
    // console.log(this.route.snapshot.queryParams['expand']);

    if(this.route.snapshot.queryParams['expand']=='true'){
      this.eventLimit = true;
    }
    else{
      this.eventLimit = false;
    }

    if(this.route.snapshot.queryParams['defaultView']){
      this.defaultView = this.route.snapshot.queryParams['defaultView'];
    }

    if(this.route.snapshot.queryParams['date']){
      this.defaultDate = new Date(this.route.snapshot.queryParams['date']);
    }

    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.baseUrl = environment.getBaseURL;
    

    let startDate = new Date();
    // this.startDate = startDate.getFullYear()+'-'+("0"+(startDate.getMonth()+1)).slice(-2)+'-01';
    // let endDate = new Date(startDate.getFullYear(), startDate.getMonth()+1 , 0).getDate();
    // this.endDate = startDate.getFullYear()+'-'+("0"+(startDate.getMonth()+1)).slice(-2)+'-'+endDate;

    startDate = new Date(startDate.getFullYear()+'-'+("0"+(startDate.getMonth()+1)).slice(-2)+'-01');
    startDate = new Date((new Date(startDate)).getTime() - (60*60*24*1000));
    this.startDate = startDate.getFullYear()+'-'+("0"+(startDate.getMonth()+1)).slice(-2)+'-'+("0"+startDate.getDate()).slice(-2);//'-01';     

    //let endDate = new Date(startDate.getFullYear(), startDate.getMonth()+1 , 0).getDate();
    let endDate = new Date((new Date(startDate)).getTime() + (35*60*60*24*1000));;
    this.endDate = endDate.getFullYear()+'-'+("0"+(endDate.getMonth()+1)).slice(-2)+'-01';//'-'+endDate;
    this.isLoaded = false;

  }

  ngOnInit() {

    this.getAllLeaveList(this.startDate, this.endDate);    
    this.userToken = this.cookieService.get('currentUserToken');
    //this.defaultDate = new Date();
    
    // this.defaultDate = new Date();
    // this.defaultDate.setDate(this.defaultDate.getDate() - 90);

    // document.getElementById('my-prev-button').addEventListener('click', function() {
    //   this.calendar.prev();
    // });
    //this.eventLimit = this.route.snapshot.queryParams['expand']; 
    if(this.defaultView == "timeGridWeek" || this.defaultView == "timeGridDay"){
      this.showCollapseButton = false;
    }
    
  }

  getAllLeaveList(startDate, endDate){                
    this.leaveService.getLeaveListAll(startDate, endDate).subscribe((data:LeaveListResponse)=>{     
      this.leaveList = data.response;
      // this.agentsCount = this.agentList.length;
      // this.assignCopy(); 
      // console.log("111 11111111 11111 1111 ");
      // console.log(this.leaveList);
      
      let lev =[],i=100;
      if(this.leaveList){
      this.leaveList.forEach(function(leave){

       // console.log(leave);

        
        //if(leave.extraDetails){
          leave.startDate = new Date(leave.startDate+'Z');
          leave.endDate = new Date((new Date(leave.endDate+'Z')).getTime() + (60*60*24*1000)); // new Date(leave.endDate+'Z'); 

          let start =leave.startDate.getFullYear()+'-'+("0"+(leave.startDate.getMonth()+1)).slice(-2)+'-'+("0"+(leave.startDate.getDate())).slice(-2);  ("0"+(leave.startDate.getDate())).slice(-2)
          let end =leave.endDate.getFullYear()+'-'+("0"+(leave.endDate.getMonth()+1)).slice(-2)+'-'+("0"+(leave.endDate.getDate())).slice(-2);  

          let color = "4cbbad";
          let leave_type = "Annual Leave";
          let user_name= "";


          if(leave.leaveType=='1'){
            color ='db8a8a';
            leave_type = "Sick Leave";
          }
          else if(leave.leaveType=='2'){
            color ='4cbbad';
            leave_type = "Annual Leave";
          }
          else if(leave.leaveType=='3'){
            color ='61cdd7';
            leave_type = "Parental Leave";
          }
          else if(leave.leaveType=='4'){
            color ='54d59a';
            leave_type = "Long Service Leave";
          }
          else if(leave.leaveType=='5'){
            color ='adadad';
            leave_type = "Time in Lieu";
          }
          else if(leave.leaveType=='6'){
            color ='ecb885';
            leave_type = "Unpaid Leave";
          }
          else if(leave.leaveType=='7'){
            color ='6173d7';
            leave_type = "Study Leave";
          }
          else if(leave.leaveType=='8'){
            color ='7b8c41';
            leave_type = "Bereavement Leave";
          }

          // else if(leave.leaveType==6){
          //   color ='ecb885';
          //   leave_type = "Community Leave";
          // }

          if(leave.createdBy && leave.createdBy.firstName){
            user_name = leave.createdBy.firstName+ ' ' +leave.createdBy.lastName ;
          }

          if(leave.leaveStatus==4){
            // console.log(i);
            // console.log(leave.leaveStatus);
            lev.push({
              id: leave.id,
              title: user_name+' - '+leave_type,//leave.extraDetails,
              description: leave.id,
              groupId :  leave.id,
              classNames:[i],            
              start: start,
              end: end,
              color: '#'+color,
              //eventMouseover:
  
            });
            i=i+1;
          }          

          //console.log(this);

          // this.calendarList.push(
          //   lev
          // );

          // console.log(this.calendarList);
         // i++;
        //}
        //this.isLoaded = false;
      });
    }

      // console.log("active leaves");
      // console.log(lev);

      //this.calendarList.push(lev);
      lev.forEach(element => {
        let exist = this.calendarList.find(x => x.id == element.id);
        if(!exist){
          this.calendarList.push(element);
        }        
      });
      //this.calendarList = lev;
      // console.log(this.eventLimit);

      this.options = {
       // editable: true,
        events: this.calendarList,      
          header: {
            left: 'dayGridMonth,timeGridWeek,timeGridDay',
            center: 'title', 
            right: 'prev,next' // dayGridMonth,timeGridWeek,timeGridDay
          },
          
          eventLimit: this.eventLimit, 
          firstDay:1,
          defaultDate:this.defaultDate,
          //eventLimitClick:"week",
          defaultView : this.defaultView,
          customButtons: {
            expandButton: {
              text: 'Expand list',
              click: function() {
                //this.eventClick();
               // alert('clicked custom button 1!');
              }
            }
          },

          // eventMouseover: function(event, jsEvent, view) {
          //   // if (event.id == 58335) {
          //   //   var targetEvents = $("#calendar").fullCalendar("clientEvents", 58345); //restrict to event(s) with the ID specified in the second argument
          //   //   targetEvents[0].backgroundColor = "blue";
          //   //   $("#calendar").fullCalendar("updateEvent", targetEvents[0]);
          //   // }
          // },
          
          views: {
            timeGrid: {
              eventLimit: 6 
              // adjust to 6 only for timeGridWeek/timeGridDay
            },
            timeGridWeek: { // name of view
              // titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
              columnHeaderFormat: {
                  day: 'numeric', month: 'short', weekday: 'short'
                  // timelineSevenDay: 'dddd D/M/Y ',
                  // timelineFifteenDay: 'dddd D/M/Y',
                  // timelineThirtyDay: 'dddd D/M/Y'
              },
            },
          },  
         // eventRender: this.eventRenderCallback,  
        plugins: [ dayGridPlugin, interactionPlugin, timeGrigPlugin],
        
        buttonText: {
          // prev: '&nbsp;&#9668;&nbsp;',
          // next: '&nbsp;&#9658;&nbsp;',
          // prevYear: '&nbsp;&lt;&lt;&nbsp;',
          // nextYear: '&nbsp;&gt;&gt;&nbsp;',
          today: 'Current month',
          month: 'Month',
          week: 'Week',
          day: 'Day'
      },
      };

      // console.log(this.calendarList);
      
      //if(this.calendarList.length >0 ){
        this.isLoaded = true;      
      //}

    },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });       
  }

  eventClick(model) {
     console.log("event clicked");
    // console.log(model);
  }

  eventMouseover(event){
    console.log(event);
  }

  eventRenderCallback(event, element){

  }

  MouseOver(event) {
    console.log(event);
    }
  
  clickButton(model) {
    console.log("button clicked");
    console.log(model);
    // if(model.buttonType == "expandButton" ){
    //   console.log(model);
    //   this.options.eventLimit = false;
    //   this.eventLimit = false;
    // }

    if(model.data && (model.buttonType == "next" || model.buttonType == "prev")){
      //this.isLoaded = false; 
      let startDate = model.data;
      this.defaultDate = new Date(startDate)

      startDate = new Date((new Date(startDate)).getTime() - (60*60*24*1000));
      this.startDate = startDate.getFullYear()+'-'+("0"+(startDate.getMonth()+1)).slice(-2)+'-'+("0"+startDate.getDate()).slice(-2);//'-01';     

      //let endDate = new Date(startDate.getFullYear(), startDate.getMonth()+1 , 0).getDate();
      let endDate = new Date((new Date(startDate)).getTime() + (35*60*60*24*1000));;
      this.endDate = endDate.getFullYear()+'-'+("0"+(endDate.getMonth()+1)).slice(-2)+'-01';//'-'+endDate;
      this.isLoaded = false;  
      
      this.getAllLeaveList(this.startDate, this.endDate);
    }

    if(model.data && (model.buttonType == "dayGridMonth" || model.buttonType == "timeGridWeek" || model.buttonType == "timeGridDay")){
      if(model.buttonType == "dayGridMonth"){
        this.defaultView = "dayGridMonth";
        this.options.defaultView = "dayGridMonth";
      }
      else if(model.buttonType == "timeGridWeek"){
        this.defaultView = "timeGridWeek";
        this.options.defaultView = "timeGridWeek";
      }
      else if(model.buttonType == "timeGridDay"){
        this.defaultView = "timeGridDay";
        this.options.defaultView = "timeGridDay";
      }

      if(model.buttonType == "timeGridWeek" || model.buttonType == "timeGridDay"){
        this.showCollapseButton = false;
      }
      else{
        this.showCollapseButton = true;
      }

    }
    
  }

  dateClick(model){
    // console.log("date clicked");
    // console.log(model);
  }

  expandData(){
    this.eventLimit = !this.eventLimit;
    this.router.navigate(['calendar'], { queryParams: { business: 'leave',expand: this.eventLimit,date: this.defaultDate,defaultView:this.defaultView }});
  }

 

}
