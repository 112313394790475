import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sidebar-profile',
  templateUrl: './sidebar-profile.component.html',
  styleUrls: ['./sidebar-profile.component.css']
})
export class SidebarProfileComponent implements OnInit {

  path :string;

  constructor(private route: ActivatedRoute) { 
    this.path = this.route.routeConfig.path;
  }

  ngOnInit() {
  }

}
