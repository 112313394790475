import { Injectable } from '@angular/core';
import { Response, FileUploadResponse, DocumentUrlResponse } from '../shared/models/response.model';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { FileUploadAddModel, FileUploadAddNoticeModel } from '../shared/models/file-upload.model';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import {Http, ResponseContentType} from '@angular/http';
import 'rxjs/Rx';
import { environment } from 'src/environments/environment';

export interface UploadResult { 
  id: string;
  name :string;
  displayName: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})


export class FileUploadService {

  baseUrl:String;
  
  constructor(
    private http_download: Http,
    private http:HttpClient,
    private cookieService: CookieService
    ) {
    this.baseUrl = environment.getBaseURL;
  }

  public handleError(error) {
    return throwError(error);
  }

  public uploadFile(fileUploadModel:FileUploadAddModel){   
    // console.log(fileUploadModel); 
    var formData = new FormData();
    formData.append("file", fileUploadModel.file, fileUploadModel.file.name)
    
    let query_str = "fileType=1&fileName="+fileUploadModel.fileName+"&folderId="+fileUploadModel.folderId+"&userId="+this.cookieService.get('CurrentUserId')+"&companyCode="+this.cookieService.get('companyCode');

    return this.http.post<FileUploadResponse>(this.baseUrl +'/FileUpload/Upload?'+query_str, formData).pipe(
      catchError(this.handleError)
      );    
  }

  public uploadFileNotice(fileUploadModel:FileUploadAddNoticeModel){   
    // console.log(fileUploadModel.file, '------- fileUploadModel.file-----'); 
    var formData = new FormData();
    formData.append("file", fileUploadModel.file, fileUploadModel.file.name)
    
    let query_str = "fileType=2&fileName="+fileUploadModel.fileName+"&businessId="+fileUploadModel.businessId+"&userId="+this.cookieService.get('CurrentUserId')+"&companyCode="+this.cookieService.get('companyCode');

    return this.http.post<FileUploadResponse>(this.baseUrl +'/FileUpload/NoticeUpload?'+query_str, formData).pipe(
      catchError(this.handleError)
      );    
  }
  
  public getFileUrl(document){
    var userId:string = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    const params = new HttpParams().set('S3Key', document.s3Key).set('bucketName', 'fortressstaffportal');  //set('fileCategory', document.documentType).set('userId', userId);  
    return this.http.get<DocumentUrlResponse>(this.baseUrl+'/FileUpload/GetDocumentUrl',{params}).pipe(
      catchError(this.handleError)
      );  
  }

  // downloadFile(url): Observable<HttpResponse<Blob>>{		
	// 	return this.http.get(url, {observe: 'response', responseType: 'blob'}); //{ responseType: ResponseContentType.Blob }
  //  }

//Commeneted after angular 11 update

  //  downloadFile(url,filename){		
	// 	return this.http_download
  //   .get(url, {
  //     responseType: ResponseContentType.Blob,
  //     //search: // query string if have
  //   })
  //   .map(res => {
  //     return {
  //       filename: filename,
  //       data: res.blob()
  //     };
  //   })
  //   .subscribe(res => {
  //       // console.log('start download:',res);
  //       var url = window.URL.createObjectURL(res.data);
  //       var a = document.createElement('a');
  //       document.body.appendChild(a);
  //       a.setAttribute('style', 'display: none');
  //       a.href = url;
  //       a.download = res.filename;
  //       // a.target="_blank";
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       a.remove(); // remove the element
  //     }, error => {
  //       console.log('download error:', JSON.stringify(error));
  //     }, () => {
  //       console.log('Completed file download.')
  //     });
  //  }


  //after angular 11 update

  public async downloadFile(url: string,fileName: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    const urls = window.URL.createObjectURL(blob);
    a.href = urls;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  
  public async uploadFileNoticeNew( fileUploadModel:FileUploadAddNoticeModel ) : Promise<UploadResult> {
    // console.log(fileUploadModel.file, '------- fileUploadModel.file-----');
    var formData = new FormData();
    formData.append("file", fileUploadModel.file, fileUploadModel.file.name)
    
    let query_str = "fileType=2&fileName="+fileUploadModel.fileName+"&businessId="+fileUploadModel.businessId+"&userId="+this.cookieService.get('CurrentUserId')+"&companyCode="+this.cookieService.get('companyCode');
    
    var result = await this.http.post<FileUploadResponse>(
      this.baseUrl+"/FileUpload/NoticeUpload?"+query_str, 
            formData
        )
        .toPromise();

    return({     
        id :null,  
        url: result.response,
        name :fileUploadModel.file.name,
        displayName: fileUploadModel.file.name
    });

  }

  public deleteAttachment(id){
    const params = new HttpParams().set('attachementId', id);     
    // console.log(id);
    
    return this.http.delete<Response>(this.baseUrl +'/Notice/DeleteAttachment', {params}).pipe(
      catchError(this.handleError)
      );     
  }

}
