<div class="container-fluid mb-0">
    <div class="row">
        <div class="col-12 p-0">
            <div class="min-vh-100 text-center m-0 d-flex flex-column justify-content-center">
                <h1 class="font-weight-bold text_404">404</h1>
                <p class="font-weight-bold text-sorry" >Oops! Sorry</p>
                <p class="font-weight-bold text-find" >We can't find that page</p>
                <a href="" class="text-decoration-none goback_link"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> &nbsp; Go Back</a>
            </div>
        </div>
    </div>
</div>