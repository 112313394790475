import { FolderViewModel } from './folder.model';

export class Document {    
    public id: string;
    public fileName: string;
    public isArchived: boolean = false;
    public createdById: string; 
    public folderId: string;
    public s3Key: string;
    public lastModified: string;
    public docType: number = 0;
    public document: File;
    public  url : string;
    public isFavorite : boolean;
    public createdBy : createdBy;
    public createdAt: Date;
    public isFolder : boolean;
    public parentFolderId: string;
    public refDocumentId: string;
    public refFolderId: string;
 }
 
 export class DocumentViewModel extends Document{
    
 }
 
 export class DocumentEditModel extends Document{
    
 }


export class FolderSearchModel extends Document{
    public folder:FolderViewModel;
}

export class createdBy {
    public  id : string;
    public  firstName : string;
    public  lastName : string;
}
