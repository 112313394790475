export class FavouriteModel {
   public id: string;
   public businessId: string;
   public userId: string;
   public name: string;
   public url: string;   
   public docType: number;
   public docType1: string;
   public documentId: string;
}

export class FavouriteAddModel extends FavouriteModel{
   
}
