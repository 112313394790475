import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { MultifactorAuthenticationModel} from 'src/app/shared/models/mfa.model';
import { MfaserviceService } from 'src/app/shared/common/services/mfaservice.service';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-multifactor-modal',
  templateUrl: './multifactor-modal.component.html',
  styleUrls: ['./multifactor-modal.component.css']
})
export class MultifactorModalComponent implements OnInit {
 mfaKey:number;
 submitted = false;
multiFactorLoginForm: FormGroup;
 event: EventEmitter<any> = new EventEmitter();
 Isvalid:boolean;
 multifactorAuthenticationModel:MultifactorAuthenticationModel;
 errors;

 domain: string;
 constructor(
   public modalRef: BsModalRef,
   private ngxService: NgxUiLoaderService,
   private formBuilder: FormBuilder,
   private authService: AuthService,
   private toastr: ToastrService,
   private cookieService: CookieService,
   private api:MfaserviceService,private router: Router,
 ) { 
  
  this.domain = environment.getAuthDomain;
 }
 

  ngOnInit() {
    this.Isvalid = true; 
    
    this.multifactorAuthenticationModel = new MultifactorAuthenticationModel();  

    this.multiFactorLoginForm = this.formBuilder.group({  
      mfaKey: ['', [Validators.required,Validators.maxLength(8)]],     // , Validators.minLength(8), Validators.maxLength(25)
      //newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
    }); 
  }

  onSubmit(){
    this.submitted = true;
    let mfaKey= this.multifactorAuthenticationModel.mfaKey;

    
    if (this.multiFactorLoginForm.invalid || (mfaKey ==null)  ) {
   
     if(mfaKey ==null){
       this.multiFactorLoginForm.controls['mfaKey'].setErrors({'required': true});
     }
   
     
   
     // console.log(this.f);
     // console.log(this.changePasswordForm.invalid);
     return;
   }
   else{

    
    var isInitialVerification=false;
 
   this.api.VerifyPin(mfaKey.toString(),isInitialVerification);
   this.api.verifyPinListener().subscribe(res=>{
    if(res.isSuccess)
    { 
      localStorage.setItem('loggedIn','1')
      // this.cookieService.set('loggedIn','1');
      
      let host = this.domain;
      
      this.cookieService.set( 'loggedIn', '1', 365,'/',host );
      this.toastr.success('Login success.');
      
      this.ClosePopup();
    this.authService.redirectIfAuth();
      this.event.emit(1);
    } else{
     
      this.toastr.error("Your Entered PIN does not valid.Please Check and Enter again!");
      return
    }
   })

  
   }
   
   }

   get f() { return this.multiFactorLoginForm.controls; }
  
modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.authService.logout();
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}


}
