import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-adminpanel',
  templateUrl: './sidebar-adminpanel.component.html',
  styleUrls: ['./sidebar-adminpanel.component.css']
})
export class SidebarAdminpanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
