import { Component, OnInit, TemplateRef } from '@angular/core';
import { LeaveViewModel, LeaveEditModel } from 'src/app/shared/models/leave.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LeaveService } from '../leave.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LeaveListResponse } from '../../shared/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AddLeaveComponent } from './add-leave/add-leave.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.css']
})
export class LeaveComponent implements OnInit {

  baseUrl:String;
  dtOptions: DataTables.Settings = {};
  leaveList:LeaveViewModel[];
  leave:LeaveViewModel;
  folder:LeaveEditModel;
  isLoaded :boolean; 
  business_id : string;
  menuName : string;
  startDate: Date;
  endDate: Date;
  viewLeave: boolean = false; 
  startDate1; // = new Date();
  endDate1; // = new Date();

  constructor(
    private router: Router,
    private leaveService: LeaveService,
    public modalRef: BsModalRef,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private toastr: ToastrService,
    //private authService: AuthService,
    ) {
    this.business_id = this.route.snapshot.queryParams['business']; 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.baseUrl = environment.getBaseURL;

    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 180);
    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate() + 180);
  }

  ngOnInit() {
    this.getLeaveList(); 
    this.dtOptions = {
      pageLength: 10,
      processing: true,
      order : [],
      // "columnDefs": [
      //   { "searchable": false, "targets": [0,3,4] }
      // ]
      searching: false
    };  
    //this.menuName = this.authService.getMenuName(this.business_id);
  }

  getLeaveList(){
    // let startDate = this.startDate.getFullYear()+'-'+this.startDate.getMonth()+'-'+this.startDate.getDate()
    // let endDate = this.endDate.getFullYear()+'-'+this.endDate.getMonth()+'-'+this.endDate.getDate()

    //let startDate = new Date();
    let startDate = this.startDate.getFullYear()+'-'+("0"+(this.startDate.getMonth()+1)).slice(-2)+'-01';
    let endDate1 = new Date(this.endDate.getFullYear(), this.endDate.getMonth()+1 , 0).getDate();
    let endDate = this.endDate.getFullYear()+'-'+("0"+(this.endDate.getMonth()+1)).slice(-2)+'-'+endDate1;
    
    this.leaveService.getLeaveList(startDate, endDate).subscribe((data:LeaveListResponse)=>{     
      this.leaveList = data.response;
      // this.agentsCount = this.agentList.length;
      // this.assignCopy();      
      // console.log(this.leaveList);
      this.isLoaded = true;      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }

  openEditModal(leave:any) {  
    //console.log(agent);  
    this.modalRef = this.modalService.show(AddLeaveComponent,  {
      initialState: {
        title: 'Edit Leave',
        data: {
          leave:leave,
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        //this.router.navigate(['leave']);
        this.router.navigate(['leave'], { queryParams: { business: 'leave' }});

        //  this.getAgentList();
      
      }
    });
  }

  openAddModal() {    
    this.modalRef = this.modalService.show(AddLeaveComponent,  {
      initialState: {
        title: 'Add Leave',
        business_id:this.business_id,
        data: {          
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);  
      if (result == 'OK') {
        this.router.navigate(['leave'], { queryParams: { business: 'leave' }});
        //this.router.navigate(['leave'], { queryParams: {business: this.business_id }});      
      }
    });
  }

  // public openDeleteDialog(template: TemplateRef<any>,folder) {
  //   this.folder = folder;
  //   this.modalRef = this.modalService.show(template, { 
  //     //class: 'modal-sm', 
  //     keyboard: false,
  //     backdrop: true,
  //     ignoreBackdropClick: true,
  //   });
  //   //this.idToBeDeleted = id;
  // }

  modalRehide(): void {
    this.modalRef.hide();
  }

  public openDeleteDialog(template: TemplateRef<any>,leave) {
    this.leave = leave;
    this.modalRef = this.modalService.show(template, { 
      //class: 'modal-sm', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }

  openViewDialog(template1: TemplateRef<any>,leave) {
    this.viewLeave = true;
    this.leave = leave;
    this.modalRef = this.modalService.show(template1, { 
      class: 'modal-lg', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }

  deleteLeave(){
    this.viewLeave = false;
    // console.log(7777777777);
    // console.log(this.leave);
    this.modalRef.hide();
    
    this.leaveService.deleteLeave(this.leave).subscribe((data:any)=>{   
      if(data.error && !data.success){
        this.toastr.error('Error on deleting leave.');
      }
      else{
        this.toastr.success('Leave cancelled successfully.');     
        this.router.navigate(['leave'], { queryParams: {business: this.business_id }});   
      }
              
      },
      (err: HttpErrorResponse)=>{
        this.toastr.error('Error on deleting leave.');
        console.log("Error"+ err.message);
      });         
  }

  decline(): void {
    this.modalRef.hide();
  }

  onChange(newVal, id){
    this.dtOptions = {};
    let startDate=((document.getElementById("startDate1") as HTMLInputElement).value);
    let endDate =((document.getElementById("endDate1") as HTMLInputElement).value);
    let startDate1 //= new Date;
    let endDate1 //= new Date;

    // console.log('start= ' + startDate +' end= ' + endDate);
    
    if(startDate !='' && endDate !=''){
      if(id=='startDate1') {
        startDate1 = new Date(newVal);
        endDate1 = ((document.getElementById("endDate1") as HTMLInputElement).value);
  
        endDate1 = endDate1.split('/')[2]+'-'+endDate1.split('/')[1]+'-'+endDate1.split('/')[0];
        startDate1 = startDate1.getFullYear()+'-'+("0"+(startDate1.getMonth()+1)).slice(-2)+'-'+(startDate1.getDate());
      }
      if(id=='endDate1') {
        startDate1 = ((document.getElementById("startDate1") as HTMLInputElement).value);
        endDate1 = new Date(newVal);
  
        startDate1 = startDate1.split('/')[2]+'-'+startDate1.split('/')[1]+'-'+startDate1.split('/')[0];
        endDate1 = endDate1.getFullYear()+'-'+("0"+(endDate1.getMonth()+1)).slice(-2)+'-'+(endDate1.getDate());
      }
        // this.dueDate2 = newVal;

        this.leaveList=[]

        this.leaveService.getLeaveList(startDate1, endDate1).subscribe((data:LeaveListResponse)=>{     
          this.leaveList = data.response;
          // this.agentsCount = this.agentList.length;
          // this.assignCopy();      
          // console.log(this.leaveList);
          this.dtOptions = {
            pageLength: 10,
            serverSide: true,
            processing: false,
            paging:false,
            order : [],
            // "columnDefs": [
            //   { "searchable": false, "targets": [0,3,4] }
            // ]
            // searching: false
          }; 

        //   $('#mydatatable')
        // .on( 'order.dt',  function () { console.log('Order' ); } )
        // .on( 'search.dt', function () {console.log('Search' ); } )
        // .on( 'page.dt',   function () { console.log('Page' ); } )
        // .dataTable();
          
          this.isLoaded = true;    
          

          },
          (err: HttpErrorResponse)=>{
            console.log("Error"+ err.message);
          }); 

          
          
      }
      
      if(startDate =='' && endDate ==''){
        this.getLeaveList(); 
      }

      

      
  }


}
