<!-- <div class="app-content content_">
    <div class="content-wrapper">
        <div class="content-header row"> </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-10 box-shadow-2 p-0 login_width">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <div class="card-header border-0" style="padding-bottom: 5px;">
                                <div class="card-title text-center"> <img src="assets/img/logo_login.png" alt="branding logo" style="width: 75%;"> </div>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2 logh2" >
                                    <span style="font-size: 10px;">RESET PASSWORD</span>
                                </h6> </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <form  [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()" class="form-horizontal" action="index.html" novalidate>
                                        <div *ngIf="invalid" class="alert alert-danger">
                                            Username does not exist.
                                        </div>
                                        <div *ngIf="valid" class="alert alert-success">
                                            Password successfully send. Please use new password.
                                        </div> -->
                                        <!-- <fieldset class="form-group position-relative has-icon-right mar_field">
                                            <input name="userName" type="text" class="form-control" id="user-name" formControlName="userName" placeholder="UserName" required >
                                            <div class="form-control-position"> <i class="la la-user"></i> </div>

                                            <div class="form-group">
                                            <div *ngIf="submitted && inputs.userName.errors" class="alert alert-danger">
                                                <div *ngIf="inputs.userName.errors.required">User name is required</div>                                                
                                            </div>
                                            </div>

                                        </fieldset> -->
<!-- 
                                        <fieldset class="form-group position-relative has-icon-right mar_field">
                                                <input name="userName" type="text" class="form-control" id="user-name" formControlName="userName" [ngClass]="{ 'is-invalid': submitted && inputs.userName.errors }" placeholder="UserName"  >
                                                <div class="form-control-position"> <i class="la la-user"></i> </div>
                                                <div *ngIf="inputs.userName.errors" class="invalid-feedback"></div>  
                                        </fieldset>

                                        <button type="submit" class="btn btn-block signbtn">Reset Password</button>
                                    </form>
                                </div>
                                <div class="for_p">
                                    <p class="text-center m-0"><a href="/login" class="card-link">Login</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  </div> -->
  
  <!-- <ngx-ui-loader ></ngx-ui-loader> -->
  <div *ngIf="invalid" class="alert alert-danger up_err">
    Username is required.
</div>


  <div class="container h-100 container_auth fp_new">
        <div class="d-flex justify-content-center h-100">
            <div class="user_card">
                <div class="d-flex justify-content-center">
                    <div class="brand_logo_container"> <img src="../../../assets/img/new_password.png" class="brand_logo" alt="Logo"> </div>
                </div>
                <div class="d-flex justify-content-center form_container">
                    <form  [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()" action="index.html" novalidate>
                        
                        <h4 class="text-center mb-3 mt-5 font-weight-normal text_c">PASSWORD RESET</h4>

                        <!-- <h6 class="text-center mb-3 font-weight-normal text_c" style="font-size: 14px;">Please contact system administrator.</h6> -->


                        <h6 class="text-center mb-3 font-weight-normal text_c" style="font-size: 14px;">Please enter your Username below. <br> Temporary password will be sent to your primary <br> mobile number or email</h6>
                        
                        <div class="input-group mb-2 login_input">
                            <input type="text" name="" class="form-control input_pass" value="" id="user-name" formControlName="userName" [ngClass]="{ 'is-invalid': submitted && inputs.userName.errors }" placeholder="Username"> 
                            <div class="icon_place"> <i class="fa fa-user"></i> </div>
                            <div *ngIf="inputs.userName.errors" class="invalid-feedback"></div>  
                        </div>
                        <div class="d-flex justify-content-center mt-4 login_container">
                            <button type="submit" name="button" class="btn login_btn font-weight-normal">SEND</button>
                        </div>
                        
                    </form>
                </div>
                <div class="mt-4">
                    <div class="d-flex justify-content-center links font-weight-normal pb-2"> <a href="/new-password">Back to Login</a> </div>
                </div>
                <div class="mt-4 login_rights">
                    <div class="d-flex justify-content-center links"> All Rights Reserved @ My Fortress </div>
                </div>
                <div class="mt-4 login_img">
                    <div class="d-flex justify-content-center links"> <img src="../../../assets/img/fortress_logo.png" alt=""> </div>
                </div>
            </div>
        </div>
    </div>