import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DocumentResponse, DocumentAddResponse, DocumentUrlResponse, TrashResponse } from '../shared/models/response.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrashService {

  baseUrl:String;
  authUrl:String;
  
  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = environment.getBaseURL;
    this.authUrl=environment.getAuthURL;
  }   

  public getTrashList(business_id){
    const params = new HttpParams().set('id', business_id); 
    return this.http.get<TrashResponse>(this.baseUrl+'/Trash/GetAllTrash',{params}).pipe(
      catchError(this.handleError)
      );    
  }
  public getPublicTrashList(CompanyCode){
    console.log(CompanyCode);
    const params = new HttpParams().set('companyCode', CompanyCode); 
    return this.http.get<TrashResponse>(this.authUrl+'/PublicBusiness/Trash',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public handleError(error) {
    return throwError(error);
  }
  
  public restoreFromThrash(trash){
    let id = '';
    if(trash.refFolderId) {
      id = trash.refFolderId
    } else {
      id = trash.id
    }
    let restore = {id : trash.id ,commonFileType: trash.entityType};
    // console.log(restore);
    return this.http.post<Response>(this.baseUrl +'/Trash/RestoreTrash',restore).pipe(
      catchError(this.handleError)
      );     
  }

  public deleteDocument(fileDelete){
    const params = new HttpParams().set('id', fileDelete.id).set('entityType', fileDelete.entityType);     
    // console.log(fileDelete);
    // return this.http.delete<Response>(this.baseUrl +'/Trash/PermanentDelete', fileDelete).pipe(
    //   catchError(this.handleError)
    //   );    

      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: fileDelete,
      };

      const header: HttpHeaders = new HttpHeaders()
                .append('Content-Type', 'application/json; charset=UTF-8');
                //.append('Authorization', 'Bearer ' + sessionStorage.getItem('accessToken'));
            const httpOptions = {
                headers: header,
                body: fileDelete
            };
      
      return this.http
      .delete(this.baseUrl +'/Trash/PermanentDelete', httpOptions)
      .pipe(
          catchError(this.handleError)
          );

    // return this.http.delete<Response>(this.baseUrl +'/Trash/PermanentDelete', {params}).pipe(
    //   catchError(this.handleError)
    //   );     
  }

}
