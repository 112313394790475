<div class="d-flex" id="wrapper">
    <app-sidebar></app-sidebar>
    <div id="page-content-wrapper">
        <app-header></app-header>
        <div class="container-fluid pl-4 pr-4 mb-5">
            <div class="content-header row">      
              <div class="content-header-left col-md-6 col-12">
                <h3 class="content-header-title mb-0 font-weight-bold">Notice Board</h3>
                <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb bredcrumb_new">
                      <li class="breadcrumb-item"><a href="/">HOME</a>
                      </li>
                      <li class="breadcrumb-item"><a href="#">NOTICE BOARD</a>
                      </li>
                      <li class="breadcrumb-item active">BORROWING TO INVEST...
                      </li>
                    </ol>
                  </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="card-body card_boxshadow bg-white pl-0 pr-0">
                        <div class="row pb-4 border_line ">
                            <h6 class="font-weight-bold">Borrowing To Invest Seminar-11 September to 12 September</h6>
                            <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Mollitia dicta molestiae ad magni reiciendis, impedit alias hic quam sunt rerum qui neque distinctio sint laborum libero ea praesentium quaerat corrupti at veritatis nam! Vitae temporibus, eius error provident. Inventore, vitae. Debitis inventore, perspiciatis dicta officiis exercitationem ipsum doloribus deserunt ipsam!</span>
                        </div>
                        <app-post></app-post>
                        <!-- <div class="row pt-4 mb-4">
                            <div class="media nb_width">
                                <div class="media-left pr-1">
                                    <a href="#">
                                        <span class="avatar avatar-online"><img src="../../assets/img/user.png" alt="avatar" style="height: 50px; margin-left: 1rem; margin-right: 15px;"></span>
                                    </a>
                                </div>
                                <div class="media-body">
                                    <p class="float-left mb-0 font-weight-bold">
                                        Louie, Leave a reply
                                    </p>
                                    <textarea class="w-100 mt-2 text_area_s" name="" id="" cols="30" rows="5"></textarea>
                                    <p>
                                        <a href="" class="float-right btn-sm btn-color ml-2 mt-2">Post</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row mt-5">
                            <div class="media">
                                <div class="media-left pr-1">
                                    <a href="#">
                                        <span class="avatar avatar-online"><img src="../../assets/img/user.png" alt="avatar" style="height: 50px; margin-left: 1rem; margin-right: 15px;"></span>
                                    </a>
                                </div>
                                <div class="media-body">
                                    <p class="float-left mb-0 font-weight-bold">Jason Ansley&nbsp;</p>
                                    <br class="nb_br">
                                    <span class="f_size">2 min ago</span>
                                    <p class="float-right mb-1 fist_latest">
                                        <span class="lates_r">Latest Replies First</span>
                                        <span class="float-right latest_icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                    </p>
                                    <p class="p_fs mt-1">Lorem Ipsum is simply dummy text of the pr make but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="media">
                                <div class="media-left pr-1">
                                    <a href="#">
                                        <span class="avatar avatar-online"><img src="../../assets/img/user.png" alt="avatar" style="height: 50px; margin-left: 1rem; margin-right: 15px;"></span>
                                    </a>
                                </div>
                                <div class="media-body">
                                    <p class="float-left mb-0 font-weight-bold">Jason Ansley&nbsp;</p>
                                    <span class="f_size">2 min ago</span>
                                    <p class="p_fs mt-1">Lorem Ipsum is simply dummy text of the pr make but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="media">
                                <div class="media-left pr-1">
                                    <a href="#">
                                        <span class="avatar avatar-online"><img src="../../assets/img/user.png" alt="avatar" style="height: 50px; margin-left: 1rem; margin-right: 15px;"></span>
                                    </a>
                                </div>
                                <div class="media-body">
                                    <p class="float-left mb-0 font-weight-bold">Jason Ansley&nbsp;</p>
                                    <span class="f_size">2 min ago</span>
                                    <p class="p_fs mt-1">Lorem Ipsum is simply dummy text of the pr make but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="media">
                                <div class="media-left pr-1">
                                    <a href="#">
                                        <span class="avatar avatar-online"><img src="../../assets/img/user.png" alt="avatar" style="height: 50px; margin-left: 1rem; margin-right: 15px;"></span>
                                    </a>
                                </div>
                                <div class="media-body">
                                    <p class="float-left mb-0 font-weight-bold">Jason Ansley&nbsp;</p>
                                    <span class="f_size">2 min ago</span>
                                    <p class="p_fs mt-1">Lorem Ipsum is simply dummy text of the pr make but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-4 m_top_md">
                    <div class="card">
                        <img class="card-img-top" src="../../assets/img/1entrepreneur-593358_1920.jpg" alt="Card image cap">
                        <div class="card-body">
                            <div class="media pc_media">
                                <div class="media-left mr-1">
                                    <div class="avatar_img_border">
                                        <span  class="avatar avatar-md avatar-busy clip_image">
                                            <img class="avatar_img_h" src="../../assets/img/user.png">
                                        </span>
                                    </div>
                                </div>
                                <div class="media-body ml-2">
                                    <h6 class="pc_text">
                                        <span>Created by</span>
                                    </h6>
                                    <h5 class="card-sub">
                                        <span >John Emmanuel</span>
                                    </h5>
                                </div>
                            </div>
                            <div class="pt-3 pc_ltext">
                                <!-- <h6>CREATED DATE  <span class="pc_clr">: &nbsp; July 06, 2019</span></h6>
                                <h6>TOTAL REPLIES  <span class="pc_clr">: &nbsp; 100</span></h6>
                                <h6>CONTRIBUTORS  <span class="pc_clr">: &nbsp; 6 PEOPLE</span></h6> -->
                                <!-- <table class="table table-borderless table-sm pro_tab">
                                    <tbody>
                                        <tr>
                                            <td class="profile_td">CREATED DATE</td>
                                            <td class="text-left pc_clr">: July 06, 2019</td>
                                        </tr>
                                        <tr>
                                            <td class="profile_td">TOTAL REPLIES</td>
                                            <td class="text-left pc_clr">: 100</td>
                                        </tr>
                                        <tr>
                                            <td class="profile_td">CONTRIBUTORS</td>
                                            <td class="text-left pc_clr">: 6 PEOPLE</td>
                                        </tr>
                                    </tbody>
                                </table> -->

                                <div>
                                    <div class="profile_about">
                                        <span class="profile_td">Partner :</span>
                                        <p class="pc_clr">Austax Client</p>
                                    </div>
                                    <div class="profile_about">
                                        <span class="profile_td">Manager :</span>
                                        <p class="pc_clr">Christine Johnson</p>
                                    </div>
                                    <div class="profile_about">
                                        <span class="profile_td">Client Advisor :</span>
                                        <p class="pc_clr">Valentino Pikoulas</p>
                                    </div>
                                </div>
                               
                               
                               
                                <h6 class="pc_ltext cat_size">TAGGED CATEGORY</h6>
                                <div class="row" style="padding-left: 20px;">
                                    <label for="" class="pc_label mr-1">Marketing</label>
                                    <label for="" class="pc_label mr-1">Dallecort</label>
                                    <label for="" class="pc_label mr-1">Paraplaning</label>
                                </div>
                            </div>
                           
                        </div>
                        <div class="card-footer p-0 tags_border nbd_footer">
                            <table class="table table-borderless table-sm pro_tab mb-0">
                                <tbody>
                                    <tr>
                                        <td class="profile_td footer_td">CREATED DATE</td>
                                        <td class="text-right">
                                                <a href=""><button type="button" class="btn mr-3 footer_btn"><i class="fa fa-pencil" aria-hidden="true"></i></button></a>
                                                <a href=""><button type="button" class="btn footer_btn"><i class="fa fa-trash-o" aria-hidden="true"></i></button></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
