
export class User {
    public id: string;
    public accessCode: string;
    public isClient: boolean;
    public isAdmin: boolean;
    public userName:string;
    public userType: number;
    public clientCode: string;
    public xeppoCode: string;
    public profileImageUrl: string;
    public primaryMobile: string;
    public isArchived:boolean;
}

export class VerifiedUser {
    public token?: string;
    public authUser : User;
}

export class RoomCreate {   
    public roomName : string;
    public userId:string;
}
export class LogIn {   
    public userName : string;
    public passWord:string;
    public companyCode: string;
    public operatingSystem : number;
}
