import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.getCurrentUserValue;
        let islogged = localStorage.getItem('loggedIn');
        let accessmfa = localStorage.getItem('accessmfa');
        // let isAuth = this.authenticationService.isAuthenticated;
        //     console.log(currentUser);       
        //if (currentUser && isAuth && currentUser.userName && currentUser.userName !='' ) {
    //     if (currentUser && currentUser.userName && currentUser.userName !='' &&  islogged == '1') {
    //             // check if route is restricted by role
    //         if (route.data.roles && route.data.roles.indexOf(currentUser.userType) === -1 ) {
    //             // role not authorised so redirect to home page
    //             this.router.navigate(['dashboard']);
    //             return false;
    //         }
    //         // authorised so return true
    //         return true;
    //     }else if(currentUser && currentUser.userName && currentUser.userName !='' &&  accessmfa == '1' ){
    // // check if route is restricted by role
    // if (route.data.roles && route.data.roles.indexOf(currentUser.userType) === -1 ) {
    //     // role not authorised so redirect to home page
    //     this.router.navigate(['twofactorsettings']);
    //     return false;
    // }
    // // authorised so return true
    // return true;
    //     }

    if (currentUser && currentUser.userName && currentUser.userName !== '') {
        const redirectRoute = islogged === '1' ? 'dashboard' : (accessmfa === '1' ? 'twofactorsettings' : null);
    
        if (redirectRoute) {
            if (route.data.roles && route.data.roles.indexOf(currentUser.userType) === -1) {
                // Role not authorized, redirect to appropriate page
                this.router.navigate([redirectRoute]);
                return false;
            }
            // Authorized, allow access
            return true;
        }
    }
    
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}