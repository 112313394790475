import { Injectable } from '@angular/core';
import { UserProfileResponse } from '../shared/models/response.model';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  baseUrl:String;
  authUrl: String;
    
  constructor(private http:HttpClient) {
    this.baseUrl = environment.getBaseURL;
    this.authUrl = environment.getAuthURL;
  }  

  updateProfile(agentModel){
    return this.http.post<UserProfileResponse>(this.authUrl+'/User/Update', agentModel).pipe(
      catchError(this.handleError)
      );
  }

  public handleError(error) {
    return throwError(error);
  }
}
