import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notice-board-details',
  templateUrl: './notice-board-details.component.html',
  styleUrls: ['./notice-board-details.component.css']
})
export class NoticeBoardDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
