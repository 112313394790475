import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { DashboardComponent } from './dashboard/dashboard.component';
// import { PasswordNewComponent } from './auth/password-new/password-new.component';
import { TrashComponent } from './trash/trash.component';
import { LibraryComponent } from './library/library.component';
import { DocumentComponent } from './document/document.component';
import { PostComponent } from './post/post.component';
import { NoticeBoardComponent } from './notice-board/notice-board.component';
import { NoticeBoardDetailsComponent } from './notice-board-details/notice-board-details.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { Role } from './shared/models/role';
import { SendCodeComponent } from './auth/send-code/send-code.component';
//import { ListViewComponent } from './notice-board/list-view/list-view.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { AccountComponent } from './edit-profile/account/account.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { CalendarComponentView } from './out-of-office/calendar/calendar.component';
import { LeaveComponent } from './out-of-office/leave/leave.component';
import { LeaveListComponent } from './out-of-office/leave-list/leave-list.component';
import { LeaveCalanderComponent } from './out-of-office/leave-calander/leave-calander.component';
import { SubDocumentComponent } from './sub-document/sub-document.component';
import { MaltifactorAuthComponent } from './settings/maltifactor-auth/maltifactor-auth.component';
import { LogoutComponent } from './auth/logout/logout.component';
const routes: Routes = [
  {path:'login',component:LoginComponent,canActivate: [AuthGuard]}, 
  {path:'logout',component:LogoutComponent, canActivate: [AuthGuard]}, 
  {path:'forget-password',component:PasswordResetComponent},
  {path:'send_code',component:SendCodeComponent}, 
  { 
    path: '', 
    component: DashboardComponent , 
    canActivate: [AuthGuard]
  },
  { 
    path: 'dashboard', 
    component: DashboardComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'forget-password',
    component:PasswordResetComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  }, 
  // {
  //   path:'new-password',
  //   component:PasswordNewComponent, 
  //   canActivate: [AuthGuard], 
  //   data: { roles: [Role.Admin] } 
  // }, 
  {
    path:'trash',
    component:TrashComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'library',
    component:LibraryComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'document',
    component:DocumentComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'document-sub',
    component:SubDocumentComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'post',
    component:PostComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'notice-board',
    component:NoticeBoardComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'notice-board-details',
    component:NoticeBoardDetailsComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  // {
  //   path:'list-view',
  //   component:ListViewComponent, 
  //   canActivate: [AuthGuard], 
  //   data: { roles: [Role.Admin] } 
  // },
  {
    path:'not-found',
    component:NotFoundComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'edit-profile',
    component:EditProfileComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'account',
    component:AccountComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'favourites',
    component:FavouritesComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'calendar',
    component:CalendarComponentView, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'leave',
    component:LeaveComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'leave-list',
    component:LeaveListComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'leave-calander',
    component:LeaveCalanderComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'twofactorsettings',
    component:MaltifactorAuthComponent,  
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
    
  },
  {path:'**',redirectTo:'login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
