<div class="list-group list-group-flush borderside">
  <ul class="m-0 p-0 sidebar_ul d-flex flex-sm-row flex-md-column">
    <li class="ind_li">
      <a routerLink="/calendar" [class.active]="path=='calendar'" [queryParams]="{business: business_id}" class="sidebar_nav" routerLinkActive="active">
        <i class="fa fa-calendar  pl-1 pr-1"></i>
        <span class="res_icon">Public Calendar</span>
        <i *ngIf="path=='calendar'" class="fa fa-angle-right float-right right_arrow_side" aria-hidden="true"></i>
      </a>
    </li>

    <li  *ngIf="viewPortalPermission('Head_of_Department') && viewPortalPermission('Head_of_Department') !=null" class="mt-3 resp_sidebar ind_li">
      <a routerLink="/leave-list" [class.active]="path=='leave-list'" [queryParams]="{business: business_id}" routerLinkActive="active" class="sidebar_nav mt-1 ">
        <i class="fa fa-calendar-check-o  pl-1 pr-1"></i>
        <span class="res_icon">Leave Approval</span>
        <i *ngIf="path=='leave-list'" class="fa fa-angle-right float-right right_arrow_side" aria-hidden="true"></i>
      </a>
    </li>

    <li class="mt-3 resp_sidebar ind_li">
      <a routerLink="/leave" [class.active]="path=='leave'" [queryParams]="{business: business_id}" routerLinkActive="active" class="sidebar_nav mt-1 ">
        <i class="fa fa-user pl-1 pr-1"></i>
        <span class="res_icon">My Leave</span>
        <i *ngIf="path=='leave'" class="fa fa-angle-right float-right right_arrow_side" aria-hidden="true"></i>
      </a>
    </li>

  </ul>
</div>
