import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FolderEditModel } from '../../shared/models/folder.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { DocumentAddResponse, FolderResponse, SubFolderResponse } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LibraryService } from 'src/app/library/library.service';
import { DocumentEditModel } from '../../shared/models/document.model';
import { FileUploadAddModel } from 'src/app/shared/models/file-upload.model';
import { DocumentService } from '../document.service';

@Component({
  selector: 'app-add-folder',
  templateUrl: './add-folder.component.html',
  styleUrls: ['./add-folder.component.css']
})
export class AddFolderComponent implements OnInit {

  folderModel : FolderEditModel ;
  documentModel : DocumentEditModel ;
  fileUploadAddModel:FileUploadAddModel;
  title;
  data;
  errors;
  addLibraryForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Create Now";
  headingText: string ="Create";
  folder_id : string = null;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private libraryService: LibraryService,
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    private documentService: DocumentService,
  ) { }

  ngOnInit() {
    this.Isvalid = true;    
    this.folderModel = new FolderEditModel();  
    this.documentModel = new DocumentEditModel();
    this.fileUploadAddModel = new FileUploadAddModel();
    
    if(this.data.library !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      this.headingText = "Update";
      Object.assign(this.folderModel, this.data.library);

      // this.folderModel.id = this.data.library.id;
      this.folderModel.name = this.data.library.fileName;
      this.folderModel.description = null;
      this.folder_id = this.data.library.folderId;
    }

    if(this.data.business_id !=null){
      this.business_id = this.data.business_id;
    }

    this.addLibraryForm = this.formBuilder.group({
      name: ['', Validators.required],
    });

    if(this.data.folder_id !=null){
      this.folder_id = this.data.folder_id;
      console.log('this.folder_id = ', this.folder_id)
    }
  }

  get f() { return this.addLibraryForm.controls; }

onSubmit(){
 this.submitted = true;
 this.folderModel.parentFolderId = this.folder_id;
 this.folderModel.s3Key = null;

 let title = this.folderModel.name;

 if (this.addLibraryForm.invalid || (title && title.trim()=="")) {

  if(title && title.trim()==""){
    this.addLibraryForm.controls['name'].setErrors({'required': true});
  }
  // console.log(this.f);
  // console.log(this.addLibraryForm.invalid);
  return;
}


else{
  this.folderModel.name = title.trim();

if(this.data.library !=null){
//   this.ngxService.start();
// //console.log("Edditre");
  this.libraryService.folderEdit(this.folderModel, this.business_id).pipe(first())
  .subscribe((data:FolderResponse)=>{
    this.ngxService.stop();
    
    if(data.error && !data.success){
      this.toastr.error('Error on updating sub folder.');
    }
    else{
      this.editDocument(this.business_id, this.folderModel.id);
      this.toastr.success('Sub folder updated successfully.');
      this.event.emit('OK');
      this.modalRef.hide();
    }

    },err=>{ 
      this.ngxService.stop();  
      this.toastr.error('Error on updating sub folder.');
    });
    
    //this.router.navigate(['agents']);
    

}else{
  
  this.ngxService.start();
  
  this.libraryService.librarySubAdd(this.folderModel, this.business_id)
  .subscribe((data:SubFolderResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){
      
      // console.log(123456);
      // console.log(data.error);
      // console.log(data.success);

      if(data.error.message){
        this.errors = data.error.message;
      }
      else{
        this.errors = Utility.GetLocalErrorString(data.error.errorCode);
      }

      this.toastr.error('Error on saving sub folder.');
      
    }
    else{
      this.addDocument(this.business_id, data.response.id);
      this.toastr.success('Sub folder added successfully.');
      this.event.emit('OK');
     // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving sub folder.');
    });

  }

  

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addLibraryForm.reset();
}

addDocument(business_id, folderId){
  this.documentModel.fileName = this.folderModel.name;
  this.documentModel.url = null;
  this.documentModel.docType = 5;
  this.documentModel.folderId = this.folder_id;
  this.documentModel.s3Key = null;
  this.documentModel.createdById = this.folderModel.createdById;
  this.documentModel.isFolder = true;
  this.documentModel.isFavorite =  false;
  this.documentModel.parentFolderId = this.folder_id;
  this.documentModel.refDocumentId = folderId;
  this.documentModel.refFolderId = folderId;

  this.documentService.documentAdd(this.documentModel, business_id)
  .subscribe((data:DocumentAddResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){     
      if(data.error.message){
        this.errors = data.error.message;
      }
      else{
        this.errors = Utility.GetLocalErrorString(data.error.errorCode);
      }
      this.toastr.error('Error on saving document.');      
    }
    else{
      // this.toastr.success('Document added successfully.');
      this.event.emit('OK');
    // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving document.');
    });
}



  editDocument(business_id, dockId){
    this.documentModel.createdById = this.folderModel.createdById;
    this.documentModel.s3Key = null;
    this.documentModel.docType = 5;
    this.documentModel.fileName = this.folderModel.name;
    this.documentModel.folderId = this.folder_id;
    this.documentModel.isFavorite =  false;

    this.documentService.documentEdit(this.documentModel, dockId)
    .subscribe((data:DocumentAddResponse)=>{
      this.ngxService.stop();
      if(data.error && !data.success){     
        if(data.error.message){
          this.errors = data.error.message;
        }
        else{
          this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        }
        this.toastr.error('Error on updating document.');      
      }
      else{
        // this.toastr.success('Document added successfully.');
        this.event.emit('OK');
      // console.log(this.event);
        this.modalRef.hide();
      }
      
      },err=>{   
        console.log(err);
        this.ngxService.stop();
        this.toastr.error('Error on updating document.');
      });
  }


}
