    <app-header></app-header>
        <div class="container-fluid pl-4 pr-4 mb-5">
                

              <div class="row">
                  <div class="col-md-2" style="margin-top: 104px;">
                        <app-sidebar></app-sidebar>
                  </div>
                  <div class="col-md-10">
                        <div class="content-header row mt-4 mb-2 header_namer">       
                                <div class="content-header-left col-md-6 col-12">
                                  <h3 class="content-header-title mb-0 font-weight-normal">{{menuName}}</h3>
                                  <div class="breadcrumb-wrapper">
                                      <ol class="breadcrumb bredcrumb_new">
                                        <li class="breadcrumb-item"><a href="/dashboard?business={{business_id}}">{{menuName}}</a>
                                        </li>
                                        <!-- <li class="breadcrumb-item active"><a href="#">DOCUMENT LIBRARIES</a>
                                        </li> -->
                                        <li class="breadcrumb-item active">Document Libraries
                                        </li>
                                      </ol>
                                    </div>
                                </div>
                                <div class="content-header-right text-md-right col-md-6 col-12 mt-2 headerbn">
                                  <div class="btn-group">
                                    <ng-container *ngIf="selectedMenu && !selectedMenu.isNoticesReadOnly">
                                        <button *ngIf="viewPortalPermission('Library_Create')" type="button" data-toggle="modal" (click)="openAddModal()" class="btn btn-round font-weight-normal clr_button upload_button text-uppercase"> <i class="fa fa-plus fa-1x" aria-hidden="true"></i>
                                        &nbsp; Create Library</button>
                                    </ng-container>
                                      <!-- <button *ngIf=" selectedMenuItem && selectedMenuItem.name !='My Fortress' " type="button" data-toggle="modal" (click)="openAddModal()" class="btn btn-round font-weight-normal clr_button upload_button text-uppercase"> <i class="fa fa-plus fa-1x" aria-hidden="true"></i>
                                        &nbsp; Create Library</button>   -->
                                  </div>
                
                                  
                                </div>
                              </div>
                                    <div  class="table-responsive tbl_res pt-3 doc_sm_tb">
                                        <span class="text-left table_text">{{menuName}} Document Libraries</span>
                                        <table *ngIf="isLoaded" datatable [dtOptions]="dtOptions" class="table " style="width: 100%;" id="mydatatable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Library Name</th>
                                                    <th scope="col">Last Modified</th> 
                                                    <!-- LAST MODIFIED -->
                                                    <th scope="col">Created By</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                
                                                <ng-container *ngFor="let folder of folderList; let i = index">
                                                    <tr style="cursor: pointer;">
                                                        <td scope="row" class="table_datann" title="{{ folder.name }}"> 
                                                            <a (click)="viewFolder(folder)" href="javascript:void(0);"  class="link_row_table">
                                                                <img src="../../assets/img/folder_new.png" alt="folder" height="29"> &nbsp; {{ folder.name | limitTo : '100' }}
                                                            </a>
                                                        </td>
                                                        <td class="table_datan" (click)="viewFolder(folder)">{{ folder.lastUpdatedDate | dateAgo }}</td> 
                                                        <td class="table_datan" (click)="viewFolder(folder)"    >
                                                            <ng-container *ngIf="folder.createdBy && folder.createdBy.firstName">
                                                                {{folder.createdBy.firstName}}
                                                            </ng-container>    
                                                            <ng-container *ngIf="folder.createdBy && folder.createdBy.lastName">
                                                                {{folder.createdBy.lastName}}
                                                            </ng-container> 
                                                        </td>
                                                        <td class="btn_table table_datan">
                                                            <ng-container *ngIf="selectedMenu && !selectedMenu.isNoticesReadOnly">
                                                                <button type="button" *ngIf="viewPortalPermission('Library_Delete')" (click)="openDeleteDialog(template,folder)" class="btn btntabel_icon"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                                                                <!-- <button type="button" *ngIf=" selectedMenuItem && selectedMenuItem.name!='My Fortress' " (click)="openDeleteDialog(template,folder)" class="btn btntabel_icon"><i class="fa fa-trash-o" aria-hidden="true"></i></button> -->
                                                                <!-- <div class="modal fade" id="delete{{ i }}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div class="modal-dialog" role="document">
                                                                    <div class="modal-content">
                                                                        <div class="modal-body text-center pb-0">
                                                                            <h4 class="del_text font-weight-bold">Do you want to delete this folder?</h4>
                                                                        </div>
                                                                        <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-4" >
                                                                            <button (click)="deleteFolder(folder)" type="button" class="clr_button_delete font-weight-bold" style="font-size: 12px;">YES,DELETE</button> &nbsp; OR &nbsp;
                                                                            <button type="button" class="pl-0 pr-0 nb_can_btn"  data-dismiss="modal">CANCEL</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div> -->
                                                                <!-- /document -->
                                                                <!-- <a (click)="viewFolder(folder)" href="javascript:void(0);"><button type="button" class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-arrow-right" aria-hidden="true"></i></button></a> -->

                                                                <button type="button" *ngIf="viewPortalPermission('Library_Update')" (click)="openEdit(folder)" class="btn secondbtn ml-2 btntabel_icon" ><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                                                <!-- <button type="button" *ngIf=" selectedMenuItem && selectedMenuItem.name!='My Fortress'" (click)="openEdit(folder)" class="btn secondbtn ml-2 btntabel_icon" ><i class="fa fa-pencil" aria-hidden="true"></i></button> -->
                                                            </ng-container>
                                                        </td>
                                                    </tr>
                                                </ng-container>                                  
            
                                            </tbody>
                                        </table>

                                        <table *ngIf="!isLoaded" datatable class="table " style="width: 100%;" id="mydatatable">
                                            <thead>
                                                <tr>
                                                        <th scope="col">Library Name</th>
                                                        <th scope="col">Last Modified</th> 
                                                        <!-- LAST MODIFIED -->
                                                        <th scope="col">Created By</th>
                                                        <th scope="col"></th>
                                                </tr>
                                            </thead>
                                        <tbody>
                                            <!-- <tr class="odd"><td valign="top" colspan="4" class="dataTables_empty">No data available in table</td></tr> -->
                                        </tbody>
                                        </table>

                                    </div>
                  </div>
              </div>

                
            </div>

<ng-template #template>
        <div class="modal-body text-center">
                <h4 class="del_text font-weight-normal">Do you want to move this folder to trash?</h4>
            </div>
            <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-5" >
                <button (click)="deleteFolder()" type="button" class="clr_button_delete font-weight-bold text-uppercase" style="font-size: 12px;">Yes, Move</button> &nbsp; OR &nbsp;
                <button (click)="decline()" type="button" class="pl-0 pr-0 nb_can_btn text-uppercase"  data-dismiss="modal">Cancel</button>
            </div>

        <!-- <div class="modal-body text-center" >
    
            <img  src="assets/img/warning.png" alt="" class="alert_img">
            <p>{{message}}</p>
              
            <div class="form-actions center mb-1">
                <button type="button" class="btn alert_no_b mr-1" (click)="decline()"> 
                    No
                </button>
                <button type="button" class="btn alert_yes_b" (click)="confirm()" >
                     Yes
                </button>
            </div>
        </div> -->
    </ng-template>

<app-footer></app-footer>
