<app-header></app-header>
<div class="container-fluid pl-4 pr-4 mb-5">
    <div class="content-header row mt-4 mb-2">     
        <div class="content-header-left col-md-6 col-12">
            <h3 class="content-header-title mb-0 font-weight-normal">My Profile Edit</h3>
            <div class="breadcrumb-wrapper">
                <ol class="breadcrumb bredcrumb_new">
                    <li class="breadcrumb-item"><a routerLink="/edit-profile" [queryParams]="{business: 'edit-profile'}">MY FORTRESS</a>
                    </li>
                    
                    <!-- <li class="breadcrumb-item"><a href="#">DOCUMENTS</a>
                    </li> -->
                    <li class="breadcrumb-item active">GENERAL
                    </li>
                </ol>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-2">
            <app-sidebar-profile></app-sidebar-profile>
        </div>
        <div class="col-md-10">
            <div class="row">
                 <div class="col-md-9 res_side">
                    <div class="card">
                        <div class="row pl-4 pr-4 pt-4 pb-4">
                            <div class="col-md-12">
                                <form [formGroup]="editProfileForm" novalidate class="form " (ngSubmit)="onSubmit()" >
                                    <div class="form-body">
                                        <span class="font-weight-normal mb-2">General Information</span>
                                        <div class="row mt-2" style="margin-bottom: 5px;">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="firstName" class="font-weight-normal">First Name</label>
                                                    <input autocomplete="off" maxlength="75" formControlName="firstName" id="firstName" required minlength="2" type="text" [(ngModel)]="agentModel.firstName" class="form-control" name="firstName" placeholder="First Name">
                                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                                        <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                                        <div *ngIf="f.firstName.errors.minlength">First Name must be at least 2 characters in length</div>
                                                    </div>                                                
                                                </div>
                                            </div>
                                        
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="lastName" class="font-weight-normal">Last Name</label>
                                                <input autocomplete="off" maxlength="75" type="text" formControlName="lastName" required minlength="2" class="form-control" id="lastName" name="lastName" [(ngModel)]="agentModel.lastName" placeholder="Last Name">
                                                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                                    <div *ngIf="f.lastName.errors.minlength">Last Name must be at least 2 characters in length</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="prefferedName" class="font-weight-normal">Preferred Name</label>
                                                <input autocomplete="off" maxlength="75" type="text" formControlName="prefferedName" required class="form-control" minlength="2" id="prefferedName" [(ngModel)]="agentModel.prefferedName" name="prefferedName" placeholder="Preferred Name">
                                                <div *ngIf="submitted && f.prefferedName.errors" class="invalid-feedback">
                                                    <div *ngIf="f.prefferedName.errors.required">Preffered Name is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="primaryMobile" class="font-weight-normal">Primary Mobile</label>
                                                <input autocomplete="off" type="text" pattern="^(\+)?\d{11}$" formControlName="primaryMobile" class="form-control" id="primaryMobile" [(ngModel)]="agentModel.primaryMobile" name="primaryMobile" placeholder="Primary Mobile Number">
                                                <div *ngIf="submitted && f.primaryMobile.errors" class="invalid-feedback">
                                                    <div *ngIf="f.primaryMobile.errors.required">Last Name is required</div>
                                                    <div *ngIf="f.primaryMobile.errors.pattern">Primary mobile must be length 11 and type of +61445678956.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="contactNumber" class="font-weight-normal">Contact Number</label>
                                                <input autocomplete="off" type="text" pattern="^(\+)?\d{11}$" formControlName="contactNumber" class="form-control" id="contactNumber" [(ngModel)]="agentModel.contactNumber" name="contactNumber" placeholder="Contact Number">
                                                <div *ngIf="submitted && f.contactNumber.errors" class="invalid-feedback">
                                                    <div *ngIf="f.contactNumber.errors.required">Last Name is required</div>
                                                    <div *ngIf="f.contactNumber.errors.pattern">Contact number must be length 11 and type of +61445678956.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn clr_button upload_button font-weight-normal mt-3" style="font-size: 12px; border-radius: 100px;">UPDATE DETAILS</button>
                                </form>
                            </div>
                        </div>
                    </div>
                 </div>
                 <div class="col-md-3 mobile_c">
                        <div class="card">
                            <div class="text-center pb-4 mt-3">
                                <!-- <div class="pl-1 pr-1">
                                        <img src="../../assets/img/user.jpg"  class="image_up img-fluid mb-2 mt-4">
                                </div> --> 
                                <!-- <img src="../../assets/img/user.jpg" class="image_up avatar img-circle img-thumbnail mt-4" alt="avatar"> -->
                                <img class="image_up avatar img-circle img-thumbnail mt-4" [src]="agentModel.profileImageUrl" (error)="agentModel.profileImageUrl = '../../assets/img/user.jpg'" alt="avatar">
                                <h5 class="font-weight-normal name_clr mt-4">
                                        {{agentModel.firstName}} {{agentModel.lastName}}
                                </h5>
                                <span class="font-weight-normal name_clr_link">
                                    <input name="profileImage" accept=".jpg,.jpeg,.png" (change)="onFileChange($event)" type="file" id="inputGroupFile01" class="">
                                    <!-- [(ngModel)]="documentModel.document" -->
                                    <!-- <input type="file" formControlName="userImage" class="form-control" [(ngModel)]="agentModel.userImage" > -->
                                    Change Profile Image?
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
    </div>

    <ngx-ui-loader  ></ngx-ui-loader>
<app-footer></app-footer>