import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FolderResponse, DocumentResponse, DocumentAddResponse, DocumentUrlResponse, DocumentResponseOne, DocumentPublicResponse } from '../shared/models/response.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { DocumentEditModel } from '../shared/models/document.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  baseUrl:String;
  authUrl:String;
  
  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = environment.getBaseURL;
    this.authUrl = environment.getAuthURL;
  }   

  public getDocumentList(folder_id){  
    const params = new HttpParams().set('folderId', folder_id); 
    return this.http.get<DocumentResponse>(this.baseUrl+'/Document/GetByFolderId',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public getDocument(document_id){
    const params = new HttpParams().set('id', document_id); 
    return this.http.get<DocumentResponseOne>(this.baseUrl+'/Document/GetById',{params}).pipe(
      catchError(this.handleError)
      );
  }

  public getPublicDocument(companyCode,document_id){
    const params = new HttpParams().set('companyCode', companyCode).set('documentId', document_id); 
    return this.http.get<DocumentResponseOne>(this.authUrl+'/PublicBusiness/DocumentById',{params}).pipe(
      catchError(this.handleError)
      );
  }

  public getPublicDocumentList(companyCode, folder_id){  
    const params = new HttpParams().set('companyCode', companyCode).set('folderId', folder_id); 
    return this.http.get<DocumentPublicResponse>(this.authUrl+'/PublicBusiness/Document',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public handleError(error) {
    return throwError(error);
  }
  
  public documentAdd(folderModel, business_id){
    folderModel.createdById = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    folderModel.businessId = business_id;
    return this.http.post<DocumentAddResponse>(this.baseUrl +'/Document', folderModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public documentAddSubFolder(folderModel, business_id){
    folderModel.createdById = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    // folderModel.businessId = business_id;
    return this.http.post<DocumentAddResponse>(this.baseUrl +'/Document', folderModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public moveToThrash(document, business_id, folder_id){
    const params = new HttpParams().set('id', document.id).set('businessId', business_id).set('folderId', folder_id); 
    return this.http.delete<Response>(this.baseUrl +'/Document', {params}).pipe(
      catchError(this.handleError)
      ); 
  }

  public documentEdit(folderModel, dockId) {    
    const params = new HttpParams().set('id', dockId);  
    // folderModel.businessId = business_id;
    // folderModel.createdById = this.cookieService.get('CurrentUserId');
    // folderModel.companyCode = this.cookieService.get('companyCode'); 
    return this.http.put<DocumentAddResponse>(this.baseUrl+'/Document',folderModel,{params}).pipe(
      catchError(this.handleError)
      );
  }

  // public deleteDocument(fileDelete ){  
  //   console.log(123456789);
  //   console.log(fileDelete);
  //   //let model = {'id':document.id, 'entityType': 3};        
  //   return this.http.delete<Response>(this.baseUrl +'/Trash/PermanentDelete', fileDelete).pipe(
  //     catchError(this.handleError)
  //     );    
  // }

}
