import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FolderResponse, NoticeBoardResponse, NoticeBoardResponseOne, PublicNoticeBoardResponse } from '../shared/models/response.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FolderEditModel } from '../shared/models/folder.model';
import { CookieService } from 'ngx-cookie-service';
import { NoticeBoardEditModel } from '../shared/models/notice-board.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NoticeBoardService {

  baseUrl:String;
  authUrl: String;
  gridView:boolean = true;
  
  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = environment.getBaseURL;
    this.authUrl = environment.getAuthURL;
  }   

  public getNoticeBoardList(business_id){  
    const params = new HttpParams().set('BusinessId', business_id); 
    return this.http.get<NoticeBoardResponse>(this.baseUrl+'/Notice/GetByBusinessId',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public getPublicNoticeBoardList(companyCode){  
    const params = new HttpParams().set('companyCode', companyCode); 
    return this.http.get<PublicNoticeBoardResponse>(this.authUrl+'/PublicBusiness/Notice',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public handleError(error) {
    return throwError(error);
  }

  public noticeAdd(noticeBoardEditModel, business_id){
    noticeBoardEditModel.createdById = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    noticeBoardEditModel.lastUpdatedBy = this.cookieService.get('CurrentUserId');
    //noticeBoardEditModel.businessId = business_id;
    return this.http.post<NoticeBoardResponse>(this.baseUrl +'/Notice', noticeBoardEditModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public deleteNotice(noticeBoardEditModel){
    return this.http.delete<Response>(this.baseUrl +'/Notice?id='+noticeBoardEditModel.id).pipe(
      catchError(this.handleError)
      );     
  }

  public noticeEdit(noticeBoardEditModel, id:string) {  
    //noticeBoardEditModel.createdById = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    // console.log("----------------------");
    // console.log(noticeBoardEditModel.expiredDate);
    noticeBoardEditModel.lastUpdatedBy = this.cookieService.get('CurrentUserId');
    const params = new HttpParams().set('id', id); 
    // let inv = Object.assign({}, invoice); 
    // inv.amount = invoice.amount*100;
    // //inv.dueDate = new Date(invoice.dueDate);
    // inv.gst = invoice.gst*100;
    return this.http.put<NoticeBoardResponse>(this.baseUrl+'/Notice',noticeBoardEditModel,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public getNotice(document_id){
    const params = new HttpParams().set('id', document_id); 
    return this.http.get<NoticeBoardResponseOne>(this.baseUrl+'/Notice/GetById',{params}).pipe(
      catchError(this.handleError)
      );
  }
  public getPublicNotice(companyCode,document_id){
    const params = new HttpParams().set('companyCode', companyCode).set('noticeId', document_id); 
    return this.http.get<NoticeBoardResponseOne>(this.authUrl+'/PublicBusiness/NoticeById',{params}).pipe(
      catchError(this.handleError)
      );
  }

  public setGridView(view) {
    this.gridView = view;
  }

  public getGridView() {
    return this.gridView ;
  }

}
