import { Component, OnInit, Renderer2, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { User } from '../shared/models/user.model';
import { AuthService } from '../auth/auth.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FolderSearchAllResponse, FolderSearchResponse } from '../shared/models/response.model';
import { FolderSearchModel } from '../shared/models/document.model';
import { LibraryService } from '../library/library.service';
import { delay } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChangePasswordComponent } from '../auth/change-password/change-password.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isSidebarToggeled: boolean = false;
  searchLoaded: boolean = false;
  currentUser: User;
  menuList:any;
  business_id:any;
  searchText:string = "";
  searchResults: FolderSearchModel[]=[];
  searchStr;
  selectedMenuItem:any;
  private wasInside = false;

  constructor(
    private router: Router,
    private libraryService: LibraryService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private authService: AuthService, 
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private document: Document,
    ) {
      this.currentUser = this.authService.getCurrentUserValue;
     }
  
    @HostListener('click')
    clickInside() {
      //console.log("clicked inside");
      this.wasInside = true;
    }
    
    @HostListener('document:click')
    clickout() {
      if (!this.wasInside) {
      // console.log("clicked outside");
        this.searchStr = "";
        this.searchText = "";
        this.searchLoaded = false;
      }
      this.wasInside = false;
    }   

  ngOnInit() {
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    this.business_id = this.route.snapshot.queryParams['business'];    //console.log(localStorage.getItem('menuList'));
    this.selectedMenuItem = this.menuList.find(x => x.id == this.business_id);
   
    localStorage.setItem('selectedMenu', JSON.stringify(this.selectedMenuItem));    
    if(!this.business_id){
      this.authService.redirectIfAuth();
    }
  }

  toggleSidebar() {
    this.isSidebarToggeled = !this.isSidebarToggeled;
    //console.log(this.isSidebarToggeled);
    this.getClasses();
    return this.isSidebarToggeled;
  }

  getClasses() {    
    let element = document.getElementById('wrapper')

    if(this.isSidebarToggeled){
      element.className +=' toggled';
      //this.renderer.addClass(this.document.body, 'toggled');
      //this.renderer.removeClass(this.document.body, 'menu-expanded');
      return 'toggled';
    }
    else{
      //this.renderer.addClass(this.document.body, 'menu-expanded');
      //this.renderer.removeClass(this.document.body, 'toggled');
      element.classList.remove("toggled");
      return 'toggled';
    }
  }

  logOut(){
    this.authService.logout();
    // .pipe(first())
    // .subscribe(
    //   data => {
    //     if(data.success){
    //       window.location = window.location;
    //     }else{          
    //     }
    //   },
    //   error => {
    //     console.log(error);
    //   }        
    // );
  }

  searchDocuments(searchText){  
    this.searchText = searchText;
    //this.searchResults = [];
    this.searchLoaded = false;
    if(searchText.length > 2){
  this.libraryService.searchDocumentsAllBusiness(this.business_id,searchText).pipe(delay(1000)).subscribe((response:any )=>{ 
        
    // this.libraryService.searchDocuments(this.business_id,searchText).pipe(delay(1000)).subscribe((response:any )=>{ 
       //these two comment by tharindu 
       // if(response.data){
          if(response){
          this.searchLoaded = true;
          console.log(response);
         // this.searchResults = response.data;   
         this.searchResults = response.data; 
               console.log(this.searchResults );
        }         
       },
       (err: HttpErrorResponse)=>{
         console.log("Error"+ err);
       });
    }         
  }

  viewDocument(document){
    console.log(document);
    if(document.folder){
      // console.log(document);
      this.router.navigate(['document'], { queryParams: {business: document.folder.businessId, folder: document.folder.id, folder_name: document.folder.name, document_id: document.id }});
    }
  }

  closeSearch(){
    this.searchStr = "";
    this.searchText = "";
    this.searchLoaded = false;
  }

  // loadMenu(){
  //   this.menuList = JSON.parse(localStorage.getItem('currentUser'));
  // }

  viewPortalPermission(permission){
    return this.authService.viewPortalPermission(permission,this.business_id);
  }

  getBusinessName(businessId){
    let business = this.menuList.find(x => x.id == businessId);
    if(business && business.name){
      return business.name+', ';
    }
    else{
      return null;
    }
  }

  openChangePasswordModal(){
    this.modalRef = this.modalService.show(ChangePasswordComponent,  {
      initialState: {
        heading_title: 'Change Password', 
        // business_id:this.business_id,
        data: {          
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        // this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  setingsgo(){
   
    this.router.navigate(['twofactorsettings'], { queryParams: { business: this.selectedMenuItem.id }});
  }

}
