import { Component, OnInit, TemplateRef } from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net';

import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TrashService } from './trash.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NoticeBoardResponse, TrashResponse, NoticeBoardResponseOne, DocumentPublicResponseOne, NoticeBoardPublicResponseOne } from '../shared/models/response.model';
//import { AddDocumentComponent } from './add-document/add-document.component';
import { FileUploadService } from '../file-upload/file-upload.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TrashEditModel } from '../shared/models/thrash.model';
import { FileDelete } from '../shared/models/file-upload.model';
import { AuthService } from '../auth/auth.service';
import { NoticeBoardViewModel } from '../shared/models/notice-board.model';
// import { AddNoticeComponent } from './add-notice/add-notice.component';
import { DocumentService } from '../document/document.service';
import { DocumentResponse, DocumentUrlResponse, DocumentResponseOne } from '../shared/models/response.model';
import { DocumentViewModel } from '../shared/models/document.model';
import { NoticeBoardService } from '../notice-board/notice-board.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trash',
  templateUrl: './trash.component.html',
  styleUrls: ['./trash.component.css']
})
export class TrashComponent implements OnInit {

  baseUrl:String;
  dtOptions: DataTables.Settings = {};
  trashList:TrashEditModel[];
  trash:TrashEditModel;
  isLoaded :boolean;
  isLoaded2 :boolean;
  isLoaded3 :boolean;
  business_id : string;
  gridView :boolean = true;
  fileDelete : FileDelete;
  menuName: string;
  viewNotice:NoticeBoardViewModel;
  viewTrash:TrashEditModel;
  document:DocumentViewModel;
  noticeBoard:NoticeBoardViewModel;
  folder_name : string;
  commonCompanyCode : string;
  menuList : any;
  selectedMenu:any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private trashService: TrashService,
    public modalRef: BsModalRef,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    //private fileUploadService: FileUploadService,
    private toastr: ToastrService,
    private authService: AuthService,
    private fileUploadService: FileUploadService,
    private documentService: DocumentService,
    private noticeBoardService: NoticeBoardService,
  ) { 
    this.business_id = this.route.snapshot.queryParams['business']; 
      this.router.routeReuseStrategy.shouldReuseRoute = function() { 
      return false;
    };
    this.baseUrl = environment.getBaseURL;
    this.commonCompanyCode = environment.commonCompanyCode;
    console.log(this.commonCompanyCode);
  }

  ngOnInit() {

    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    this.selectedMenu = this.menuList.find(x => x.id == this.business_id);

    if(this.selectedMenu.isPublic) {
      this.getPublicTrashList(this.commonCompanyCode);
    }
    else {
      if(this.commonCompanyCode){
        this.getTrashList(this.business_id); 
      }
      
    } 
   
    this.menuName = this.authService.getMenuName(this.business_id); 
    this.dtOptions = {
      order: [],
      columnDefs: [{
        orderable: false,
        targets: [ 1]
      }]

    };
  }

  getTrashList(business_id){
    this.trashService.getTrashList(business_id).subscribe((data:TrashResponse)=>{     
      this.trashList = data.response;
    
      //this.trashList.sort((a, b) => new Date(b.lastUpdatedDate).getTime() - new Date(a.lastUpdatedDate).getTime());
      // this.agentsCount = this.agentList.length;
      // this.assignCopy();      
      this.isLoaded = true;      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }
  getPublicTrashList(CompanyCode){
    this.trashService.getPublicTrashList(CompanyCode).subscribe((data:TrashResponse)=>{     
      console.log(data);
      this.trashList = data.data;
     // this.trashList.sort((a, b) => new Date(b.lastUpdatedDate).getTime() - new Date(a.lastUpdatedDate).getTime());
      // this.agentsCount = this.agentList.length;
      // this.assignCopy();      
      this.isLoaded = true;      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }
  openDeleteDialog(template: TemplateRef<any>,trash) {

    this.trash = trash;
    this.modalRef = this.modalService.show(template, { 
      //class: 'modal-sm', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }

  openRestoreDialog(template: TemplateRef<any>,trash) {
    this.trash = trash;
    this.modalRef = this.modalService.show(template, { 
      //class: 'modal-sm', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }

  deleteDocument(){
    // console.log(this.trash);
    this.fileDelete = new FileDelete();
    this.fileDelete.id = this.trash.id;
    this.fileDelete.entityType = this.trash.entityType;

    this.modalRef.hide();
    this.trashService.deleteDocument(this.fileDelete).subscribe((data:any)=>{  
      if(data.error && !data.success){
        //this.ngxService.stop();  
        this.toastr.error('Error on deleting item.');
      }
      else{
        this.toastr.success('Item deleted successfully.');     
        this.router.navigate(['trash'], { queryParams: {business: this.business_id }});  
      }
               
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });         
  }

  restoreFromThrash(){
    // console.log(this.trash);
    this.modalRef.hide();
    this.trashService.restoreFromThrash(this.trash).subscribe((data:any)=>{ 
      if(data.error && !data.success){
        //this.ngxService.stop();  
        this.toastr.error('Error on restoring item.');
      }
      else{
        this.toastr.success('Item restored successfully.');     
        this.router.navigate(['trash'], { queryParams: {business: this.business_id }});   
      }

      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });   
  }

  decline(): void {
    this.modalRef.hide();
  }

  public openViewdModal(template: TemplateRef<any>,trash) {

    this.isLoaded2 = false;
    this.isLoaded3 = false;

    // this.viewNotice = notice;
    this.viewTrash = trash;

    //console.log(notice);
    //console.log(this.viewNotice);
    this.modalRef = this.modalService.show(template, { 
      class: 'modal-lg', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    if(trash.entityType == 3) {
      if(this.selectedMenu.isPublic) {
      this.documentService.getPublicDocument(this.commonCompanyCode,trash.id).subscribe((data:DocumentPublicResponseOne)=>{

        if(data.isSuccess){
          this.document=data.data;
          // this.agentsCount = this.agentList.length;
          // this.assignCopy();      
          // console.log(this.document);
          this.isLoaded2 = true;
        }
              
        },
        (err: HttpErrorResponse)=>{
          console.log("Error"+ err.message);
        });
      }else{
        this.documentService.getDocument(trash.id).subscribe((data:DocumentResponseOne)=>{

          if(data.success){
            this.document=data.response;
            // this.agentsCount = this.agentList.length;
            // this.assignCopy();      
            // console.log(this.document);
            this.isLoaded2 = true;
          }
                
          },
          (err: HttpErrorResponse)=>{
            console.log("Error"+ err.message);
          });
      }
    }
    if(trash.entityType == 4) {
      if(this.selectedMenu.isPublic) {
      this.noticeBoardService.getPublicNotice(this.commonCompanyCode,trash.id).subscribe((data:NoticeBoardPublicResponseOne)=>{
        console.log(data);
        if(data.isSuccess){
          this.noticeBoard=data.data;     
        
          this.isLoaded3 = true;
        }
              
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });
    }else{
      this.noticeBoardService.getNotice(trash.id).subscribe((data:NoticeBoardResponseOne)=>{

        if(data.success){
          this.noticeBoard=data.response;     
          // console.log(this.noticeBoard);
          this.isLoaded3 = true;
        }
              
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });
    }
    }
    //this.idToBeDeleted = id;
  }

  downloadTrashDocument(document1){ 
    // console.log(document1);      
    if(document1.s3Key){ 
      if(document1 && (document1.docType == '1' || document1.docType == '2' || document1.docType == '4') ){      
          this.ngxService.start();
          this.fileUploadService.getFileUrl(document1).subscribe((data)=>{ 
            this.ngxService.stop(); 
            let documentUrl = data.response; 
          
              this.fileUploadService.downloadFile(documentUrl,document1.fileName);
    
            },
            (err: HttpErrorResponse)=>{
              this.ngxService.stop(); 
              this.toastr.error('Unable to show document/url.'); 
              console.log("Error"+ err.message);
            });     
      }
      else{
        let url = document1.s3Key;
        if(url.search("http") != -1){
          window.open(url,'_blank');
        }
        else{
          window.open("http://"+url,'_blank');
        }
      }
    }
    else{
      console.log('s3key is not coming');
      this.toastr.error('Unable to show document/url.'); 
    }

  }


  downloadTrashFile(viewNotice){
    this.fileUploadService.downloadFile(viewNotice.url,viewNotice.name);
  }

}
