<app-header></app-header>
<div class="container-fluid pl-4 pr-4 mb-5">
  <div class="content-header row mt-4 mb-2">
    <div class="content-header-left col-md-6 col-12">
      <h3 class="content-header-title mb-0 font-weight-normal">Out of Office</h3>
      <div class="breadcrumb-wrapper">
        <ol class="breadcrumb bredcrumb_new">
          <li class="breadcrumb-item"><a routerLink="/calendar" [queryParams]="{business: business_id}">Out Of Office</a>
          </li>
          <!-- <li class="breadcrumb-item"><a href="#">DOCUMENTS</a>
                          </li> -->
          <li class="breadcrumb-item active">Leave Approval
          </li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2">
      <app-sidebar-office></app-sidebar-office>
    </div>
    <div class="col-md-10 leaved">
      <div class="row">
        <div class="table-responsive tbl_res pt-3 doc_sm_tb">
          <span class="text-left table_text">Leave List</span>
          <ng-container *ngIf="isLoaded"> 
              <table class="table " datatable [dtOptions]="dtOptions" style="width: 100%;" id="mydatatable">
                <thead>
                  <tr>
                    <th scope="col" class="text-truncate">Employee Name</th>
                    <th scope="col" class="text-truncate">Type</th>
                    <th scope="col" class="text-truncate">From</th>
                    <th scope="col" class="text-truncate">To</th>
                    <th scope="col" class="text-truncate">Days Out Of Office</th>
                    <th scope="col" class="text-truncate">Status</th>
                  </tr>
                </thead>
                <tbody>


                  <ng-container *ngFor="let leave of leaveList; let i = index" >
                      <tr *ngIf="leave.createdBy" style="cursor: pointer;" data-toggle="modal" data-target="#leavedetailsp1" (click)="openViewDialog(template1, leave)">
                        <td  class="table_datan text-truncate">
                            <ng-container *ngIf="leave.createdBy" >
                              {{ leave.createdBy.firstName }} {{ leave.createdBy.lastName }}
                            </ng-container>
                        </td>
                        <td class="table_datanll text-truncate">
                            <!-- <ng-container *ngIf="leave.leaveType && leave.leaveType == '1'"> Sick </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType == '2'"> Annual  </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType == '3'"> Parental  </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType == '4'"> Personal  </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType == '5'"> Long Service Leave  </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType == '6'"> Community Leave  </ng-container> -->

                            <ng-container *ngIf="leave.leaveType && leave.leaveType=='1'" >
                              Sick Leave
                            </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType=='2'" >
                                Annual Leave
                            </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType=='3'" >
                                Parental Leave
                            </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType=='4'" >
                              Long Service Leave
                            </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType=='5'" >
                              Time in Lieu
                            </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType=='6'" >
                              Unpaid Leave
                            </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType=='7'" >
                              Study Leave
                            </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType=='8'" >
                              Bereavement Leave
                            </ng-container>

                        </td>
                        <td class="table_datanll text-truncate">{{ leave.startDate+'Z' | date:"dd/MM/yyy" }}  </td> <!-- mediumDate --><!-- Jun 12 2019 --> 
                        <td class="table_datanll text-truncate">{{ leave.endDate+'Z' | date:"dd/MM/yyy" }} </td> <!-- mediumDate -->
                        <td class="table_datanll text-truncate">{{ (leave.endDate | amDifference : leave.startDate : 'days')+1 }}</td>
                        <td class="table_datanll text-truncate" >
                            <!-- <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus=='1'" >
                                <label class="status_app"> Confirm </label>
                            </ng-container> -->
                            <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus=='2'" >
                                <label class="status_can"> Cancelled </label>
                            </ng-container>
                            <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus=='3'" >
                                <label class="status_pen"> Pending </label>
                            </ng-container>
                            <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus=='4'" >
                                <label class="status_app"> Approved </label>
                            </ng-container>
                            <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus=='5'" >
                                <label class="status_dec"> Declined </label>
                            </ng-container>
                        </td>
                      </tr>
                  </ng-container>


                </tbody>
              </table>
          </ng-container>
          <ng-container *ngIf="!isLoaded"> 
              <table datatable class="table " style="width: 100%;" id="mydatatable">
                  <thead>
                      <tr>
                          <th scope="col" class="text-truncate">Employee Name</th>
                          <th scope="col" class="text-truncate">Type</th>
                          <th scope="col" class="text-truncate">From</th>
                          <th scope="col" class="text-truncate">To</th>
                          <th scope="col" class="text-truncate">Days Out Of Office</th>
                          <th scope="col" class="text-truncate">Status</th>
                      </tr>
                  </thead>
                  <tbody>
                  </tbody>
              </table>
              </ng-container>

        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #template1>
        <form [formGroup]="setLeaveStatusForm" novalidate class="form " (ngSubmit)="onSubmit()" >
  
            <div class="modal-header border-bottom-0 pb-0">
              <button type="button" (click)="decline()" class="close" aria-label="Close"> <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body  pt-0 pb-3 pl-4 pr-4">
              <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">Leave Details</h4>
              <ng-container *ngIf="isLoaded"> 
                  <table class="table table-borderless table-sm pro_tab table-responsive w-100 d-block d-md-table leaveview">
                    <tbody>
                      <tr>
                        <td class="pl-0 text-truncate leavel_fs">Leave Status</td>
                        <td class="profile_td text-left text-truncate leavel_fs">:  
                            <!-- <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==1" >
                               <span class="status_app"> Confirm </span><span style="color: #424242;"></span>
                            </ng-container> -->
                            <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==2" >
                              <span class="status_can" style="padding: 2px 25px;"> Cancelled </span><span style="color: #424242;"></span>
                            </ng-container>
                            <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==3" >
                              <span class="status_pen"  style="padding: 2px 25px;"> Pending </span> <span style="color: #424242;"></span>
                            </ng-container>
                            <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==4" >
                              <span class="status_app"  style="padding: 2px 25px;"> Approved </span>&nbsp;<span style="color: #424242;"> (Approved by {{ leave.lastUpdatedBy.firstName }} {{ leave.lastUpdatedBy.lastName }})</span>
                            </ng-container>
                            <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==5" >
                              <span class="status_dec"  style="padding: 2px 25px;">  Declined </span> <span style="color: #424242;"></span>

                            <!-- <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==4" >
                              <span class="status_app"> Approved</span><span style="color: #424242;">(Approved by {{ leave.lastUpdatedBy.firstName }} {{ leave.lastUpdatedBy.lastName }})</span>
                            </ng-container>
                            <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==5" >
                              <span class="status_dec">  Declined</span> <span style="color: #424242;">(Declined by {{ leave.lastUpdatedBy.firstName }} {{ leave.lastUpdatedBy.lastName }})</span> -->

                            </ng-container>
                          
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 text-truncate leavel_fs">Employee Name</td>
                        <td class="profile_td text-left text-truncate leavel_fs">: {{ leave.createdBy.firstName }} {{ leave.createdBy.lastName }}</td>
                      </tr>
                      <tr>
                          <td class="pl-0 text-truncate leavel_fs">Employee Department</td>
                          <td class="profile_td text-left text-truncate leavel_fs">: 
                            <ng-container *ngFor="let department of departmentList">
                                <!-- {{ leave.departmentId }} {{ department | json }} -->
                              <ng-container *ngIf="department.id == leave.departmentId">{{department.name}}</ng-container>
                            </ng-container>
                          </td>
                      </tr>
                      <tr>
                        <td class="pl-0 text-truncate leavel_fs">Leave Type</td>
                        <td class="profile_td text-left text-truncate leavel_fs">: 
                            <!-- <ng-container *ngIf="leave.leaveType && leave.leaveType == 1"> Sick </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType == 2"> Annual  </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType == 3"> Parental  </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType == 4"> Personal  </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType == 5"> Long Service Leave  </ng-container>
                            <ng-container *ngIf="leave.leaveType && leave.leaveType == 6"> Community Leave  </ng-container> -->

                            <ng-container *ngIf="leave.leaveType && leave.leaveType=='1'" >
                                Sick Leave
                              </ng-container>
                              <ng-container *ngIf="leave.leaveType && leave.leaveType=='2'" >
                                  Annual Leave
                              </ng-container>
                              <ng-container *ngIf="leave.leaveType && leave.leaveType=='3'" >
                                  Parental Leave
                              </ng-container>
                              <ng-container *ngIf="leave.leaveType && leave.leaveType=='4'" >
                                Long Service Leave
                              </ng-container>
                              <ng-container *ngIf="leave.leaveType && leave.leaveType=='5'" >
                                Time in Lieu
                              </ng-container>
                              <ng-container *ngIf="leave.leaveType && leave.leaveType=='6'" >
                                Unpaid Leave
                              </ng-container>
                              <ng-container *ngIf="leave.leaveType && leave.leaveType=='7'" >
                                Study Leave
                              </ng-container>
                              <ng-container *ngIf="leave.leaveType && leave.leaveType=='8'" >
                                Bereavement Leave
                              </ng-container>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 text-truncate leavel_fs">Date From</td>
                        <td class="profile_td text-left text-truncate leavel_fs">: {{ leave.startDate+'Z' | date:"dd/MM/yyy" }}</td> <!-- mediumDate --><!-- 06/02/2019 -->
                      </tr>
                      <tr>
                        <td class="pl-0 text-truncate leavel_fs">Date To</td>
                        <td class="profile_td text-left text-truncate leavel_fs">: {{ leave.endDate+'Z' | date:"dd/MM/yyy" }}</td> <!-- mediumDate -->
                      </tr>
                      <tr>
                        <td class="pl-0 text-truncate leavel_fs">Days out of office</td>
                        <td class="profile_td text-left text-truncate leavel_fs">: {{ (leave.endDate | amDifference : leave.startDate : 'days')+1 }}</td>
                      </tr>
                      <tr>
                        <td class="pl-0 text-truncate leavel_fs">Extra Details</td>

                        <td class="profile_td text-left text-truncate leavel_fs"  data-toggle="tooltip" title="{{leave.extraDetails}}" >: 
                          <ng-container *ngIf="leave.extraDetails"> {{ leave.extraDetails | limitTo : '30' }} </ng-container> 
                          <ng-container *ngIf="leave.extraDetails == '' || leave.extraDetails== null "> -- </ng-container> 
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 text-truncate leavel_fs">Leave Created By</td>
                        <td class="profile_td text-left text-truncate leavel_fs">: {{ leave.createdBy.firstName }} {{ leave.createdBy.lastName }} |  {{ leave.createdAt+'z' | date:"dd/MM/yyy, h:mm a" }}</td> <!-- medium -->
                      </tr>
                      <tr>
                        <td class="pl-0 text-truncate leavel_fs">Last Updated By</td>
                        <td class="profile_td text-left text-truncate leavel_fs">: {{ leave.lastUpdatedBy.firstName }} {{ leave.lastUpdatedBy.lastName }} |  {{ leave.lastUpdatedDate+'z' | date:"dd/MM/yyy, h:mm a" }}</td> <!-- medium -->
                      </tr>
                      <tr>
                        <td colspan="2" style="padding: 4px 0px 6px;">
                            <div class="form-group">
                                <label for="message-text" class="col-form-label d-flex font-weight-normal leavel_fs pt-0">Action</label>
                                <select class="form-control " required formControlName="setLeaveStatus" [(ngModel)]='leave.setLeaveStatus'  >
                                    <option value="0" class="leavel_fs">Please Select Status</option>
                                    <!-- <option value="1">Confirm</option> -->
                                    <option value="2" class="leavel_fs">Cancel</option>
                                    <option value="3" class="leavel_fs">Pending</option>
                                    <option value="4" class="leavel_fs">Approved</option>
                                    <option value="5" class="leavel_fs">Declined</option>
                                </select>

                                <div *ngIf="submitted && f.setLeaveStatus.errors" class="invalid-feedback">
                                    <div *ngIf="f.setLeaveStatus.errors.required">Leave Status is required</div>
                                </div>
                    
                            
                                <!-- <div class="invalid-feedback">
                                    <div *ngIf="setLeaveStatus.invalid && (setLeaveStatus.dirty || setLeaveStatus.touched)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="setLeaveStatus.errors.required">
                                          Leave status is required.
                                      </div>
                                    </div>
                                </div> -->
                        
                            </div>
                        </td>
                          
                      </tr>
                    </tbody>
                    <div class="modal-footer justify-content-start border-top-0 nb-footer pl-0 pt-0 pb-0">

                        <button class="btn clr_button upload_button font-weight-normal text-uppercase"
                          style="font-size: 12px; border-radius: 100px;  padding: 7px 15px;">Submit Now</button>
                        &nbsp;
                        OR
                        &nbsp;
                        <button type="button" class="btn pl-0 pr-0 nb_can_btn text-uppercase" (click)="decline()"
                          data-dismiss="modal">Cancel</button>
                      </div>
                  </table>
              </ng-container>
            </div>
        </form>  
</ng-template>


<app-footer></app-footer>
