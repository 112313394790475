export class FolderBaseMOdel {    
   public id: string;
   public isArchived: boolean = false;
   public createdDate: string;
   public name: string;
   public createdById: string; 
   public businessId: string;
   public s3Key: string;
   public description: string;
   public companyCode: string;
   public parentFolderId: string;
}

export class FolderViewModel extends FolderBaseMOdel{
   
}

export class FolderEditModel extends FolderBaseMOdel{
   
}