<nav class="navbar navbar-expand-lg navbar_color pt-0 pb-0">
    <a class="navbar-brand mr-0" href="#">
        <div class="sidebar-heading text-center sidebar_h p-0"> 
            <!-- <img _ngcontent-ked-c3="" alt="" class="logo_icon" src="../../assets/img/logo.png"> 
            <img _ngcontent-ked-c3="" alt="" class="logo_name" src="../../assets/img/logo_name.png">  -->
            <img alt="" class="logo_name" src="../../assets/img/ffl_logo.png" style="height: 40px; display: none;">
        </div>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <i class="fa fa-ellipsis-v" aria-hidden="true"></i> </button>
    <div class="collapse navbar-collapse mobile_drop" id="navbarSupportedContent">
        <ul class="navbar-nav navitem_l">
            
            <ng-container *ngFor="let menu of menuList; let i = index">  
                <li class="nav-item " > 
                    <a class="nav-link" [ngClass]="[menu.id===business_id ? 'active' : '']" routerLinkActive="active" routerLink="/dashboard" [queryParams]="{business:menu.id}"  > {{menu.name}} <span class="sr-only">(current)</span></a> 
                </li>
            </ng-container>   

            <!-- <li class="nav-item active"> <a class="nav-link" routerLink="/dashboard" routerLinkActive="active" >MY FORTRESS <span class="sr-only">(current)</span></a> </li> -->
            <!-- [routerLinkActiveOptions]="{exact: true}" -->
            <!-- <li class="nav-item"> <a class="nav-link" href="#">DALLECORT</a> </li>
            <li class="nav-item"> <a class="nav-link" href="#">AUSTAX</a> </li>
            <li class="nav-item"> <a class="nav-link" href="#">MY MORTAGE PARTNER</a> </li> -->
        </ul>
        <ul class="navbar-nav ml-auto">
            <!-- <form class="search_form"> -->
                <div class="input-group  mr-2 res_search">
                    <input type="text" class="form-control header_search" (ngModelChange)="searchDocuments($event)" [(ngModel)]="searchStr" placeholder="Search documents in all businesses..." style="margin-top: 7px;  font-size: 13px; padding-top: 19px; padding-bottom: 19px;">
                    <!-- {{selectedMenuItem.name}}  -->
                    <div class="input-group-prepend"> <span class="input-group-text header_search_icon" style="height: 38px; margin-top: 7px;"><i class="fa fa-search" style="font-size: 16px;"></i></span> </div>
                    <button *ngIf="searchText.length > 0" (click)="closeSearch()" style="font-size: 16px;position: absolute; display: block; text-align: right;  margin-left: 285px;   margin-top: 13px; z-index: 99;">x</button>
                </div>

                <div class="search_b" *ngIf="searchText.length > 0">
                    <ul class="search_ul">
                        <ng-container *ngIf="3 > searchText.length "> 
                            <li class="search_pad text-center" style="list-style-type: none;font-size: 13px; color: #424242;">Please type at least 3 characters.</li>
                        </ng-container>
                        <ng-container *ngIf="!searchLoaded && searchText.length > 2 "> 
                            <li class="search_pad text-center" style="list-style-type: none;font-size: 13px; color: #424242;">Loading...</li>
                        </ng-container>
                        <ng-container *ngIf="searchText.length > 2 && searchLoaded && searchResults.length == 0"> 
                            <li class="search_pad text-center" style="list-style-type: none;font-size: 13px; color: #424242;">No result found</li>
                        </ng-container>
                        <ng-container *ngIf="searchLoaded && searchResults.length >0" > 
                            <ng-container *ngFor="let document of searchResults; let j = index">  
                                <a href="javascript:void(0);" (click)="viewDocument(document)" class="seach_a newaclr">
                                    <!-- <ng-container *ngIf="document.folder"> 
                                    <img src="../../assets/img/folder_new.png" alt="folder" height="29"> &nbsp; {{ document.folder.name | limitTo : '100' }}
                                    </ng-container> -->
                                    <span class="search_span search_new_bottom search_new_top">
                                            <img *ngIf="document && document.docType=='1'" src="../../assets/img/news_pdf.png" alt="pdf" height="21" style="margin-top: 5px;">
                                            <img *ngIf="document && document.docType=='4'" src="../../assets/img/news_doc.png" alt="doc" height="21" style="margin-top: 5px;">
                                            <img *ngIf="document && document.docType=='2'" src="../../assets/img/news_img.png" alt="img" height="21" style="margin-top: 5px;">
                                            <img *ngIf="document && document.docType=='3'" src="../../assets/img/news_link.png" alt="link" height="21" style="margin-top: 5px;">

                                            <li class="search_pad" style="padding-left: 10px; font-size: 14px;">{{document.fileName}}</li>
                                          
                                    </span>    
                                    <ng-container *ngIf="document && document.folder && document.folder.name !='' " >
                                        <span class="folder_name">(
                                            <ng-container *ngIf="document.folder.businessId && document.folder.businessId !='' " >
                                                {{getBusinessName(document.folder.businessId)}}
                                            </ng-container>                                            
                                            {{document.folder.name}}
                                            )</span>
                                    </ng-container>
                                </a>
                            </ng-container>
                        </ng-container>
                    </ul>                
                </div>   
            <!-- </form> -->
            <!-- <li class="nav-item adminpanell" > 
                    <a class="nav-link text-truncate admin_panelc" routerLink="/leave-calander" > Admin Panel</a> 
                </li> -->
            <li class="dropdown dropdown-user nav-item profile_card">
                <a class="dropdown-toggle nav-link dropdown-user-link ml-2" href="#" data-toggle="dropdown"> <span class="avatar avatar-online">
                    <img src="../../assets/img/user.jpg" class="profile_img" alt="avatar">
                   </span> <span class="ml-2 font-weight-normal profile_name mt-2">{{currentUser.userName}}</span> </a>
                <div class="dropdown-menu dropdown-menu-right profiledrop pt-0 pb-0">
                    <!-- <a class="dropdown-item" href="javascript:void(0);"> <i class="fa fa-user" aria-hidden="true"></i> Edit Profile </a> -->
                    <!-- *ngIf="viewPortalPermission('Head_of_Department') && viewPortalPermission('Head_of_Department') !=null" -->
                    <a class="dropdown-item ep_header"  href="javascript:void(0);" routerLink="/calendar" [queryParams]="{business: 'leave'}" > <i class="fa fa-calendar" aria-hidden="true"></i>&nbsp; Out of office </a>
                    <!-- <a class="dropdown-item ep_header" routerLink="/edit-profile" [queryParams]="{business: 'edit-profile'}"> <i class="fa fa-user" ></i> Edit Profile </a> -->

                    <!-- /edit-profile -->
                    <div class="dropdown-divider mt-0 mb-0"></div>
                    <a class="dropdown-item ep_header" (click)="openChangePasswordModal()" href="javascript:void(0)"> <i class="fa fa-unlock-alt" aria-hidden="true"></i> &nbsp; Update password</a>
                    <a class="dropdown-item ep_header " (click)="setingsgo()" href="javascript:void(0)" ><i class="fa fa-gear" aria-hidden="true"></i>&nbsp;Settings</a> 
                    <a class="dropdown-item ep_header" (click)="logOut()" href="javascript:void(0)"><i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Logout</a> 
                    
                </div>
            </li>
            <!-- <li class="nav-item ml-2 pr_edit_img" > 
                <span class="avatar avatar-online">
                    <img src="../../assets/img/profile.png" class="profile_img" alt="avatar">
                </span> 
                <span class="ml-2 font-weight-normal profile_name mt-2">{{currentUser.userName}}</span>
            </li>
            <li class="nav-item ml-2 pr_edit" > 
                    <a class="nav-link" href="/edit-profile" >  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;Profile
                         </a>
                </li>
                <li class="nav-item ml-2 pr_edit" > 
                        <a class="nav-link" (click)="logOut()" href="javascript:void(0)">  <i class="fa fa-sign-out" aria-hidden="true"></i>
                             </a>
                    </li> -->
            <li class="dropdown dropdown-user nav-item profile_card_responsive">
                <div class="row respon_row ml-2 mr-2">
                    <a class="nav-link ml-2"> <span class="avatar avatar-online">
          <img src="../../assets/img/profile.png" class="profile_img" alt="avatar">
        </span> <span class="ml-2 font-weight-bold profile_name mt-2"> {{currentUser.userName}} 111</span> </a>
                    <!-- <a class="nav-link ml-2 respon_icons" routerLink="/edit-profile" [queryParams]="{business: 'edit-profile'}"> <i class="fa fa-user" ></i> Edit Profile </a> -->
                    
                    <!-- *ngIf="viewPortalPermission('Head_of_Department') && viewPortalPermission('Head_of_Department') !=null" -->
                    <a class="nav-link ml-2 respon_icons" routerLink="/calendar" [queryParams]="{business: 'leave'}" > <i class="fa fa-calendar" aria-hidden="true"></i> Out of office </a>
                    <!-- <a class="nav-link ml-2 respon_icons" href="#"> <i class="fa fa-cog" aria-hidden="true"></i> Settings </a> -->
                    <a class="nav-link ml-2 mr-2 respon_icons" (click)="openChangePasswordModal()" > <i class="fa fa-unlock-alt" aria-hidden="true"></i> &nbsp; Update password</a>
                    <a class="nav-link ml-2 mr-2 respon_icons" (click)="logOut()" href="javascript:void(0)"> <i class="fa fa-sign-out" aria-hidden="true"></i> Logout </a>
                </div>
            </li>
        </ul>
    </div>
</nav>
