import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from './document.service';
import { DocumentEditModel, DocumentViewModel } from '../shared/models/document.model';
import { HttpErrorResponse } from '@angular/common/http';
import { DocumentResponse, DocumentUrlResponse, DocumentResponseOne, DocumentPublicResponse } from '../shared/models/response.model';
import { AddDocumentComponent } from './add-document/add-document.component';
import { FileUploadService } from '../file-upload/file-upload.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileDelete } from '../shared/models/file-upload.model'; 
import { TrashService } from '../trash/trash.service';
import { AuthService } from '../auth/auth.service';
import { ViewDocumentComponent } from './view-document/view-document.component';
import { AddLibraryComponent } from '../library/add-library/add-library.component';
import { AddFolderComponent } from './add-folder/add-folder.component';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
//import { AddLinkComponent } from './add-link/add-link.component';
//import * as fileSaver from '../utility/file-saver';
//import 'rxjs/Rx' ;

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  baseUrl:String;
  dtOptions: DataTables.Settings = {};
  documentList:DocumentViewModel[]=[];
  documentPublicList:DocumentViewModel[]=[];
  document:DocumentViewModel;
  isLoaded :boolean;
  business_id : string;
  folder_id : string;
  document_id : string;
  folder_name : string;
  fileDelete : FileDelete;
  menuName : string;
  commonCompanyCode : string;
  menuList : any;
  selectedMenu:any;
  
  constructor(
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private documentService: DocumentService,
    public modalRef: BsModalRef,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private fileUploadService: FileUploadService,
    private trashService: TrashService,
    private toastr: ToastrService,
    private authService: AuthService,
    private location: Location,
    ) {
      this.business_id = this.route.snapshot.queryParams['business']; 
      this.folder_id = this.route.snapshot.queryParams['folder']; 
      this.folder_name = this.route.snapshot.queryParams['folder_name']; 
      this.document_id = this.route.snapshot.queryParams['document_id'];
      this.router.routeReuseStrategy.shouldReuseRoute = function() { 
      return false;
    };
    this.baseUrl = environment.getBaseURL;
    this.commonCompanyCode = environment.commonCompanyCode;
   }

  ngOnInit() {
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    this.selectedMenu = this.menuList.find(x => x.id == this.business_id);

    if(this.selectedMenu.isPublic) {
      this.getPublicDocumentList(this.folder_id);
    }
    else {
      if(this.document_id){
       this.getDocument(this.folder_id,this.document_id); 
   
      }
      else{
       this.getDocumentList(this.folder_id); 
  
      }
    } 

    this.dtOptions = {
      //pagingType: 'full_numbers',
      //ajax: this.baseUrl+'/FortressAdmins',
      pageLength: 10,
      processing: true,
      order : []
    };  
    this.fileDelete = new FileDelete(); 
    this.menuName = this.authService.getMenuName(this.business_id);

    // console.log('in codument', JSON.parse(localStorage.getItem('breadcrumbArrayLS')))
  }

  getDocumentList(folder_id){
    this.documentService.getDocumentList(folder_id).subscribe((data:DocumentResponse)=>{     
      this.documentList = data.response;
      // this.agentsCount = this.agentList.length;
      // this.assignCopy();      
      // console.log(this.documentList);
      this.isLoaded = true;      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }

  viewPortalPermission(permission){
    return this.authService.viewPortalPermission(permission,this.business_id);
  }

  getPublicDocumentList(folder_id){
    this.documentService.getPublicDocumentList(this.commonCompanyCode, folder_id).subscribe((data:DocumentPublicResponse)=>{     
      this.documentList = data.data;
      // this.agentsCount = this.agentList.length;
      // this.assignCopy();      
      // console.log(this.documentList);
      this.isLoaded = true;      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }

  getDocument(folder_id,document_id){
    this.documentService.getDocument(document_id).subscribe((data:DocumentResponseOne)=>{

      if(data.success){
        this.documentList.push(data.response);
        // this.agentsCount = this.agentList.length;
        // this.assignCopy();      
        // console.log(this.documentList);
        this.isLoaded = true;
      }
            
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });
  }

  openAddModal() {    
    this.modalRef = this.modalService.show(AddDocumentComponent,  {
      initialState: {
        title: 'Create new document', 
        business_id:this.business_id,
        folder_id:this.folder_id,
        data: {  
          business_id:this.business_id,
          folder_id:this.folder_id,        
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.router.navigate(['document'], { queryParams: {business: this.business_id, folder: this.folder_id, folder_name: this.folder_name }}); //folder_name
      }
    });
  }

  downloadDocument(document1){ 
    // console.log(document1);      
    if(document1.s3Key){ 
    if(document1 && (document1.docType == '1' || document1.docType == '2' || document1.docType == '4') ){      
        this.ngxService.start();
        this.fileUploadService.getFileUrl(document1).subscribe((data)=>{ 
          this.ngxService.stop(); 
          let documentUrl = data.response; 
         
            this.fileUploadService.downloadFile(documentUrl,document1.fileName);
  
          },
          (err: HttpErrorResponse)=>{
            this.ngxService.stop(); 
            this.toastr.error('Unable to show document/url.'); 
            console.log("Error"+ err.message);
          });     
    }
    else{
      let url = document1.s3Key;
      if(url.search("http") != -1){
        window.open(url,'_blank');
      }
      else{
        window.open("http://"+url,'_blank');
      }
    }
  }
  else{
    console.log('s3key is not coming');
    this.toastr.error('Unable to show document/url.'); 
  }

  }

  viewDocument(document1){
    if(document1.s3Key){
      
      this.ngxService.start();
      this.fileUploadService.getFileUrl(document1).subscribe((data)=>{ 
        this.ngxService.stop(); 
        let documentUrl = data.response; 
       
        this.modalRef = this.modalService.show(ViewDocumentComponent,  {
          initialState: {
            title: 'View document', 
            business_id:this.business_id,
            folder_id:this.folder_id,
            data: {
              documentUrl : documentUrl
            },
          },
          keyboard: false,
          backdrop: true,
          ignoreBackdropClick: true,
          class: "modal-lg"
        });

        },
        (err: HttpErrorResponse)=>{
          this.ngxService.stop(); 
          this.toastr.error('Unable to show document.'); 
          console.log("Error"+ err.message);
        });     

    }
    else{
      console.log('s3key is not coming');
      this.toastr.error('Unable to show document.'); 
    }    
  }

  public openDeleteDialog(template: TemplateRef<any>,document) {

    this.document = document;
    this.modalRef = this.modalService.show(template, { 
      //class: 'modal-sm', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
    // console.log(7777777777);
    // console.log(this.document);
  }

  deleteDocument(){
    // console.log(7777777777);
    // console.log(this.document);
    this.modalRef.hide();
    // console.log(this.fileDelete);
    this.fileDelete.id = this.document.id;
    this.fileDelete.entityType = 3;
    this.trashService.deleteDocument(this.fileDelete).subscribe((data:any)=>{   
      if(data.error && !data.success){
        this.toastr.error('Error on deleting document.');
      }
      else{
        this.toastr.success('Document deleted successfully.');     
        this.router.navigate(['document'], { queryParams: {business: this.business_id, folder: this.folder_id, folder_name: this.folder_name }});   
      }
              
      },
      (err: HttpErrorResponse)=>{
        this.toastr.error('Error on deleting document.');
        console.log("Error"+ err.message);
      });         
  }

  moveToThrash(){
    // console.log(this.document);
    this.modalRef.hide();
    this.documentService.moveToThrash(this.document, this.business_id, this.folder_id).subscribe((data:any)=>{

      if(data.error && !data.success){
        this.toastr.error('Error on moving to trash.');
      }
      else{
        if (this.document.docType != 5) {
          this.toastr.success('Document moved to trash successfully.');    
        } else {
          this.toastr.success('File moved to trash successfully.');    
        }
         
        this.router.navigate(['document'], { queryParams: {business: this.business_id, folder: this.folder_id, folder_name: this.folder_name }});   
      }  
               
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });   
  }

  decline(): void {
    this.modalRef.hide();
  }

  openAddSubFolderModal() {
    
    this.modalRef = this.modalService.show(AddFolderComponent,  {
      initialState: {
        title: 'Create new file library',
        business_id:this.business_id,
        data: {  
          folder_id:this.folder_id, 
          business_id:this.business_id,    
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {    
      // console.log("result");
      // console.log(result);
  
      if (result == 'OK') {
        this.router.navigate(['document'], { queryParams: {business: this.business_id, folder: this.folder_id, folder_name: this.folder_name }}); //folder_name    
      }
    });
  }

  viewFolder(document:DocumentEditModel){
    let breadcrumbArray = [];
    this.router.navigate(['document-sub'], { queryParams: {business: this.business_id, folder: document.refFolderId, folder_name: document.fileName, parentFolderId: document.folderId, parentFolderName: this.folder_name}});  // , breadcrumbArray: breadcrumbArray
  }

  goBack() {
    // window.history.back();
    this.location.back();

    console.log( 'goBack()...' );
  }

  openEdit(folder){
    this.modalRef = this.modalService.show(AddFolderComponent,  {
      initialState: {
        heading_title: 'Edit sub folder',
        business_id:this.business_id,
        data: {
          library:folder
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        this.router.navigate(['document'], { queryParams: {business: this.business_id, folder: this.folder_id, folder_name: this.folder_name }}); //folder_name
      }
    });
  }

  // openAddLinkModal() {    
  //   this.modalRef = this.modalService.show(AddLinkComponent,  {
  //     initialState: {
  //       title: 'Create new link', 
  //       business_id:this.business_id,
  //       folder_id:this.folder_id,
  //       data: {          
  //       },
  //     },
  //     keyboard: false,
  //     backdrop: true,
  //     ignoreBackdropClick: true,
  //     class: "modal-lg"
  //   });
  
  //   this.modalRef.content.event.subscribe(result => {    
  //     // console.log(result);
  //     if (result == 'OK') {
  //       this.router.navigate(['document'], { queryParams: {business: this.business_id, folder: this.folder_id, folder_name: this.folder_name }}); //folder_name
  //     }
  //   });
  // }


}
