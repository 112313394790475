import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PasswordChangeModel } from 'src/app/shared/models/changepassword.model';
import { ChangePasswordResponse } from 'src/app/shared/models/response.model';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  submitted = false;
  changePasswordForm: FormGroup;
  passwordChangeModel : PasswordChangeModel ;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  hide1:boolean = true;
  hide2:boolean = true;
  errors;

  constructor(
    public modalRef: BsModalRef,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.passwordChangeModel = new PasswordChangeModel();  
    this.Isvalid = true; 
    

    this.changePasswordForm = this.formBuilder.group({  
      oldPassword: ['', [Validators.required]],     // , Validators.minLength(8), Validators.maxLength(25)
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
    }); 
  }

  onSubmit(){
    this.submitted = true;
    let oldPassword = this.passwordChangeModel.oldPassword;
    let newPassword = this.passwordChangeModel.newPassword;
    
    if (this.changePasswordForm.invalid || (oldPassword && oldPassword.trim()=="") || (newPassword && newPassword.trim()=="")  ) {
   
     if(oldPassword && oldPassword.trim()==""){
       this.changePasswordForm.controls['oldPassword'].setErrors({'required': true});
     }
   
     if(newPassword && newPassword.trim()==""){
      this.changePasswordForm.controls['newPassword'].setErrors({'required': true});
    }
   
     // console.log(this.f);
     // console.log(this.changePasswordForm.invalid);
     return;
   }
   else{
   
     
     this.ngxService.start();
     this.passwordChangeModel.userId = this.cookieService.get('CurrentUserId');  
     
    //  if(this.documentId && this.documentId !=""){
    //    this.passwordChangeModel.documentId = this.documentId;
    //  }
     this.authService.updatePassword(this.passwordChangeModel)
     .subscribe((data:ChangePasswordResponse)=>{
          this.ngxService.stop();
          
          if(data.validationIssues || !data.isSuccess){     
            // if(data.error.message){
            //   this.errors = data.error.message;
            // }
            // else{
            //   this.errors = Utility.GetLocalErrorString(data.error.errorCode);
            // }
            
            this.toastr.error('Current password is a mismatch.');      
          }
          else{
            
            this.toastr.success('New password updated successfully.');
            this.event.emit('OK');
            this.modalRef.hide();
          }
       
      },err=>{   
        console.log(err);
        this.ngxService.stop();
        this.toastr.error('Error on saving new password.');
      });
   
     
   
   }
   
   }

   get f() { return this.changePasswordForm.controls; }
  
modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

}
