<div *ngIf="mfaconfig=='1'">
    <app-header></app-header>
</div>

<div class="container-fluid pl-4 pr-4 mb-5">
    
    <div class="row">
        <div class="col-md-3 resp_sidebar_icons" style="margin-top: 70px;">
            <div class="content-header row mt-4 mb-2 header_namer">
                <div class="content-header-left col-md-12 col-12">
                    <h3 class="content-header-title mb-0 font-weight-normal">Settings</h3>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb bredcrumb_new">
                            <li class="breadcrumb-item"><a href="/setings">Settings</a> </li>
                            <li class="breadcrumb-item active">Multi Factor Authentication</li>
                        </ol>
                    </div>
                </div>
               
            </div>
           <app-setingsidebar></app-setingsidebar>
        </div>

        <div  class="col-md-9">
            <div *ngIf="mfaconfig=='0'">
                       <div class="row" >
                   <div class="col-md-12" style="margin-top: 40px;">
                        <h3>Configure authenticator app</h3>
                      
                        <h6>To use an authenticator app go through the following steps. </h6>
                        <ol>
                            <li>Download the Google Authenticator app on your mobile device. </li>
                            <li>Scan the QR code bellow or enter the key <b>{{manualEntryKey}}</b> in to your app. Spaces and casing do not mater</li>
                            <div id="img"></div>
                            <li>Once you have scaned the QR code or input the key above, your two factor authentication app will provide you with a unique code. Enter the code in the confirmation box below and then press the verify button. </li>
                        </ol>
                        <ul style="list-style-type: none;">
                            <li>
                        <form [formGroup]="multiFactorRegisterForm" novalidate class="form " (ngSubmit)="onSubmit()" >
                             <div class="form-group">
                          
                          
                                <input matInput type="number" required formControlName="mfaKey" style="text-align: center; width: 300px;" [(ngModel)]='multifactorAuthenticationModel.mfaKey' class="form-control" id="mfaKey" placeholder="Enter Code" size="10">
                             
                           
                            <div *ngIf="submitted && f.mfaKey.errors" class="invalid-feedback">
                                <div *ngIf="f.mfaKey.errors.required">Two Factor Code Required</div>
                               
                            </div>  


                            <div class="modal-footer justify-content-start border-top-0 nb-footer pb-4" style="margin-left: -22px;">
                                <div class="form-group">
                                    <div *ngIf="!Isvalid"   class="form_alert">
                                            <span  class="ft-alert-circle"></span>
                                            Please Enter Correct Two Factor Code
                                    </div>
                                    <div *ngIf="errors"   class="form_alert">
                                        <span  class="ft-alert-circle"></span>
                                        {{errors}}
                                    </div>
                                </div>
                                <div class="button-container-div">
                                <button class="btn clr_button upload_button font-weight-normal text-uppercase" style="font-size: 20px;border-radius:200px; padding: 7px 15px;">Verify</button>                
                              </div>
                            </div>

                        </div>

                        </form>
                    </li>
                    </ul>
                </div>

            
            
            </div>
        </div>
        <!--config finish-->
        <div *ngIf="mfaconfig=='1'" style="margin-top: 40px;">
<div class="container" >
    <div class="row">
        <div class="col-md-8">

        </div>
        <div class="col-md-4">
            <a  (dblclick)="resetMfa()" ngbTooltip="Please double click to reset"><span class="resetlink" >[Re-configure Two Factor Authentication]</span></a>
        </div>
    </div>


    <div class="row" >
        <div class="col-md-2"></div>

        
        <div class="col-md-8 container-items">
            <div class="modal-body d-flex justify-content-center flex-column">
                <img src="../../../assets/img/autheticator-ico.svg" class="w-30 mx-auto" />
                <h5 class="text-center mt-1">Google Authenticator app have been successfully configured</h5>
                   <p class="text-center mt-1">Please reach out the admin if you need any assistance</p> 
                
                <!-- <div class="d-flex flex-row align-items-center justify-content-center">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                    <p class="mb-0">Yes, I understand the risk</p>
                </div> -->
        
                
            </div>
        </div>
        <div class="col-md-2"></div>

        
    </div>
</div>
        </div>
 <!--after Config-->

        </div>

    </div>
</div>


    

<app-footer></app-footer>