export class FileUpload {
    public  file : File;
    public  fileType : number;
    public  userId : string ;
    public  fileCategory : string;
    public  fileName : string;
    public  folderId : string;
    public  isArchived : boolean =  false;
}

export class FileUploadAddModel extends FileUpload{

}

export class FileUploadAddNoticeModel extends FileUpload{
    public businessId: string;
}

export class FileDelete {
    public  id : string;
    public  entityType : number;
}