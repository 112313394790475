import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.css']
})
export class ViewDocumentComponent implements OnInit {

  documentUrl : string;
  data: any;

  constructor(public modalRef: BsModalRef) { }
 
  ngOnInit() {   
    //this.data.documentUrl = "https://file-examples.com/wp-content/uploads/2017/02/file-sample_100kB.docx"; 
    //console.log(this.data.documentUrl);    
    if(this.data.documentUrl != null || this.data.documentUrl != ""){
      this.documentUrl= this.data.documentUrl;
      //console.log(this.documentUrl);
    }
  }


modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

}
