<div class="list-group list-group-flush borderside">    
   
  <ul class="m-0 p-0 sidebar_ul d-flex flex-sm-row flex-md-column">
     
    <!-- [src]="selectedMenuItem.url" -->
      <li class="sidebar_z">
          <a  class="sidebar_nav " routerLinkActive="active" >
            <i class="fa fa-podcast pl-1 pr-1"></i> 
            <span class="res_icon">Multi Factor Authentication</span>
            <i  class="fa fa-angle-right float-right right_arrow_side" aria-hidden="true"></i>
          </a> 
      </li>

     

     
  </ul>
</div>  
