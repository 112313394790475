import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FolderResponse, FolderSearchResponse, FolderPublicResponse, SubFolderResponse } from '../shared/models/response.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FolderEditModel } from '../shared/models/folder.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  baseUrl:String;
  authUrl:String;
  
  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = environment.getBaseURL;
    this.authUrl = environment.getAuthURL;
  }   

  public getFolderList(business_id){  
    //console.log(789456123);     
    const params = new HttpParams().set('BusinessId', business_id); 
    return this.http.get<FolderResponse>(this.baseUrl+'/Folder/GetByBusiness',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public getPublicFolderList(commonCompanyCode){  
    //console.log(789456123);     
    const params = new HttpParams().set('companyCode', commonCompanyCode); 
    return this.http.get<FolderPublicResponse>(this.authUrl+'/PublicBusiness/Folder',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public handleError(error) {
    return throwError(error);
  }

  public libraryAdd(folderModel, business_id){
    folderModel.createdById = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    folderModel.businessId = business_id;
    folderModel.companyCode = this.cookieService.get('companyCode');
    return this.http.post<FolderResponse>(this.baseUrl +'/Folder', folderModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public librarySubAdd(folderModel, business_id){
    folderModel.createdById = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    folderModel.businessId = business_id;
    folderModel.companyCode = this.cookieService.get('companyCode');
    return this.http.post<SubFolderResponse>(this.baseUrl +'/Folder', folderModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public libraryEdit(folderModel, business_id) {    
    const params = new HttpParams().set('id', folderModel.id);  
    folderModel.businessId = business_id;
    folderModel.createdById = this.cookieService.get('CurrentUserId');
    folderModel.companyCode = this.cookieService.get('companyCode'); 
    return this.http.put<FolderResponse>(this.baseUrl+'/Folder',folderModel,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public folderEdit(folderModel, business_id) {    
    const params = new HttpParams().set('id', folderModel.refFolderId);  
    folderModel.businessId = business_id;
    folderModel.createdById = this.cookieService.get('CurrentUserId');
    folderModel.companyCode = this.cookieService.get('companyCode'); 
    return this.http.put<FolderResponse>(this.baseUrl+'/Folder',folderModel,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public deleteFolder(folder){
    //const params = new HttpParams().set('id', folder.id); 
    return this.http.delete<Response>(this.baseUrl +'/Folder?id='+folder.id).pipe(
      catchError(this.handleError)
      );     
  }

  public searchDocuments(businessId,searchString){
    //return this.http.get<FolderSearchResponse>(this.baseUrl +'/Folder/Search?businessId='+businessId+'&searchString='+searchString);     
    return this.http.get<FolderSearchResponse>(this.baseUrl +'/Folder/Search?searchString='+searchString);     
  }

  public searchDocumentsAllBusiness(businessId,searchString){
    const companyCode = this.cookieService.get('companyCode'); 
    const businesData=JSON.parse(localStorage.getItem("menuList"));
    const businessIds=businesData.map(item => item.id);
    const businessIdList = businessIds.join('&businessIdList=')
    const url = this.authUrl +'/PublicBusiness/Search?businessIdList=' + businessIdList + '&searchString=' + searchString;
    console.log(url);
    //return this.http.get<FolderSearchResponse>(this.authUrl +'/PublicBusiness/Search?searchString='+searchString+'&companyCode='+ companyCode);  
    return this.http.get<FolderSearchResponse>(url);     
  }

  public searchDocumentsForBusiness(businessId,searchString){
    return this.http.get<FolderSearchResponse>(this.baseUrl +'/Folder/SearchWithBusiness?businessId='+businessId+'&searchString='+searchString);     
    //return this.http.get<FolderSearchResponse>(this.baseUrl +'/Folder/Search?searchString='+searchString);     
  }
  

}
