<!-- <div class="input-group login_input up_err">
        <div *ngIf="invalid" class="alert alert-danger">
            Username or Password mismatch.
        </div>
    </div> -->
    <div *ngIf="invalid" class="alert alert-danger up_err">
        Username/Password is required.
    </div>
<div class="container h-100 container_auth fp_new">
        
    <div class="d-flex justify-content-center h-100">
        <div class="user_card">
            <div class="d-flex justify-content-center">
                <div class="brand_logo_container">
                    <img src="../../../assets/img/user.png" class="brand_logo" alt="Logo">
                </div>
            </div>
            <div class="d-flex justify-content-center form_container">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" action="index.html" novalidate>
                    
                    <h4 class="text-center mb-4 mt-4 login_text">LOGIN</h4>                   
                   
                    <div class="input-group login_input">
                        <input type="text" class="form-control input_user" id="user-name" formControlName="userName" [ngClass]="{ 'is-invalid': submitted && inputs.userName.errors }" placeholder="Username" required>
                        <div class="icon_place"> <i class="fa fa-user"></i> </div>
                        <div *ngIf="submitted && inputs.userName.errors" class="invalid-feedback"></div>
                    </div>
                    <div class="input-group mb-2 login_input">
                        <input type="password" class="form-control input_pass" formControlName="password" [ngClass]="{ 'is-invalid': submitted && inputs.password.errors }" id="user-password" placeholder="Password" required>
                        <div class="icon_place"> <i class="fa fa-lock"></i> </div>
                        <div *ngIf="submitted && inputs.password.errors" class="invalid-feedback"></div>
                    </div>
                                     <div class="d-flex justify-content-center mt-4 login_container">
                            <button type="submit" name="button" class="btn login_btn" >LOGIN</button>
                        </div>
                </form>
            </div>
           
            <div class="mt-4">
                <div class="d-flex justify-content-center links">
                    <a href="/forget-password">Forgot Password?</a>
                    <!-- /forget-password javascript:void(0); -->
                </div>
            </div>
       
           <div class="mt-4 login_rights">
                <div class="d-flex justify-content-center links">
                    All Rights Reserved @ My Fortress
                </div>
            </div>
       
           <div class="mt-4 login_img">
                <div class="d-flex justify-content-center links">
                    <img src="../../../assets/img/fortress_logo.png" alt="">
                </div>
            </div>
        </div>
        
        <ngx-ui-loader *ngIf="!logged" [loaderId]="'loader-01'"></ngx-ui-loader>

    </div>
    
</div>

