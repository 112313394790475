

<form [formGroup]="multiFactorLoginForm" novalidate class="form " (ngSubmit)="onSubmit()" >
    <div class="modal-content">
        <div class="modal-header border-bottom-0 pb-0">                   
            <button type="button" class="close" data-dismiss="modal" (click)="modalRehide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body pt-0 pb-0 pl-4 pr-4">
            <img src="../../../assets/img/mfaModalImg.png" class="brand_logo center" alt="Logo" >
            <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel" class="centerTitle"> Authenticate Your Account</h4>
            <h6 class="modal-title d-flex font-weight-normal" id="exampleModalLabel" class="centerTitle"> Please Enter Authenticate Code display on your Google Authenticator Application</h6>
           <p  class="centerTitle">Google Authenticator Number</p>
            <div class="container">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">

                        <div class="form-group" class="centerTitle">
                            <label for="recipient-oldPassword" class="col-form-label d-flex font-weight-normal" ><span style="text-align: center !important;"></span></label>
                          
                                <input matInput type="text" required formControlName="mfaKey" style="text-align: center;" [(ngModel)]='multifactorAuthenticationModel.mfaKey' class="form-control" id="mfaKey" placeholder="Enter Code" >
                             
                           
                            <div *ngIf="submitted && f.mfaKey.errors" class="invalid-feedback">
                                <div *ngIf="f.mfaKey.errors.required">Multi Factor Code Required</div>
                               
                            </div>  


                            <div class="modal-footer justify-content-start border-top-0 nb-footer pb-4" style="margin-left: -22px;">
                                <div class="form-group">
                                    <div *ngIf="!Isvalid"   class="form_alert">
                                            <span  class="ft-alert-circle"></span>
                                            Please Enter Correct Two Factor Code
                                    </div>
                                    <div *ngIf="errors"   class="form_alert">
                                        <span  class="ft-alert-circle"></span>
                                        {{errors}}
                                    </div>
                                </div>
                                <div class="container">  
                                      <button class="btn login_btn" id ="btn_sub">Submit</button>    
                                    </div>    
                                          
                            
                            </div>

                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
            

            

          

        </div>
    </div>
   
</form>
<ngx-ui-loader  ></ngx-ui-loader>