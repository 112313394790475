<!-- <div class="modal fade" id="examplelibrary" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document"> -->

        <form [formGroup]="addLibraryForm" novalidate class="form " (ngSubmit)="onSubmit()" >
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">                   
              <button type="button" class="close" data-dismiss="modal" (click)="modalRehide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body pt-0 pb-0 pl-4 pr-4">
              <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">{{headingText}} file library</h4>
              
                <div class="form-group">
                  <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Library Name</label>
                  <input type="text" required formControlName="name" [(ngModel)]='folderModel.name' class="form-control" id="name" placeholder="Type here">
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Library Name is required</div>
                    </div>
                </div>
              
            </div>

            <div class="modal-footer justify-content-start border-top-0 nb-footer pb-4">
                <div class="form-group">
                    <div *ngIf="!Isvalid"   class="form_alert">
                            <span  class="ft-alert-circle"></span>
                            Please fill in all the required fields correctly.
                    </div>
                    <div *ngIf="errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                        {{errors}}
                    </div>
                </div>
                <button class="btn clr_button upload_button font-weight-normal text-uppercase" style="font-size: 12px;border-radius: 100px; padding: 7px 15px;">{{buttonText}}</button> &nbsp; OR &nbsp;                    
                <button type="button" class="btn pl-0 pr-0 nb_can_btn text-uppercase" (click)="modalRehide()" data-dismiss="modal">Cancel</button>
            </div>


          </div>
        <!-- </div>
      </div> -->
        </form>
      <ngx-ui-loader  ></ngx-ui-loader>