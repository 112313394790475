import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FolderResponse, FolderSearchResponse, LeaveListResponse, AllLeaveListResponse } from '../shared/models/response.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FolderEditModel } from '../shared/models/folder.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {

  baseUrl:String;
  
  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = environment.getBaseURL;
  }   

  public getLeaveList(startDate, endDate){  
    let userId = this.cookieService.get('CurrentUserId');
    const params = new HttpParams().set('userId', userId).set('startDate', startDate).set('endDate', endDate); 
    return this.http.get<LeaveListResponse>(this.baseUrl+'/Leave/GetLeaves',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public getLeaveListAll(startDate, endDate){  
    let userId = "";
    const params = new HttpParams().set('userId', userId).set('startDate', startDate).set('endDate', endDate); 
    return this.http.get<LeaveListResponse>(this.baseUrl+'/Leave/GetLeaves',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public getAllLeaveList(){  
    let userId = this.cookieService.get('CurrentUserId');
    const params = new HttpParams().set('skip', '0').set('take', '5000'); 
    return this.http.get<AllLeaveListResponse>(this.baseUrl+'/Leave/ListAllLeaves',{params}).pipe(
      catchError(this.handleError)
      );    
  }

  public handleError(error) {
    return throwError(error);
  }

  leaveAdd(leaveModel){
    leaveModel.userId = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    leaveModel.createdById = this.cookieService.get('CurrentUserId');
    leaveModel.lastUpdatedById = this.cookieService.get('CurrentUserId');
    // console.log("before submit ----");
    // console.log(leaveModel);
    return this.http.post<LeaveListResponse>(this.baseUrl +'/Leave', leaveModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public leaveEdit(leaveModel, id:string) {      
    leaveModel.lastUpdatedById = this.cookieService.get('CurrentUserId');
    //leaveModel.leaveType = parseInt(leaveModel.leaveType);
    const params = new HttpParams().set('id', id); 
    return this.http.put<LeaveListResponse>(this.baseUrl+'/Leave',leaveModel,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public deleteLeave(leaveModel){
    // leaveModel.lastUpdatedById = this.cookieService.get('CurrentUserId');
    // leaveModel.leaveStatus = 2;
    //leaveModel.leaveType = parseInt(leaveModel.leaveType); 
    const params = new HttpParams().set('id', leaveModel.id).set('leaveStatus', '2'); 
    return this.http.patch<LeaveListResponse>(this.baseUrl+'/Leave',leaveModel, {params}).pipe(
      catchError(this.handleError)
      );

    // return this.http.delete<Response>(this.baseUrl +'/Leave?id='+leaveModel.id).pipe(
    //   catchError(this.handleError)
    //   );     
  }

  public updateLeaveStatus(id:string, leaveStatus) {      
    const params = new HttpParams().set('id', id).set('leaveStatus', leaveStatus); 
    return this.http.patch<AllLeaveListResponse>(this.baseUrl+'/Leave',{},{params}).pipe(
      catchError(this.handleError)
      );
  }

}
