<app-header></app-header>
<div class="container-fluid pl-4 pr-4 mb-5">
    <div class="content-header row mt-4 mb-2">     
        <div class="content-header-left col-md-6 col-12">
            <h3 class="content-header-title mb-0 font-weight-normal">Out of Office</h3>
            <div class="breadcrumb-wrapper">
                <ol class="breadcrumb bredcrumb_new">
                    <li class="breadcrumb-item"><a routerLink="/calendar" [queryParams]="{business: business_id}">Out of Office</a>
                    </li>
                    <!-- <li class="breadcrumb-item"><a href="#">DOCUMENTS</a>
                    </li> -->
                    <li class="breadcrumb-item active">Public Calendar
                    </li>
                </ol>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-2">
            <app-sidebar-office></app-sidebar-office>
        </div>
        <div class="col-md-10 doc_sm_tb">
            <div class="row">
                <div class="col-md-12 res_side">
                    <div class="card cal_card" >
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-body">
                                    <span class="calendar_inf mb-2 d-flex pl-4 pt-4 pr-4 pb-2">Public Out of Office Calendar</span>

                                    <button *ngIf="eventLimit && showCollapseButton" (click)='expandData()' class="nexpand"><i class="fa fa-arrows-alt nexpand_ic" aria-hidden="true"></i>&nbsp;&nbsp;<span class="nexpand_text">Expand All Leaves</span></button>
                                    <button *ngIf="!eventLimit && showCollapseButton" (click)='expandData()' class="nexpand"><i class="fa fa-compress nexpand_ic" aria-hidden="true"></i>&nbsp;&nbsp;<span class="nexpand_text">Collapse All Leaves</span></button>

                                    <div *ngIf="isLoaded" >
                                            <ng-fullcalendar
                                            #fullcalendar
                                            (dateClick)="dateClick($event)"
                                            (clickButton)="clickButton($event)"
                                            [eventsModel]="eventsModel"
                                           
                                            [options]="options"
                                            
                                            

                                        ></ng-fullcalendar>

                                    </div>

                                    <div *ngIf="!isLoaded" >
                                            <ng-fullcalendar
                                            #fullcalendar                                            
                                            [eventsModel]="eventsModel"
                                            [options]="options"
                                        ></ng-fullcalendar>

                                    </div>

                                    <!-- (eventMouseOver)="MouseOver($event)"
                                            (eventMouseover)="onEventHover($event.detail)"
                                            (eventMouseout)="eventMouseOut($event.detail)" -->

                                    <!-- <ng-fullcalendar #ucCalendar [options]="calendarOptions"
(eventMouseOver)="onEventHover($event.detail)"
(eventMouseOut)="eventMouseOut($event.detail)"
></ng-fullcalendar>  -->

                                    <!-- (eventDragStop)="eventDragStop($event)" -->
                                    <!-- <button (click)='expandData()'>Expand data</button>
                                    <button (click)='previousData()'>Previous data</button>
                                    <button (click)='nextData()'>Next data</button>

                                    <full-calendar #fullcalendar
                                    [eventLimit]="eventLimit"
                                    [header]="{
                                        left: 'dayGridMonth,timeGridWeek,timeGridDay, expandButton',
                                        center: 'title', 
                                        right: 'prev,next' 
                                      }" (clickButton)="clickButton($event)" *ngIf="isLoaded"

                                    [events]="calendarList" 

                                    [eventSources] = "[
                                        {
                                        url: baseUrl+'/Leave/GetLeaves',
                                        headers: {
                                            'Authorization':'Bearer '+userToken,
                                            'Content-Type':'application/json'
                                        },
                                        extraParams: {
                                            headers: {
                                                'Authorization':'Bearer '+userToken,
                                                'Content-Type':'application/json'
                                            }
                                          }
                                        
                                        }
                                    ]"
                                    
                                    defaultView="dayGridMonth" [plugins]="calendarPlugins"></full-calendar> -->

                                    <!-- [events]= "getList(info, successCallback, failureCallback)" -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>


<app-footer></app-footer>