import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NoticeBoardService } from './notice-board.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NoticeBoardResponse, DocumentUrlResponse, PublicNoticeBoardResponse } from '../shared/models/response.model';
//import { AddDocumentComponent } from './add-document/add-document.component';
import { FileUploadService } from '../file-upload/file-upload.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NoticeBoardViewModel } from '../shared/models/notice-board.model';
import { AddNoticeComponent } from './add-notice/add-notice.component';
import { SanitizeHtmlPipe } from '../utility/sanitize-html.pipe';
import { AuthService } from '../auth/auth.service';
import { AddFavouiteComponent } from '../favourites/add-favouite/add-favouite.component';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notice-board',
  templateUrl: './notice-board.component.html',
  styleUrls: ['./notice-board.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService]
})
export class NoticeBoardComponent implements OnInit {

  baseUrl:String;
  dtOptions: DataTables.Settings = {};
  noticeList : NoticeBoardViewModel[]=[];
  publicNoticeList : NoticeBoardViewModel[]=[];
  notice:NoticeBoardViewModel;
  isLoaded :boolean;
  isLoaded2 :boolean;
  business_id : string;
  gridView :boolean = true;
  viewNotice:NoticeBoardViewModel; 
  menuName : string;
  companyCode : string;
  commonCompanyCode : string;
  menuList:any;
  selectedMenu:any ='';

  constructor(
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private noticeBoardService: NoticeBoardService,
    public modalRef: BsModalRef,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private fileUploadService: FileUploadService,
    private toastr: ToastrService,
    private authService: AuthService,
    private cookieService: CookieService,
  ) { 
    this.business_id = this.route.snapshot.queryParams['business']; 
    this.router.routeReuseStrategy.shouldReuseRoute = function() { 
      return false;
    };
    this.baseUrl = environment.getBaseURL;
    this.commonCompanyCode = environment.commonCompanyCode;
    this.gridView = this.noticeBoardService.getGridView();
    this.companyCode = this.cookieService.get('companyCode');
  }

  ngOnInit() {
    
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    this.selectedMenu = this.menuList.find(x => x.id == this.business_id);
    // console.log(this.selectedMenu, '=== this.selectedMenu==');

    if(this.selectedMenu.isPublic) {
      this.getPublicNoticeBoardList(this.business_id);
    }
    else {
      this.getNoticeBoardList(this.business_id); 
    }
    
    this.dtOptions = {
      //pagingType: 'full_numbers',
      //ajax: this.baseUrl+'/FortressAdmins',
      pageLength: 10,
      processing: true,
      order : []
    };  
    this.menuName = this.authService.getMenuName(this.business_id);   
    
  }

  public tools: object = {
    type: 'Expand',
    items: [
           'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
           'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
           'LowerCase', 'UpperCase', '|', 'Undo', 'Redo', '|',
           'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
           'Indent', 'Outdent', '|', 'CreateLink','CreateTable','|', 'SourceCode', '|', 'FullScreen']
   };

   getNoticeBoardList(business_id){
    //this.ngxService.start(); 
    this.noticeBoardService.getNoticeBoardList(business_id).subscribe((data:NoticeBoardResponse)=>{     
      this.noticeList = data.response;
      // this.agentsCount = this.agentList.length;
      // this.assignCopy();  

      // if(this.commonCompanyCode != this.companyCode){
      //   this.getPublicNoticeBoardList(business_id);
      // }

      this.isLoaded = true; 
      //this.ngxService.stop();
      // if(this.noticeList.length ==0 ){
      //   this.gridView = false;
      // }

      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err);
      });      
  }

  getPublicNoticeBoardList(business_id){
    //this.ngxService.start(); 
    this.noticeBoardService.getPublicNoticeBoardList(this.commonCompanyCode).subscribe((data:PublicNoticeBoardResponse)=>{ 
         
      this.publicNoticeList = data.data; 
      // console.log(this.publicNoticeList , '===publicNoticeList=='); 

      if(this.publicNoticeList && this.publicNoticeList.length > 0) {
        
        for(let i=0;i< this.publicNoticeList.length;i++)
        { 
          if(this.publicNoticeList[i].businessId == business_id) {
            this.noticeList.push(this.publicNoticeList[i]);
          }
        }
      }
        
      this.isLoaded = true; 
      
      //this.ngxService.stop();
      // if(this.noticeList.length ==0 ){
      //   this.gridView = false;
      // }

      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err);
      });      
  }

  openAddModal() {    
    this.modalRef = this.modalService.show(AddNoticeComponent,  {
      initialState: {
        heading_title: 'Create New Post', 
        business_id:this.business_id,
        data: {          
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-xl"
    });
  
    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }
  
  openEdit(notice:any) {  
    //console.log(newsFeed);  
    this.modalRef = this.modalService.show(AddNoticeComponent,  {
      initialState: {
        heading_title: 'Edit Post',
        business_id:this.business_id,
        data: {
          notice:notice
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-xl"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        //this.getNewsFeedList();
        this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});      
      }
    });
  }
  
  public openViewdModal(template: TemplateRef<any>,notice) {

    this.viewNotice = notice;
    //console.log(notice);
    //console.log(this.viewNotice);
    this.modalRef = this.modalService.show(template, { 
      class: 'modal-lg', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }

  changeView(view){
    this.gridView = view;
    this.noticeBoardService.setGridView(view);

    // if(this.noticeList.length > 0 ){
    //   this.gridView = view;
    //   this.noticeBoardService.setGridView(view);
    // }
    // else{
    //   this.gridView = false;
    // }

  }

  changeListOrder(value){
    if(value = 'latest'){
      this.noticeList.sort(function(a, b)
      {
        if (a.expiredDate > b.expiredDate) return 1;
        if (a.expiredDate < b.expiredDate) return -1;
      });
      // this.noticeList = this.noticeList.sort(function(a,b){
      //   // Turn your strings into dates, and then subtract them
      //   // to get a value that is either negative, positive, or zero.
      //   return b.expiredDate - a.expiredDate;
      // });;
    }
  }

  decline(): void {
    this.modalRef.hide();
  }

  modalRehide(): void {
    this.modalRef.hide();
  }

  downloadFile(viewNotice){
    this.fileUploadService.downloadFile(viewNotice.url,viewNotice.name);
  }

  openFavouriteAddModal(){
    this.modalRef = this.modalService.show(AddFavouiteComponent,  {
      initialState: {
        heading_title: 'Create new favourite link', 
        business_id:this.business_id,
        data: {          
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openDeleteDialog(template: TemplateRef<any>,notice) {
    // console.log(22222222222222);
    // console.log(notice);
    // console.log(template);
    this.notice = notice;
    this.modalRef = this.modalService.show(template, { 
      //class: 'modal-sm', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }
  
  deleteNotice(){
    this.modalRef.hide();
    this.noticeBoardService.deleteNotice(this.notice).subscribe((data:any)=>{  
      if(data.error && !data.success){
        //this.ngxService.stop();  
        this.toastr.error('Error on deleting item.');
      }
      else{
        this.toastr.success('Notice deleted successfully.');     
        this.router.navigate(['dashboard'], { queryParams: {business: this.business_id }});  
      }
               
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });       
  }

  // moveToThrash(){
  //   //console.log(this.document);
  //   this.modalRef.hide();
  //   this.noticeBoardService.moveToThrash(this.notice, this.business_id, this.folder_id).subscribe((data:any)=>{

  //     if(data.error && !data.success){
  //       this.toastr.error('Error on moving to trash.');
  //     }
  //     else{
  //       this.toastr.success('Document moved to trash successfully.');     
  //       this.router.navigate(['document'], { queryParams: {business: this.business_id, folder: this.folder_id, folder_name: this.folder_name }});   
  //     }  
               
  //     },
  //     (err: HttpErrorResponse)=>{
  //       console.log("Error"+ err.message);
  //     });   
  // }

}


