import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { DOCUMENT } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LogIn } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';
import { MultifactorAuthenticationModel } from 'src/app/shared/models/mfa.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {MultifactorModalComponent} from '../multifactor-modal/multifactor-modal.component';
import { MfaserviceService } from 'src/app/shared/common/services/mfaservice.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted: boolean = false;
  success: boolean = false;
  loading = false;
  invalid: boolean = false;
  //public utility: Utility;
  logged: boolean = false;
  errors: string;
  companyId;
  company_code: string;
  mfastatus=false;
  returnUrl: string;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private fromBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,    
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private api:MfaserviceService,
  
    ) {  
      
    this.company_code = environment.companyCode;

  }

  ngOnInit() {
    //this.ngxService.startLoader('loader-01');
    
    this.renderer.removeClass(this.document.body, 'vertical-layout');
    this.renderer.removeClass(this.document.body, 'vertical-menu');
    this.renderer.removeClass(this.document.body, '2-columns');
    this.renderer.removeClass(this.document.body, 'fixed-navbar');
    this.renderer.removeClass(this.document.body, 'menu-expanded');
    this.renderer.removeClass(this.document.body, 'pace-running');
    
    this.renderer.addClass(this.document.body, 'bg-full-screen-image');
    this.renderer.addClass(this.document.body, 'vertical-layout');
    this.renderer.addClass(this.document.body, 'vertical-menu');
    this.renderer.addClass(this.document.body, '1-column');
    this.renderer.addClass(this.document.body, 'menu-expanded');
    this.renderer.addClass(this.document.body, 'blank-page');

    this.loginForm = this.fromBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });

    //this.getMenuList();
    
  }

  // convenience getter for easy access to form fields
  get inputs() { return this.loginForm.controls; }
// onSubmit(){
//   this.openMfaModal();
// }



  onSubmit() {
    this.submitted = true;

   
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.toastr.error("Username/Password is required.");
      //this.invalid = true;
      return;
    }
    else{
      this.ngxService.startLoader('loader-01');
      //this.invalid = false;
    }
    
    //this.router.navigate(['send_code']);

    //console.log(this.inputs);
    this.loading =true;
    let operatingSystem =3;
    var login = new LogIn();
    login.userName =this.inputs.userName.value;
    login.passWord =this.inputs.password.value;
    login.companyCode =this.company_code;
    login.operatingSystem = 3;

    this.authService.Login(login)
    .subscribe((data:any)=>{
      if(data.isSuccess){
        this.mfastatus=data.data.isTowWayAuthActivated;
     
        if(this.mfastatus){
          this.ngxService.stopLoader('loader-01'); 
          localStorage.setItem("mfastatus","1")
          this.openMfaModal();
         
        }else{
          localStorage.setItem("mfastatus","0")
        }
               
      }
                
      if(!data.isSuccess){
        
        //this.invalid=true;
        this.toastr.error('These credentials do not match our records.');
        this.ngxService.stopLoader('loader-01');
               
      }
      else{

        this.companyId = data.data.companyId;
        this.mfastatus=data.data.isTowWayAuthActivated;
        this.authService.getMenuList().subscribe((data:any)=>{
          this.ngxService.stopLoader('loader-01');   
          if(!data.success){
            this.toastr.error('Error on login.');
            this.logOutUser();
              //this.ngxService.stop();
              this.ngxService.stopLoader('loader-01');
              

          }
          else{ 
            /*
            * login success redirect from here
            */


console.log(this.mfastatus);
           this.authService.getDepartmentList(this.companyId).subscribe((data:any)=>{    
          try {
          
               
          }finally{
            if(this.mfastatus){
            //  this.openMfaModal();
            }else{
              this.toastr.success('Please Set Up Multifactor Authentication.');
              localStorage.setItem('accessmfa','1')
            this.authService.redirectIfAuthWithoutMFA();
            }
       
          
          } 
           
            },err=>{   
              this.toastr.error('Error on login.');
              this.logOutUser();
              this.ngxService.stopLoader('loader-01');
              //this.ngxService.stop();
              
            });
          
          
          //this.redirectIfAuth();
            
          }      
        },err=>{   
          //this.ngxService.stop();
          this.toastr.error('Error on login.');
          this.logOutUser();
            //this.ngxService.stop();
            this.ngxService.stopLoader('loader-01');            
        });
      
      }
      
      },err=>{   
        this.toastr.error('Error on login.');
        this.logOutUser();
          //this.ngxService.stop();
          this.ngxService.stopLoader('loader-01');          
      });

    
  }

  logOutUser(){
    this.authService.logout();
  }

  getMenuList() {
    this.authService.getMenuList()
    .subscribe((data:any)=>{
      console.log(data);
      //this.ngxService.stopLoader('loader-01');          
      if(data.error || !data.isSuccess){
        this.invalid=true;
      }
      else{        
        //this.toastr.success('Login success.');
      }      
      },err=>{   
        //this.ngxService.stop();
        //this.toastr.error('Error on login.');
      });
  }

  redirectIfAuth(){    
    let menuList = JSON.parse(localStorage.getItem('menuList'));
    if(menuList[0] && menuList[0].id){
      //console.log(58963);      
      //console.log(menuList);      
      this.router.navigate(['dashboard'], { queryParams: { business: menuList[0].id }});
    }  
  }


    openMfaModal(){
    this.modalRef = this.modalService.show(MultifactorModalComponent,  {
      initialState: {
        heading_title: 'Multi Factor Authentication', 
        // business_id:this.business_id,
        data: {          
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-s"
    });
  
    this.modalRef.content.event.subscribe(result => {    
 console.log(result);
      if (result == 1) {
       // this.redirectIfAuth();
      }
    });
  }

}
