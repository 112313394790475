import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { DocumentEditModel } from '../../shared/models/document.model';
import { Router } from '@angular/router'; 
import { DocumentService } from '../document.service';
import { FileUploadService } from '../../file-upload/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { DocumentResponse, DocumentAddResponse, FileUploadResponse } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileUploadAddModel } from 'src/app/shared/models/file-upload.model';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.css']
})
export class AddDocumentComponent implements OnInit {

  documentModel : DocumentEditModel ;
  title;
  data;
  errors;
  docType: number;
  addDocumentForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  folder_id: string;
  uploadDocumentEnable:boolean = true;
  addUrlEnable:boolean = true;
  disableDocumentType:boolean = false;
  acceptDocType : string;
  documentLabel = "Choose file";
  fileUploadAddModel:FileUploadAddModel;
  attachmentAddStyle:boolean = false;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private documentService: DocumentService,
    public modalRef: BsModalRef,
    private fileUploadService: FileUploadService,
    private toastr: ToastrService
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.documentModel = new DocumentEditModel();  

    if(this.data.document !=null){
      Object.assign(this.documentModel, this.data.document);
    }

    if(this.data.business_id !=null){
      this.business_id = this.data.business_id;
    }
    if(this.data.folder_id !=null){
      this.folder_id = this.data.folder_id;
    }

    this.addDocumentForm = this.formBuilder.group({  
      fileName: ['', [Validators.required, Validators.minLength(3)]],  //[Validators.required, Validators.minLength(3)]
      document: [''],
      docType: ['', Validators.required],
      url: ['', [Validators.pattern("(?:http(s)?:\\/\\/)?[\\w\\.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)*\\+,;=.]+")]],
      //title: ['', Validators.required],

    });

    this.documentModel.docType = 0;
    this.fileUploadAddModel = new FileUploadAddModel();

  }

  get f() { return this.addDocumentForm.controls; }

onSubmit(){
 this.submitted = true;
 let title = this.documentModel.fileName;
 let url = this.documentModel.url;
//  console.log(this.documentModel.docType);

 if(this.documentModel.docType == 3){
  if(url && url.trim()==""){
    this.addDocumentForm.controls['url'].setErrors({'required': true});
  } 
 }

 if (this.addDocumentForm.invalid || this.documentModel.docType == 0 || (title && title.trim()=="") ) {

  // if(this.documentModel.docType == "3"){
  //   this.addDocumentForm.controls['url'].setErrors({'required': true});
  // }

  if(title && title.trim()==""){
    this.addDocumentForm.controls['fileName'].setErrors({'required': true});
  }

  if(this.documentModel.docType == 0){
    this.f['docType'].setErrors({'required': true});
  }
  // console.log(this.f);
  // console.log(this.addDocumentForm.invalid);
  return;
}
else{

if(this.data.document !=null){


}else{
  
  this.ngxService.start();
  this.fileUploadAddModel.folderId = this.folder_id;  
  this.fileUploadAddModel.fileName = this.documentModel.fileName;

  if(this.documentModel.docType == 1 || this.documentModel.docType == 2 || this.documentModel.docType == 4){
    this.fileUploadService.uploadFile(this.fileUploadAddModel).subscribe((data:FileUploadResponse)=>{  
      // if(data.error && !data.success){
      if(!data.success){
        this.ngxService.stop();  
        this.toastr.error('Error on saving document.');
      }
      else if(data.error && data.error.message){
        this.ngxService.stop();  
        this.toastr.error('Error on saving document.');
      }
      else{
        this.documentModel.s3Key = data.response;
        this.documentModel.folderId = this.folder_id; 
        this.docType = this.documentModel.docType;
        console.log(this.documentModel.docType,"this.documentModel.docType, e23")
        this.documentModel.docType = this.docType; 
        // console.log(this.documentModel);
        //if(this.documentModel.docType == "1" || this.documentModel.docType == "2" || this.documentModel.docType == "4"){
          this.addDocument(this.documentModel, this.business_id);
        //}
      }
      
      },err=>{
        this.ngxService.stop();   
        this.toastr.error('Error on saving document.');
      });
  }
  else{
    this.documentModel.folderId = this.folder_id;
    this.documentModel.s3Key = this.documentModel.url;
    this.documentModel.docType =  3;
    this.addDocument(this.documentModel, this.business_id);
  }
  

  }

}

}

addDocument(documentModel, business_id){
  this.documentService.documentAdd(documentModel, business_id)
  .subscribe((data:DocumentAddResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){     
      if(data.error.message){
        this.errors = data.error.message;
      }
      else{
        this.errors = Utility.GetLocalErrorString(data.error.errorCode);
      }
      this.toastr.error('Error on saving document.');      
    }
    else{
      this.toastr.success('Document added successfully.');
      this.event.emit('OK');
    // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving document.');
    });
}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addDocumentForm.reset();
}

onDocumentTypeChange(docType){
  this.uploadDocumentEnable = false;
  if(docType==1){
    this.acceptDocType = ".pdf";
    this.addUrlEnable = true;
  }else if(docType==2){
    this.acceptDocType = ".jpg,.jpeg,.png";
    this.addUrlEnable = true;
  }else if(docType==3){
    this.acceptDocType = ".url"; 
    this.uploadDocumentEnable = true;
    this.addUrlEnable = false;
  }else if(docType==4){
    this.acceptDocType = ".doc,.docx"; 
    this.addUrlEnable = true;
  }
  else{
    this.uploadDocumentEnable = true;
    this.addUrlEnable = true;
  }
  this.documentLabel = "Choose file";
}

onFileChange(event){
  if(event.target.files[0]){
    // console.log(this.fileUploadAddModel);
    this.fileUploadAddModel.file = <File>event.target.files[0];
    this.documentLabel = event.target.files[0].name;
    this.disableDocumentType = true;
    //console.log('file selected');
    this.attachmentAddStyle = true;
  } 
  else{
    this.documentLabel = "Choose file";
    this.disableDocumentType = false;
    //console.log('no file selected');
    this.attachmentAddStyle = false;
    
  }  
}

}
