<app-header></app-header>
<div class="container-fluid pl-4 pr-4 mb-5">
    
    <div class="row">
        <div class="col-md-2 resp_sidebar_icons" style="margin-top: 104px;">
            <app-sidebar></app-sidebar>
        </div>

        <div  class="col-md-10">
                <div class="content-header row mt-4 mb-2 header_namer">
                        <div class="content-header-left col-md-5 col-12">
                            <h3 class="content-header-title mb-0 font-weight-normal">{{menuName}}</h3>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb bredcrumb_new">
                                    <li class="breadcrumb-item"><a href="/dashboard?business={{business_id}}">{{menuName}}</a> </li>
                                    <li class="breadcrumb-item active">Notice Board </li>
                                </ol>
                            </div>
                        </div>
                        <div class="content-header-right text-md-right col-md-7 col-12 mt-2 headerbn">
                            <div class="input-group d-block">
                                <!-- <select class="form-control form_s2 mr-2" name="category" id="validationCustom03" (change)="changeListOrder($event.target.value)" >
                                    <option value="">Select </option>
                                    <option value="latest">Latest First </option>                    
                                </select> -->
                                <!-- <a href="/list-view">
                                </a> -->
                
                                <button type="button" [ngClass]="{ 'disab': isLoaded && noticeList.length==0 }" *ngIf="gridView" (click)="changeView(false)" class="btn btn-round font-weight-normal clr_button upload_button mr-2 nbplus_btn"> <img src="../../assets/img/list.png" alt="" class="list_iconnb" >&nbsp; List View</button>
                                <button type="button" [ngClass]="{ 'disab': isLoaded && noticeList.length==0 }" *ngIf="!gridView" (click)="changeView(true)" class="btn btn-round font-weight-normal clr_button upload_button mr-2 nbplus_btn"> <img src="../../assets/img/menu.png" alt="" class="grid_iconnb" >&nbsp; Grid View</button>
                                <ng-container *ngIf="selectedMenu && !selectedMenu.isNoticesReadOnly">   
                                    <button type="button" (click)="openAddModal()" class="btn btn-round font-weight-normal clr_button upload_button mr-2 nbplus_btn text-uppercase"> <i class="fa fa-plus fa-1x" aria-hidden="true"></i> &nbsp; Create Post</button>
                                    <button type="button" (click)="openFavouriteAddModal()" class="btn btn-round font-weight-normal clr_button upload_button nbplus_btn text-uppercase" > <i class="fa fa-plus fa-1x" aria-hidden="true"></i> &nbsp; New Favourite</button>
                                </ng-container>
                                
                                <div class="modal fade" id="fav1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header border-bottom-0 pb-0">
                                                    <button type="button" class="close newclose"  (click)="modalRehide()" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                                                </div>
                                                <div class="modal-body pt-0 pb-0 pl-4 pr-4">
                                                    <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">Add New Favourite</h4>
                                                        <div class="form-group">
                                                            <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Favourite Name</label>
                                                            <input type="text" class="form-control" placeholder="Type here">
                                                        </div> 
                                    
                                                        <div class="form-group">
                                                            <label for="message-text" class="col-form-label d-flex font-weight-normal">Please Select Favourite Type</label>
                                                            <select  class="form-control" >
                                                                <option value="document">Document</option>
                                                                <option value="link">Link</option>
                                                            </select>
                                                        </div>
                                                        
                                                        <div class="form-group">
                                                            <label for="message-text" class="col-form-label d-flex font-weight-normal">Upload Your File</label>
                                                            <div class="custom-file">
                                                                <input name="document"  type="file"  class="custom-file-input">
                                                                <label class="custom-file-label d-flex text-truncate" for="validatedCustomFile">No file attached</label>
                                                            </div>                                
                                                        </div>
                                    
                                                    
                                                    <div class="modal-footer justify-content-start border-top-0 nb-footer pl-0 pb-4">                                                    
                                                        <button class="btn clr_button upload_button font-weight-normal " style="font-size: 12px; border-radius: 100px;  padding: 7px 15px;">Create Now</button> &nbsp; Or &nbsp;
                                                        <button type="button" class="btn pl-0 pr-0 nb_can_btn " (click)="modalRehide()" data-dismiss="modal">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                
                        </div>
                    </div>
            <div class="row">
                <div class="col-md-9">
                        <div *ngIf="!gridView" class="table-responsive mb-5 mr-3 table_Sh sm_bt">
                                
                            <ng-container *ngIf="isLoaded">        
                                <span  class="text-left table_text">{{menuName}} Notices</span>   
                            <table datatable [dtOptions]="dtOptions" class="table " style="width: 100%;" id="mydatatable">
                                <thead>
                                    <tr>
                                        <th scope="col">Post Title</th>
                                        <th scope="col" style=" white-space: nowrap; overflow: hidden;  text-overflow: ellipsis;">Created Date</th>
                                        <th scope="col" style=" white-space: nowrap; overflow: hidden;  text-overflow: ellipsis;">Created By</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
            
                                <ng-container *ngFor="let notice of noticeList; let i = index">
                                <tr style="cursor: pointer;">
                                    <td scope="row" (click)="openViewdModal(template, notice)" class="table_datan" title="{{ notice.title }}">
                                        <a href="javascript:void(0);" class="link_row_table">{{notice.title | limitTo : '60' }} </a>
                                    </td>
                                    <td class="table_datan" (click)="openViewdModal(template, notice)">{{ notice.createdDate+'Z' | date:"dd/MM/yyy" }}</td> <!-- date:"mediumDate" -->
                                    <td class="table_datan" (click)="openViewdModal(template, notice)">
                                        <ng-container *ngIf="notice.createdBy && notice.createdBy.firstName">
                                            {{notice.createdBy.firstName}}
                                        </ng-container>    
                                        <ng-container *ngIf="notice.createdBy && notice.createdBy.lastName">
                                            {{notice.createdBy.lastName}}
                                        </ng-container>
                                    </td>
                                    <td class="btn_table table_datan">
                                        <ng-container *ngIf="selectedMenu && !selectedMenu.isNoticesReadOnly">
                                            <button type="button" (click)="openEdit(notice)" class="btn secondbtn ml-2 btntabel_icon" style="padding: 3px 8px !important;"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                            <button type="button" (click)="openDeleteDialog(template1,notice)"  class="btn secondbtn ml-2 btntabel_icon" ><i class="fa fa-trash" aria-hidden="true"></i></button>
                                        </ng-container>
                                    </td>
                                </tr>
                                </ng-container>   
            
                               
            
                                </tbody>
                            </table>

                            
                            </ng-container>
            
                            <ng-container *ngIf="!isLoaded"> 
                            <div>
                                <table datatable class="table " style="width: 100%;" id="mydatatable">
                                    <thead>
                                        <tr>
                                            <th scope="col">Post Title</th>
                                            <th scope="col">Created Date</th>
                                            <th scope="col">Created By</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr class="odd"><td valign="top" colspan="4" class="dataTables_empty">No data available in table</td></tr>   -->
                                    </tbody>
                                </table>
                                <ngx-ui-loader  ></ngx-ui-loader>
                            </div>     
                            </ng-container>
                                   
                            
                            
                         </div>

                        <div class="card-deck sm_bt">
                         <ng-container *ngIf="isLoaded && gridView && noticeList.length==0"> 
                            <div class="col-md-4 mb-5 pl-0 pr-0">
                                <div class="card">
                                    <a class="card_NB  h-100"> 
                                        <img class="card-img-top grid_img" [src]="'../../../assets/img/no_data_cover.jpg'" alt="Card image cap">
                                        <div class="card-body text-center">
                                        <p  class="card-text mt-2 mb-2 card_new_t text-truncate pt-2 pb-2">
                                            <span class="newlink_grid">Notice List is Empty</span>
                                            <br><br>
                                            <ng-container *ngIf="selectedMenu && !selectedMenu.isNoticesReadOnly">
                                                <!-- <a class="emptynoticelist" (click)="openAddModal()"><img src="../../assets/img/add.png" style="padding: 5px; border: 1px solid #0DB2F0;border-radius: 100px;height: 40px; margin-top: .5rem;"></a> -->
                                                <a class="emptynoticelist" (click)="openAddModal()">
                                                    <!-- <img src="../../assets/img/add.png" style="padding: 5px; border: 1px solid #0DB2F0;border-radius: 100px;height: 40px; margin-top: .5rem;"> -->
                                                    <span class="addnew_post" style="font-size: 27px; color: #10bdcd; border: 1px solid #10bdcd; padding: 5px 14px; border-radius: 100px;">+</span>
                                                </a>
                                            </ng-container>
                                        </p>
                                        </div>
                                    </a>
                                    
                                </div>
                            </div>
                            
                         </ng-container>
                        </div>




                        <!-- <div *ngIf="isLoaded && gridView && noticeList.length==0" class="table-responsive mb-5 mr-3 table_Sh sm_bt">
                                <span  class="text-left table_text">{{menuName}} Notices</span>
                                <table datatable class="table " style="width: 100%;" id="mydatatable">
                                        <thead>
                                            <tr>
                                                <th scope="col">Post Title</th>
                                                <th scope="col">Created Date</th>
                                                <th scope="col">Created By</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>                        
                         </div> -->




                         <ng-container *ngIf="isLoaded && gridView">
                                <!-- <div class="row"> -->
                                       
                        <div class="card-deck sm_bt">
            
                            <ng-container *ngFor="let notice of noticeList; let i = index">
                                <div class="col-md-4 mb-5 pl-0 pr-0">
                                <div class="card">
                                    <a href="javascript:void(0);" (click)="openViewdModal(template, notice)" class="card_NB  h-100"> 
                                        <img class="card-img-top grid_img" [src]="notice.urlKey" (error)="notice.urlKey = '../../../assets/img/default_cover_image.jpg'" alt="Card image cap">
                                        <div class="card-body"> 
                                        <span class="name_nb">
                                            By <ng-container *ngIf="notice.createdBy && notice.createdBy.firstName">
                                                    {{notice.createdBy.firstName}}
                                                </ng-container>    
                                                <ng-container *ngIf="notice.createdBy && notice.createdBy.lastName">
                                                    {{notice.createdBy.lastName}}
                                                </ng-container>
                                        </span> 
                                        <span class="date_nb">{{ notice.createdDate+'Z' | date:"dd/MM/yyy" }}</span> <!-- date:"mediumDate" -->
                                            <p  class="card-text mt-3 mb-3 card_new_t text-truncate" title="{{ notice.title }}" style="max-width: 290px;">
                                                <!-- {{notice.title}} -->
                                                <a href="javascript:void(0);" class="newlink_grid">{{notice.title | limitTo : '60' }}</a>
                                            </p>
                                        </div>
                                    </a>
                                    <div *ngIf="selectedMenu && !selectedMenu.isNoticesReadOnly" class="card-footer pl-0 pr-0 tags_border" style="    margin-left: 20px; margin-right: 20px; background: none; ">
                                        
                                        <!-- <button type="button" (click)="openDeleteDialog(template1,notice)"  class="btn secondbtn ml-2 btntabel_icon" ><i class="fa fa-trash" aria-hidden="true"></i></button> -->
                                        <a href="javascript:void(0);" (click)="openDeleteDialog(template1,notice)" class="card_NB "> <i class="fa fa-trash float-left"></i> </a>
                                        <a href="javascript:void(0);" (click)="openEdit(notice)" class="card_NB "> <i class="fa fa-pencil float-right"></i> </a>
                                        
                                    </div>
                                </div>
                                </div>
                            </ng-container> 
            
                            
                        </div>
                        
                    
                <!-- </div> -->
                        </ng-container>





                         
            
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <div class="cf_header">
                            <span class="cf_title text-left">Favourites</span>
                        </div>
                        <div class="table-responsive shadow-none  border-0 cf_table">
                            <app-favourites></app-favourites>
                        </div>
                    </div>
                </div>
      
      
                
    











            </div>
        </div>

    </div>
</div>
<ng-template #template>
        
        <div class="modal-header border-bottom-0 pb-0">
                <button type="button" class="close close_edit" (click)="decline()" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
            </div>
            <div class="modal-body p-0">
                    <!-- <h4 class="modal-title d-flex font-weight-normal card_b tcolor">{{menuName}} Notice</h4>  -->
                    <!-- <div class="card_b"> 
                        <img style="height: 206px !important;width:343px !important; object-fit: cover;" [src]="viewNotice.urlKey" (error)="viewNotice.urlKey = '../../../assets/img/default_cover_image.jpg'" class="d-flex img-fluid" height="30%" width="40%" alt=""> 
                        <span class="d-flex mt-1 font-weight-normal fonts">CREATED BY 
                            <ng-container *ngIf="viewNotice.createdBy && viewNotice.createdBy.firstName">
                                {{viewNotice.createdBy.firstName}}
                            </ng-container>    
                            <ng-container *ngIf="viewNotice.createdBy && viewNotice.createdBy.lastName">
                                {{viewNotice.createdBy.lastName}}
                            </ng-container>&nbsp;|&nbsp;   
                        <span class="fonts_date">{{ viewNotice.createdDate | date:"mediumDate" }}</span></span>
                        
                    </div> -->
                    <!-- <hr class="mb-2 mt-1"> -->

                    <div class="card_b pb-3" style="word-wrap: break-word;">
                        <h5 class="font-weight-normal mb-0 ">{{viewNotice.title}}</h5>  
                        <span class="d-flex mt-1 font-weight-normal fonts ">CREATED BY 
                                <ng-container *ngIf="viewNotice.createdBy && viewNotice.createdBy.firstName">
                                    {{viewNotice.createdBy.firstName}}
                                </ng-container>    
                                <ng-container *ngIf="viewNotice.createdBy && viewNotice.createdBy.lastName">
                                    {{viewNotice.createdBy.lastName}}
                                </ng-container>&nbsp;|&nbsp;   
                            <span class="fonts_date">{{ viewNotice.createdDate+'Z' | date:"dd/MM/yyy" }}</span> <!-- date:"mediumDate" -->
                            <!-- <span class="borders "></span> -->
                        </span>   
                        <span class="borders "></span>
                        <!-- <a href="javascript:void(0);" download (click)="downloadFile(viewNotice)" >Download document</a> -->
                        <!-- <button (click)="downloadFile(viewNotice)" >Download document</button> -->
                          
                        <div [innerHTML]="viewNotice.description | sanitizeHtml" class="pt-4 clearfix"></div>   
                        
                        <ng-container *ngIf="viewNotice && viewNotice.attachments && viewNotice.attachments.length >0">
                            <hr *ngIf="viewNotice.attchementURL && viewNotice.attchementURL != null ">
                            <ng-container *ngFor="let attachment of viewNotice.attachments; let j = index">
                                <a href="javascript:void(0);" title="{{ attachment.name }}" *ngIf="attachment.url && attachment.url != null " download (click)="downloadFile(attachment)" class="down_attachment"><i class="fa fa-download" aria-hidden="true"></i>
                                    &nbsp; ({{attachment.name | limitTo : '150'}})</a> <br/> <!--Download Attachment-->
                            </ng-container>
                        </ng-container>

                        
                        
                    </div>
                    

                </div>        
                <!-- July 06, 2019 -->
    </ng-template>
    <!-- <ngx-ui-loader  ></ngx-ui-loader>-->

    <ng-template #template1>
            <div class="modal-body text-center">
                    <h4 class="del_text font-weight-normal">Do you want to delete this item?</h4>
                </div>
                <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-5" >
                    <button (click)="deleteNotice()" type="button" class="clr_button_delete font-weight-normal text-uppercase" style="font-size: 12px;">Delete</button> &nbsp; OR &nbsp; 
                    <!-- <button (click)="moveToThrash()" type="button" class="clr_button trash_bn font-weight-normal text-uppercase" style="font-size: 12px;">Move To Trash</button> &nbsp; OR &nbsp; -->
                    <button (click)="decline()" type="button" class="pl-0 pr-0 nb_can_btn text-uppercase"  data-dismiss="modal">Cancel</button>

                </div>
        </ng-template> 

<app-footer></app-footer>