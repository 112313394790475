import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-sidebar-office',
  templateUrl: './sidebar-office.component.html',
  styleUrls: ['./sidebar-office.component.css']
})
export class SidebarOfficeComponent implements OnInit {

  business_id : string;
  path: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.business_id = this.route.snapshot.queryParams['business'];
    this.path = this.route.routeConfig.path;
  }

  viewPortalPermission(permission){
    // console.log("---permission--");
    // console.log(permission);
    return this.authService.viewPortalPermission(permission,this.business_id);
  }

}
