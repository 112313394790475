<div class="app-content content0">
  <div class="content-wrapper">
      <div class="content-header row"> </div>
      <div class="content-body">
          <section class="flexbox-container">
              <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-md-4 col-10 box-shadow-2 p-0 login_width">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                          <div class="card-header border-0" style="padding-bottom: 5px;">
                              <div class="card-title text-center"> <img src="assets/img/logo_login.png" alt="branding logo" style="width: 75%;"> </div>
                              <div class="card-title text-center icon_pass"> <img src="assets/img/shield.png" alt="shield" style="width: 21%;"> </div>
                              <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2 passh" >
                                 <span>Login to your account securely</span>
                              </h6> 
                          </div>
                          <div class="card-content">
                              <div class="card-body">
                                  <!-- <form [formGroup]= "verifyAccessCodeForm" (ngSubmit)="onSubmit()" class="login-form"> -->
                                        
                                <div *ngIf="invalid" class="alert alert-danger">
                                    Code mismatch.
                                </div>
                                  <form [formGroup]="sendCodeForm" (ngSubmit)="onSubmit()" class="form-horizontal">
                                      <!-- <fieldset class="form-group position-relative has-icon-right mar_field">
                                          <input type="text" class="form-control" formControlName="accessCode" id="user-passcode" placeholder="Passcode" required >
                                          <div class="form-control-position"> <i class="icon-screen-smartphone"></i> </div>

                                        <div class="form-group">
                                        <div *ngIf="submitted && inputs.accessCode.errors" class="alert alert-danger">
                                            <div *ngIf="inputs.accessCode.errors.required">Passcode is required</div>                                                
                                        </div>
                                        </div>


                                      </fieldset> -->

                                      <fieldset class="form-group position-relative has-icon-right mar_field">
                                            <input type="text" class="form-control" formControlName="accessCode" id="user-passcode" [ngClass]="{ 'is-invalid': submitted && inputs.accessCode.errors }" placeholder="Passcode" required >
                                            <div class="form-control-position"> <i class="icon-screen-smartphone"></i> </div>
                                            <div *ngIf="inputs.accessCode.errors" class="invalid-feedback"></div>                                               
                                        </fieldset>

                                      <button type="submit" class="btn btn-block signbtn">SUBMIT</button>
                                  </form>
                              </div>
                              <div class="for_p">
                                  <p class="text-center m-0"><a href="/login" class="card-link">Back to Login</a></p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
  </div>
</div>

<ngx-ui-loader ></ngx-ui-loader>