import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { BsModalService } from 'ngx-bootstrap/modal/public_api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LibraryService } from './library.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FolderResponse, FolderPublicResponse } from '../shared/models/response.model';
import { FolderViewModel } from '../shared/models/folder.model';
import { AddLibraryComponent } from './add-library/add-library.component';
import { EditLibraryComponent } from './edit-library/edit-library.component';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.css']
})
export class LibraryComponent implements OnInit {

  baseUrl:String;
  dtOptions: DataTables.Settings = {};
  folderList:FolderViewModel[]=[];
  folderPublicList:FolderViewModel[]=[];
  folder:FolderViewModel;
  isLoaded :boolean; 
  business_id : string;
  menuName : string;
  menuList : any;
  selectedMenu:any;
  commonCompanyCode : string;

  constructor(
    private router: Router,
    private libraryService: LibraryService,
    public modalRef: BsModalRef,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private authService: AuthService,
    ) {
    this.business_id = this.route.snapshot.queryParams['business']; 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.baseUrl = environment.getBaseURL;
    this.commonCompanyCode = environment.commonCompanyCode;
  }

  ngOnInit() {
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    this.selectedMenu = this.menuList.find(x => x.id == this.business_id);

    if(this.selectedMenu.isPublic) {
      this.getPublicFolderList(this.business_id);
    }
    else {
      this.getFolderList(this.business_id); 
    } 

    this.dtOptions = {
      //pagingType: 'full_numbers',
      //ajax: this.baseUrl+'/FortressAdmins',
      pageLength: 10,
      processing: true,
      order : []
    };   
    this.menuName = this.authService.getMenuName(this.business_id);
  }

  getFolderList(business_id){
    this.libraryService.getFolderList(business_id).subscribe((data:FolderResponse)=>{     
      this.folderList = data.response;
      // this.agentsCount = this.agentList.length;
      // this.assignCopy();      
      // console.log(this.folderList);
      this.isLoaded = true;      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }

  getPublicFolderList(business_id){
    this.libraryService.getPublicFolderList(this.commonCompanyCode).subscribe((data:FolderPublicResponse)=>{     
      this.folderPublicList = data.data;
      // this.agentsCount = this.agentList.length;
      // this.assignCopy();      
      // console.log(this.folderPublicList);

      if(this.folderPublicList && this.folderPublicList.length > 0) {
        
        for(let i=0;i< this.folderPublicList.length;i++)
        { 
          if(this.folderPublicList[i].businessId == business_id) {
            this.folderList.push(this.folderPublicList[i]);
          }
        }
      }

      this.isLoaded = true;      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }

  deleteFolder(){
    // console.log(this.folder);
    this.modalRef.hide();
    this.libraryService.deleteFolder(this.folder).subscribe((data:any)=>{   
       
      if(data.error && !data.success){
        this.toastr.error('Error on deleting library.');
      }
      else{
        this.toastr.success('Library moved to trash successfully.');     
      this.router.navigate(['library'], { queryParams: {business: this.business_id }});   
      }

      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });         
  }

  viewFolder(folder:FolderViewModel){
    this.router.navigate(['document'], { queryParams: {business: this.business_id, folder: folder.id, folder_name: folder.name }});
  }

  openEditModal(library:any) {  
    //console.log(agent);  
    this.modalRef = this.modalService.show(EditLibraryComponent,  {
      initialState: {
        title: 'Edit Agent',
        data: {
          library:library,
          business_id:this.business_id
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.router.navigate(['agents']);
        //  this.getAgentList();
      
      }
    });
  }

  openAddModal() {
    
    this.modalRef = this.modalService.show(AddLibraryComponent,  {
      initialState: {
        title: 'Create new file library',
        business_id:this.business_id,
        data: {  
          folder_id:null,        
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {    
      // console.log("result");
      // console.log(result);
  
      if (result == 'OK') {
        this.router.navigate(['library'], { queryParams: {business: this.business_id }});      
      }
    });
  }

  public openDeleteDialog(template: TemplateRef<any>,folder) {

    this.folder = folder;
    this.modalRef = this.modalService.show(template, { 
      //class: 'modal-sm', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }

  decline(): void {
    this.modalRef.hide();
  }

  viewPortalPermission(permission){
    return this.authService.viewPortalPermission(permission,this.business_id);
  }

  openEdit(folder){
    this.modalRef = this.modalService.show(AddLibraryComponent,  {
      initialState: {
        heading_title: 'Edit file library',
        business_id:this.business_id,
        data: {
          library:folder
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        this.router.navigate(['library'], { queryParams: {business: this.business_id }});      
      }
    });
  }

}
