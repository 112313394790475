import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { User } from '../shared/models/user.model';
import { AuthService } from '../auth/auth.service';
import { Routes, Router, ActivatedRoute } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  portalUrl:String;
  currentUser: User;
  business_id:any;
  permission:any;
  path :string;
  menuList:any;
  selectedMenuItem:any;
  businessName:string='';
  selectedMenu:any;

  constructor(
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService, 
    private router: Router,    
  ) {
    this.portalUrl = environment.getPortalURL;
    this.business_id = this.route.snapshot.queryParams['business'];
    this.currentUser = this.authService.getCurrentUserValue;
    this.path = this.route.routeConfig.path;
    this.businessName = environment.getBusinessName;
    //console.log(this.route.routeConfig.path);
  }

  ngOnInit() {
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    this.selectedMenu = this.menuList.find(x => x.id == this.business_id);

    //console.log(window.location.hostname);
    this.permission = this.getPermission();
    //console.log(this.permission);
    
    this.menuList = JSON.parse(localStorage.getItem('menuList'));
    this.selectedMenuItem = this.menuList.find(x => x.id == this.business_id);
    //console.log(this.selectedMenuItem);
    
  }

  public openRedirectDialog(template: TemplateRef<any>) {
    if( typeof this.permission == 'string') return null;
    if(!this.permission.find(x => x == "ViewMyFortressAdmin")) return null;
    this.modalRef = this.modalService.show(template, { 
      //class: 'modal-sm', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }

  // viewPortalPermission(permission){
  //   if( typeof this.permission == 'string') return null;
  //   return this.permission.find(x => x == permission)
  // }

  viewPortalPermission(permission){
    return this.authService.viewPortalPermission(permission,this.business_id);
  }

  getPermission() {
    let token = this.authService.userToken;
    const decoded = jwt_decode(token);
    //console.log(decoded);
    if (decoded.scope === undefined || decoded.scope=="") return null;
    return decoded.scope;
  }

  decline(): void {
    this.modalRef.hide();
  }

//   routeIsActive(routePath: string) {
//     let currentRoute = this.router.urlTree.firstChild(this.router.urlTree.root);
//     // e.g. 'Login' or null if route is '/'
//     let segment = currentRoute == null ? '/' : currentRoute.segment;
//     return  segment == routePath;
//  }


}
