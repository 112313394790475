<app-header></app-header>
<div class="container-fluid pl-4 pr-4 mb-5">
  <div class="content-header row mt-4 mb-2">
    <div class="content-header-left col-md-6 col-12">
      <h3 class="content-header-title mb-0 font-weight-normal">Out of Office</h3>
      <div class="breadcrumb-wrapper">
        <ol class="breadcrumb bredcrumb_new">
          <li class="breadcrumb-item"><a routerLink="/calendar" [queryParams]="{business: business_id}">Out of Office</a>
          </li>
          <!-- <li class="breadcrumb-item"><a href="#">DOCUMENTS</a>
                    </li> -->
          <li class="breadcrumb-item active">My Leave
          </li>
        </ol>
      </div>
    </div>
    <div class="content-header-right text-md-right col-md-6 col-12 mt-2 headerbn">
      <div class="btn-group">
        <button type="button" (click)="openAddModal()" class="btn btn-round font-weight-normal clr_button upload_button text-uppercase"> <i class="fa fa-plus fa-1x" aria-hidden="true"></i>
          &nbsp; NEW LEAVE</button>
      </div>


    </div>
  </div>

  <div class="row">
    <div class="col-md-2">
      <app-sidebar-office></app-sidebar-office>
    </div>
    <div class="col-md-10 leaved">
      <div class="row">
        <div class="table-responsive tbl_res pt-3 doc_sm_tb"> <!-- <div class="table-responsive tbl_res pt-3 doc_sm_tb leavetable"> -->
          
          <div class="row">
            <div class="col-md-4">
              <span class="text-left table_text">My Leave Days</span>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                  <div class="input-group">
                      <label for="message-text" class="col-form-label d-flex font-weight-normal" style="padding-right: 10px;">From</label>
                          <!-- readonly="falls"
                            [minDate]="minDate" 
                            value="{{ startDate1 | date:'dd/MM/yyyy'}}"
                            [bsValue]="startDate1"
                          -->
                      <input type="text"  
                      (ngModelChange)="onChange($event, 'startDate1')"
                      placement="bottom right"
                      [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}" 
                      bsDatepicker 
                      id="startDate1"
                      name="startDate1"
                      [(ngModel)]="startDate1"
                       class="form-control border-right-0 bg-white" placeholder="dd/mm/yyyy">
                      <div class="input-group-prepend"> 
                        <span class="input-group-text" style="background-color: #ced4da !important;">
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                      
                  </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" >
                  <div class="input-group" style="padding-right: 25px;">
                      <label for="message-text" class="col-form-label d-flex font-weight-normal" style="padding-right: 10px;">To</label>
                          <!-- [minDate]="endDate" -->
                      <input type="text" 
                      (ngModelChange)="onChange($event, 'endDate1')"
                      placement="bottom right"
                      [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}" 
                      bsDatepicker 
                      id="endDate1"
                      name="endDate1"
                      [(ngModel)]="endDate1"
                      class="form-control border-right-0 bg-white" placeholder="dd/mm/yyyy">
                      <div class="input-group-prepend"> <span class="input-group-text" style="background-color: #ced4da !important;"><i class="fa fa-calendar"></i></span> </div>
                  </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="isLoaded"> 
          <table class="table " datatable [dtOptions]="dtOptions" style="width: 100%;" id="mydatatable">
            <thead>
              <tr>
                <th scope="col" class="text-truncate">Type</th>
                <th scope="col" class="text-truncate">From</th>
                <th scope="col" class="text-truncate">To</th>
                <th scope="col" class="text-truncate">Days Out Of Office</th>
                <th scope="col" class="text-truncate">Status</th>
                <th scope="col" class="text-truncate"></th>
              </tr>
            </thead>
            <tbody  *ngIf="leaveList.length > 0">


                <ng-container *ngFor="let leave of leaveList; let i = index">
                <tr style="cursor: pointer;">
                    <td class="table_datan text-truncate" (click)="openViewDialog(template1, leave)" >
                        
                        <!-- <ng-container *ngIf="leave.leaveType && leave.leaveType=='1'" >
                            Sick Leave
                        </ng-container>
                        <ng-container *ngIf="leave.leaveType && leave.leaveType=='2'" >
                            Annual Leave
                        </ng-container>
                        <ng-container *ngIf="leave.leaveType && leave.leaveType=='3'" >
                            Parental Leave
                        </ng-container>
                        <ng-container *ngIf="leave.leaveType && leave.leaveType=='4'" >
                            Personal Leave
                        </ng-container>
                        <ng-container *ngIf="leave.leaveType && leave.leaveType=='5'" >
                            Long Service Leave
                        </ng-container>
                        <ng-container *ngIf="leave.leaveType && leave.leaveType=='6'" >
                            Community Leave
                        </ng-container>   -->

                        <ng-container *ngIf="leave.leaveType && leave.leaveType=='1'" >
                          Sick Leave
                      </ng-container>
                      <ng-container *ngIf="leave.leaveType && leave.leaveType=='2'" >
                          Annual Leave
                      </ng-container>
                      <ng-container *ngIf="leave.leaveType && leave.leaveType=='3'" >
                          Parental Leave
                      </ng-container>
                      <ng-container *ngIf="leave.leaveType && leave.leaveType=='4'" >
                        Long Service Leave
                      </ng-container>
                      <ng-container *ngIf="leave.leaveType && leave.leaveType=='5'" >
                        Time in Lieu
                      </ng-container>
                      <ng-container *ngIf="leave.leaveType && leave.leaveType=='6'" >
                        Unpaid Leave
                      </ng-container>
                      <ng-container *ngIf="leave.leaveType && leave.leaveType=='7'" >
                        Study Leave
                      </ng-container>
                      <ng-container *ngIf="leave.leaveType && leave.leaveType==8" >
                        Bereavement Leave
                      </ng-container>
                      <!-- <ng-container *ngIf="leave.leaveType && leave.leaveType==6" >
                          Community Leave
                      </ng-container>  -->
                       
                    </td>
                    <td class="table_datan text-truncate" (click)="openViewDialog(template1, leave)" >{{ leave.startDate+'Z' | date:"dd/MM/yyy" }}</td> <!-- mediumDate -->
                    <td class="table_datan text-truncate" (click)="openViewDialog(template1, leave)" >{{ leave.endDate+'Z' | date:"dd/MM/yyy" }}</td> <!-- mediumDate -->
                    <td class="table_datan text-truncate" (click)="openViewDialog(template1, leave)" >
                        {{ (leave.endDate | amDifference : leave.startDate : 'days')+1 }}
                    </td>
                    <td class="table_datan status_pen text-truncate" (click)="openViewDialog(template1, leave)" >
                        <!-- <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus=='1'" >
                            <label class="status_app"> Confirm </label>
                        </ng-container> -->
                        <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus=='2'" >
                            <label class="status_can"> Cancelled </label>
                        </ng-container>
                        <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus=='3'" >
                            <label class="status_pen"> Pending </label>
                        </ng-container>
                        <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus=='4'" >
                            <label class="status_app"> Approved </label>
                        </ng-container>
                        <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus=='5'" >
                            <label class="status_dec"> Declined </label>
                        </ng-container>
                      
                    </td>
                    <td class="btn_table table_datan text-truncate">
                      <ng-container *ngIf="leave.leaveStatus && ( leave.leaveStatus!='2' && leave.leaveStatus!='5' && leave.leaveStatus!='4' )" >
                          <button type="button" class="btn secondbtn btntabel_icon" (click)="openEditModal(leave)"><i class="fa fa-pencil"
                            aria-hidden="true"></i></button>
                          <button type="button" class="btn btntabel_icon ml-2" (click)="openDeleteDialog(template,leave)"><i class="fa fa-times"
                            aria-hidden="true"></i></button>
                      </ng-container>
                      <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus=='4' " >                          
                          <button type="button" class="btn btntabel_icon ml-2" (click)="openDeleteDialog(template,leave)"><i class="fa fa-times"
                            aria-hidden="true"></i></button>
                      </ng-container>                          
                    </td>
                    
                </tr>
                </ng-container>

         
            </tbody>
            <tbody  *ngIf="leaveList.length == 0">
              <tr>
                  <td colspan="6" style="text-align: center !important;">No data available in table.</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

          <ng-container *ngIf="!isLoaded"> 
              <table datatable [dtOptions]="dtOptions" class="table " style="width: 100%;" id="mydatatable">
                  <thead>
                      <tr>
                          <th scope="col" class="text-truncate">Type</th>
                          <th scope="col" class="text-truncate">From</th>
                          <th scope="col" class="text-truncate">To</th>
                          <th scope="col" class="text-truncate">Days Out Of Office</th>
                          <th scope="col" class="text-truncate">Status</th>
                          <th scope="col" class="text-truncate"></th>  
                      </tr>
                  </thead>
                  <tbody>
                  </tbody>
              </table>
              </ng-container>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #template>
    <!-- <div class="modal-body text-center">
        <h4 class="del_text font-weight-normal">Are you sure you want to <span
            style="color: #ed1919;">cancel</span> this record?</h4>
      </div>
      <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-5">
        <button (click)="deleteLeave()" type="button"
          class="clr_button_delete font-weight-bold text-uppercase" style="font-size: 12px;">Yes</button> &nbsp; OR &nbsp;
        <button (click)="decline()" type="button" class="pl-0 pr-0 nb_can_btn text-uppercase"
          data-dismiss="modal">Cancel</button>
      </div> -->

      
      <div class="modal-body text-center">
          <h4 class="del_text font-weight-normal">Are you sure you want to <span
              style="color: #ed1919;">cancel</span> this record?</h4>
        </div>
        <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-5">
          <button (click)="deleteLeave()" type="button"
            class="clr_button_delete font-weight-bold text-uppercase" style="font-size: 12px;">Yes, Cancel</button> &nbsp; OR &nbsp;
          <button (click)="decline()" type="button" class="pl-0 pr-0 nb_can_btn text-uppercase"
            data-dismiss="modal">No</button>
        </div>
</ng-template>

<ng-template #template1>
    <div class="modal-header border-bottom-0 pb-0">
        <button type="button" (click)="decline()" class="close" aria-label="Close"> <span aria-hidden="true">&times;</span>
        </button>
      </div>
<div class="modal-body  pt-0 pb-3 pl-4 pr-4">
    <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">Leave Details</h4>

    <table
      class="table table-borderless table-sm pro_tab table-responsive w-100 d-block d-md-table leaveview">
      <tbody>
        <tr>
          <td class="pl-0 leavel_fs">Leave Status</td>
          <td class="profile_td text-left text-truncate status_app leavel_fs">: 
              <!-- <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==1" >
                  <label class="status_app mb-0"> Confirm </label>
              </ng-container> -->
              <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==2" >
                  <label class="status_can mb-0"> Cancelled </label>
              </ng-container>
              <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==3" >
                  <label class="status_pen mb-0"> Pending </label>
              </ng-container>
              <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==4" >
                  <label class="status_app mb-0"> Approved </label>
                  <span style="color: #424242;">
                      (Approved by 
                      <ng-container *ngIf="leave.lastUpdatedBy && leave.lastUpdatedBy.firstName">
                          {{leave.lastUpdatedBy.firstName}}
                      </ng-container>    
                      <ng-container *ngIf="leave.lastUpdatedBy && leave.lastUpdatedBy.lastName">
                          {{leave.lastUpdatedBy.lastName}}
                      </ng-container>
                      )</span>
              </ng-container>
              <ng-container *ngIf="leave.leaveStatus && leave.leaveStatus==5" >
                  <label class="status_dec mb-0"> Declined </label>
              </ng-container> 
            <!-- <span style="color: #424242;">
              (Approved by 
              <ng-container *ngIf="leave.lastUpdatedBy && leave.lastUpdatedBy.firstName">
                  {{leave.lastUpdatedBy.firstName}}
              </ng-container>    
              <ng-container *ngIf="leave.lastUpdatedBy && leave.lastUpdatedBy.lastName">
                  {{leave.lastUpdatedBy.lastName}}
              </ng-container>
              )</span> -->
          </td>
        </tr>
        <tr>
          <td class="pl-0 leavel_fs">Leave Type</td>
          <td class="profile_td text-left text-truncate leavel_fs">: 
              <ng-container *ngIf="leave.leaveType && leave.leaveType=='1'" >
                  Sick Leave
              </ng-container>
              <ng-container *ngIf="leave.leaveType && leave.leaveType=='2'" >
                  Annual Leave
              </ng-container>
              <ng-container *ngIf="leave.leaveType && leave.leaveType=='3'" >
                  Parental Leave
              </ng-container>
              <ng-container *ngIf="leave.leaveType && leave.leaveType=='4'" >
                Long Service Leave
              </ng-container>
              <ng-container *ngIf="leave.leaveType && leave.leaveType=='5'" >
                Time in Lieu
              </ng-container>
              <ng-container *ngIf="leave.leaveType && leave.leaveType=='6'" >
                Unpaid Leave
              </ng-container>
              <ng-container *ngIf="leave.leaveType && leave.leaveType=='7'" >
                Study Leave
              </ng-container>
              <ng-container *ngIf="leave.leaveType && leave.leaveType=='8'" >
                Bereavement Leave
              </ng-container>
              <!-- <ng-container *ngIf="leave.leaveType && leave.leaveType==6" >
                  Community Leave
              </ng-container>  -->
          </td>
        </tr>
        <tr>
          <td class="pl-0 leavel_fs">Date From</td>
          <td class="profile_td text-left text-truncate leavel_fs">: {{ leave.startDate+'Z' | date:"dd/MM/yyy" }}</td> <!-- mediumDate -->
        </tr>
        <tr>
          <td class="pl-0 leavel_fs">Date To</td>
          <td class="profile_td text-left text-truncate leavel_fs">: {{ leave.endDate+'Z' | date:"dd/MM/yyy" }}</td> <!-- mediumDate -->
        </tr>
        <tr>
          <td class="pl-0 leavel_fs">Days out of office</td>
          <td class="profile_td text-left text-truncate leavel_fs">: {{ (leave.endDate | amDifference : leave.startDate : 'days')+1 }}</td>
        </tr>
        <tr>
          <td class="pl-0 leavel_fs">Extra Details</td>
          <!-- <td class="profile_td text-left text-truncate leavel_fs" data-toggle="tooltip" title="{{leave.extraDetails}}" >: 
              {{ leave.extraDetails | limitTo : '30' }}
          </td> -->
          <td class="profile_td text-left text-truncate leavel_fs"  data-toggle="tooltip" title="{{leave.extraDetails}}" >: 
              <ng-container *ngIf="leave.extraDetails"> {{ leave.extraDetails | limitTo : '30' }} </ng-container> 
              <ng-container *ngIf="leave.extraDetails == '' || leave.extraDetails== null "> -- </ng-container> 
            </td>
        </tr>
        
        <tr>
          <td class="pl-0 leavel_fs">Leave Created By</td>
          <td class="profile_td text-left text-truncate leavel_fs">: 
              <ng-container *ngIf="leave.createdBy && leave.createdBy.firstName">
                  {{leave.createdBy.firstName}}
              </ng-container>    
              <ng-container *ngIf="leave.createdBy && leave.createdBy.lastName">
                  {{leave.createdBy.lastName}}
              </ng-container> 
            | 
            {{ leave.createdAt+'z' | date:"dd/MM/yyy, h:mm a" }} 
          </td> <!-- medium -->
        </tr>
        <tr>
          <td class="pl-0 text-truncate leavel_fs">Last Updated By</td>
          <td class="profile_td text-left text-truncate leavel_fs">: {{ leave.lastUpdatedBy.firstName }} {{ leave.lastUpdatedBy.lastName }} |  {{ leave.lastUpdatedDate+'z' | date:"dd/MM/yyy, h:mm a" }}</td> <!-- medium -->
        </tr>
      </tbody>
    </table>

  </div>
</ng-template>

<app-footer></app-footer>
