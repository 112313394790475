import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import {MfaVerifyModel } from "../../models/mfaVerify.model";
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class MfaserviceService {
  AuthUrl : String;
  mfaVerifyValue:MfaVerifyModel;
  private MfaRegisterDataUpdate = new Subject<any>();
  private VerifyMfaDataUpdate = new Subject<any>();
  private ResetMfaDataUpdate = new Subject<any>();

  constructor(private http:HttpClient,private cookieService: CookieService) { 
    this.AuthUrl = environment.getAuthURL;
    
  }
  
  geMfaRegisterData(){
var userId=this.cookieService.get('CurrentUserId');
    const params = new HttpParams().set('Id', userId); 
    this.http.get<{Data:any}>(this.AuthUrl+"/User/GetMFACredentials",{params})
     .subscribe((res) => {
    this.MfaRegisterDataUpdate.next(res);
     });
  
  }

  public VerifyPin(code,isInitialVerification){

 var userId=this.cookieService.get('CurrentUserId').trim();
   
   
    console.log(this.mfaVerifyValue);
   
    this.http.post<any>(this.AuthUrl +'/User/VerifyMFACode',{userid:userId,code:code,isInitialVerification:isInitialVerification}).subscribe((res) => {
      this.VerifyMfaDataUpdate.next(res);
      console.log(res);
      
       });
  }


  public restMfa(){
    var userId=this.cookieService.get('CurrentUserId').trim();
    this.http.patch<any>(this.AuthUrl +'/User/ResetMFACredentials?Id='+userId," ").subscribe((res) => {
      this.ResetMfaDataUpdate.next(res);
      console.log(res);
      
       });
  
   
  }







  getMfaListener() {
    return this.MfaRegisterDataUpdate.asObservable();
  }
  verifyPinListener() {
    return this.VerifyMfaDataUpdate.asObservable();
  }

  resetMfaListener() {
    return this.ResetMfaDataUpdate.asObservable();
  }
  ngDestroy() {
   // this.VerifyMfaDataUpdate.next();
  }
  

}
