
        <app-header></app-header>
        <div class="container-fluid pl-4 pr-4 mb-5">

           

              <div class="row">
                  <div class="col-md-2" style="margin-top: 104px;">
                        <app-sidebar></app-sidebar>
                  </div>
                  <div class="col-md-10 doc_sm_tb">
                        <div class="content-header row mt-4 mb-2 header_namer">     
                                <div class="content-header-left col-md-6 col-12">
                                  <h3 class="content-header-title mb-0 font-weight-normal">{{menuName}}</h3>
                                  <div class="breadcrumb-wrapper">
                                      <ol class="breadcrumb bredcrumb_new">
                                        <li class="breadcrumb-item"><a href="/dashboard?business={{business_id}}">{{menuName}}</a>
                                        </li>
                                        <!-- <li class="breadcrumb-item"><a href="#">DOCUMENTS</a>
                                        </li> -->
                                        <li class="breadcrumb-item active">Trash
                                        </li>
                                      </ol>
                                    </div>
                                </div>
                                <!-- <div class="content-header-right text-md-right col-md-6 col-12 mt-2">
                                  <div class="btn-group">
                                    <button type="button" class="btn btn-round font-weight-bold clr_button upload_button"> <i class="fa fa-plus fa-1x" aria-hidden="true"></i>
                                      &nbsp; CREATE POST</button>
                                  </div>
                                </div> -->
                              </div>
                        <div class="table-responsive mb-5" style="background-color: white;padding: 20px 0px 0px;">
                                <span class="text-left table_text">{{menuName}} Deleted Items</span>
                    <table *ngIf="isLoaded" datatable [dtOptions]="dtOptions" class="table " style="width: 100%;" id="mydatatable">
                        <thead>
                            <tr>
                                <th scope="col" class="text-truncate">Document Name</th>
                                <th scope="col" class="text-truncate">Deleted Date</th>
                                <th scope="col" class="text-truncate">Deleted By</th>
                                <!-- <th scope="col">DELETED FROM</th> -->
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>

                        <ng-container *ngFor="let trash of trashList; let i = index">    
                            <tr > 
                                <td scope="row" class="table_datann text-truncate" title="{{ trash.name }}" style="padding-top: 13px; padding-bottom: 10px;">
                                    <ng-container *ngIf="trash.entityType !=2">
                                        <a href="javascript:void(0);" (click)="openViewdModal(template2, trash)" class="btn secondbtn pl-0 downimg "> 
                                            <ng-container *ngIf=" trash.entityType ==3"> 
                                                <img src="../../assets/img/document.png" alt="pdf" height="28">  
                                            </ng-container>
                                            <ng-container *ngIf=" trash.entityType ==2">
                                                <img src="../../assets/img/folder_new.png" alt="pdf" height="30">   
                                            </ng-container>
                                            <ng-container *ngIf=" trash.entityType ==4"> 
                                                <img src="../../assets/img/news_podcast.png" alt="pdf" height="30">   
                                            </ng-container>
                                            &nbsp; {{trash.name | limitTo : "100"}}
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="trash.entityType == 2">
                                        <ng-container *ngIf=" trash.entityType ==3"> 
                                            <img src="../../assets/img/document.png" alt="pdf" height="28">  
                                        </ng-container>
                                        <ng-container *ngIf=" trash.entityType ==2">
                                            <img src="../../assets/img/folder_new.png" alt="pdf" height="30">   
                                        </ng-container>
                                        <ng-container *ngIf=" trash.entityType ==4"> 
                                            <img src="../../assets/img/news_podcast.png" alt="pdf" height="30">   
                                        </ng-container>
                                        &nbsp; {{trash.name | limitTo : "100"}}
                                    </ng-container>
                                    
                                </td>
                                <td class="table_datan text-truncate">{{ trash.lastUpdatedDate+'Z' | date:"dd/MM/yyy" }}</td>  <!-- date:"mediumDate" -->
                                <td class="table_datan text-truncate">
                                    <ng-container *ngIf="trash.lastUpdatedBy && trash.lastUpdatedBy.firstName">
                                        {{trash.lastUpdatedBy.firstName}}
                                    </ng-container>    
                                    <ng-container *ngIf="trash.lastUpdatedBy && trash.lastUpdatedBy.lastName">
                                        {{trash.lastUpdatedBy.lastName}}
                                    </ng-container>
                                </td>
                                <!-- <td>Dallecort</td> -->
                                <td class="btn_table table_datan text-truncate" >
                                    <button  type="button" *ngIf=" trash.entityType !=4 && !(selectedMenu.isPublic && selectedMenu.isNoticesReadOnly)" (click)="openRestoreDialog(template1,trash)" class="btn secondbtn btntabel_icon "><i class="fa fa-undo" aria-hidden="true"></i></button>
                                    <button *ngIf="!(selectedMenu.isPublic && selectedMenu.isNoticesReadOnly)"  type="button" (click)="openDeleteDialog(template,trash)" class="btn btntabel_icon ml-2"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                                </td>
                            </tr>
                        </ng-container>

                        </tbody>
                    </table>

                    <table *ngIf="!isLoaded" datatable class="table " style="width: 100%;" id="mydatatable">
                            <thead>
                                <tr>
                                    <th scope="col">Document Name</th>
                                    <th scope="col">Deleted Date</th>
                                    <th scope="col">Deleted By</th>
                                    <!-- <th scope="col">DELETED FROM</th> -->
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                        <tbody>
                        </tbody>
                    </table>

                    </div>
                  </div>
              </div>


                
    </div>

    <ng-template #template>
        <div class="modal-body text-center">
                <h4 class="del_text font-weight-normal">Do you want to delete this item?</h4>
            </div>
            <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-5" >
                <button (click)="deleteDocument()" type="button" class="clr_button_delete font-weight-normal text-uppercase" style="font-size: 12px;">Delete</button> &nbsp; OR &nbsp;
                <button (click)="decline()" type="button" class="pl-0 pr-0 nb_can_btn text-uppercase"  data-dismiss="modal">Cancel</button>
            </div>
    </ng-template>  
    <!-- clr_button_delete font-weight-bold       clr_button trash_bn font-weight-bold -->

    <ng-template #template1>
        <div class="modal-body text-center">
                <h4 class="del_text font-weight-normal">Do you want to restore this item?</h4>
            </div>
            <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-5" >
                <button (click)="restoreFromThrash()" type="button" class="clr_button trash_bn font-weight-normal text-uppercase" style="font-size: 12px;">Restore</button> &nbsp; OR &nbsp;
                <button (click)="decline()" type="button" class="pl-0 pr-0 nb_can_btn text-uppercase"  data-dismiss="modal">Cancel</button>
            </div>
    </ng-template>


    <ng-template #template2>
    
        <div class="modal-header border-bottom-0 pb-0">
            <button type="button" class="close close_edit" (click)="decline()" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
        </div>
            <div class="modal-body p-0">
                    
                <div class="card_b pb-3" style="word-wrap: break-word;">
                    <h5 class="font-weight-normal mb-0 ">{{viewTrash.name}}</h5>  
                    <span class="d-flex mt-1 font-weight-normal fonts ">EXPIRED BY 
                        <ng-container *ngIf="viewTrash.lastUpdatedBy && viewTrash.lastUpdatedBy.firstName">
                            {{viewTrash.lastUpdatedBy.firstName}}
                        </ng-container>    
                        <ng-container *ngIf="viewTrash.lastUpdatedBy && viewTrash.lastUpdatedBy.lastName">
                            {{viewTrash.lastUpdatedBy.lastName}}
                        </ng-container>&nbsp;|&nbsp; 
                        <ng-container *ngIf="viewTrash && viewTrash.entityType == 3 && isLoaded2 ">
                            <span class="fonts_date">{{ document.lastModified+'Z' | date:"dd/MM/yyy" }}</span> <!-- date:"mediumDate" --> 
                        </ng-container>  
                        <ng-container *ngIf="viewTrash && viewTrash.entityType == 4 && isLoaded3 ">
                            <ng-container *ngIf="noticeBoard.isArchived">
                                <span class="fonts_date">{{ noticeBoard.lastUpdatedDate+'Z' | date:"dd/MM/yyy" }}</span> <!-- date:"mediumDate" -->
                            </ng-container>
                            <ng-container *ngIf="!noticeBoard.isArchived">
                                <span class="fonts_date">{{ noticeBoard.expiredDate+'Z' | date:"dd/MM/yyy" }}</span> <!-- date:"mediumDate" -->
                            </ng-container>
                        </ng-container>  
                        <!-- <span class="borders "></span> -->
                    </span>   
                    <span class="borders "></span>
                    <!-- <a href="javascript:void(0);" download (click)="downloadFile(viewNotice)" >Download document</a> -->
                    <!-- <button (click)="downloadFile(viewNotice)" >Download document</button> -->
                        
                    <!-- <div [innerHTML]="viewNotice.description | sanitizeHtml" class="pt-4 clearfix"></div>    -->
                    <br/>

                    <ng-container *ngIf="viewTrash && viewTrash.entityType == 3 && isLoaded2 ">
                        <span class="d-flex mt-1 font-weight-normal fonts ">CREATED BY 
                            <ng-container *ngIf="document.createdBy && document.createdBy.firstName">
                                {{document.createdBy.firstName}}
                            </ng-container>    
                            <ng-container *ngIf="document.createdBy && document.createdBy.lastName">
                                {{document.createdBy.lastName}}
                            </ng-container>&nbsp;|&nbsp;   
                            <span class="fonts_date">{{ document.createdAt+'Z' | date:"dd/MM/yyy" }}</span> <!-- date:"mediumDate" -->
                            <!-- <span class="borders "></span> -->
                        </span> 
                        <p></p>
                        <ng-container *ngIf="document && document.s3Key">
                            <a href="javascript:void(0);" title="{{ document.fileName }}" download (click)="downloadTrashDocument(document)" class="down_attachment"><i class="fa fa-download" aria-hidden="true"></i>
                                    &nbsp; {{document.fileName | limitTo : '150'}}</a> <br/> <!--Download Attachment ({{document.fileName | limitTo : '80'}})-->
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="viewTrash && viewTrash.entityType == 4 && isLoaded3 ">
                        
                        <img class="card-img-top grid_img" [src]="noticeBoard.urlKey" (error)="noticeBoard.urlKey = '../../../assets/img/default_cover_image.jpg'" alt="Card image cap">
                        <span class="d-flex mt-1 font-weight-normal fonts ">CREATED BY 
                            <ng-container *ngIf="noticeBoard.createdBy && noticeBoard.createdBy.firstName">
                                {{noticeBoard.createdBy.firstName}}
                            </ng-container>    
                            <ng-container *ngIf="noticeBoard.createdBy && noticeBoard.createdBy.lastName">
                                {{noticeBoard.createdBy.lastName}}
                            </ng-container>&nbsp;|&nbsp;   
                            <span class="fonts_date">{{ noticeBoard.createdDate+'Z' | date:"dd/MM/yyy" }}</span> <!-- date:"mediumDate" -->
                            <!-- <span class="borders "></span> -->
                        </span> 
                        <div [innerHTML]="noticeBoard.description | sanitizeHtml" class="pt-4 clearfix"></div>
                        
                        <ng-container *ngIf="noticeBoard && noticeBoard.attachments && noticeBoard.attachments.length >0">
                            <hr *ngIf="noticeBoard.attchementURL && noticeBoard.attchementURL != null ">
                            <ng-container *ngFor="let attachment of noticeBoard.attachments; let j = index">
                                <a href="javascript:void(0);" title="{{ attachment.name }}" *ngIf="attachment.url && attachment.url != null " download (click)="downloadTrashFile(attachment)" class="down_attachment"><i class="fa fa-download" aria-hidden="true"></i>
                                    &nbsp; {{attachment.name | limitTo : '150'}}</a> <br/> <!--Download Attachment ({{attachment.name | limitTo : '80'}})-->
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="noticeBoard && noticeBoard.attachments && noticeBoard.attachments.length ==0">
                            <div>No Attachment</div>
                        </ng-container>
                    </ng-container>

                </div>
            </div> 
    </ng-template>
<app-footer></app-footer>
