export class MfaVerifyModel {
    public userId: string;
    public code:string;
    public isInitialVerification:boolean;

   
 }
 
 export class MultifactorVerifyModel extends MfaVerifyModel{
    
 }
 