import { Component, OnInit, EventEmitter, TemplateRef } from '@angular/core';
//import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { DocumentEditModel } from '../../shared/models/document.model';
import { Router, ActivatedRoute } from '@angular/router'; 
import { ProfileService } from '../profile.service';
import { FileUploadService } from '../../file-upload/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { DocumentResponse, DocumentAddResponse, FileUploadResponse, UserProfileResponse } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileUploadAddModel } from 'src/app/shared/models/file-upload.model';
import { UserAgentAddModel } from '../../shared/models/agent.model';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../../shared/models/user.model';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  title;
  data;
  errors;
  editProfileForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  folder_id: string;
  uploadDocumentEnable:boolean = true;
  addUrlEnable:boolean = true;
  disableDocumentType:boolean = false;
  acceptDocType : string;
  documentLabel = "Choose file";
  fileUploadAddModel:FileUploadAddModel;
  attachmentAddStyle:boolean = false;
  agentModel : UserAgentAddModel ;
  domain: string;
  agent:any;

  constructor(
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private profileService: ProfileService,
    private authService: AuthService,
    public modalRef: BsModalRef,
    private fileUploadService: FileUploadService,
    private toastr: ToastrService,    
    private modalService: BsModalService,
    ) { 
      this.business_id = this.route.snapshot.queryParams['business']; 
      this.router.routeReuseStrategy.shouldReuseRoute = function() { 
        return false;
      };
    }

  ngOnInit() {
    this.Isvalid = true;    
    this.agentModel = new UserAgentAddModel();
    let user = JSON.parse(localStorage.getItem('currentUser'));
    this.agentModel = user;
    this.domain = environment.getAuthDomain;

    this.editProfileForm = this.formBuilder.group({  
      userName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required]],
      // prefferedName: ['', Validators.required],
      // primaryMobile: [''],
      // contactNumber: [''],
    });
  }

  get f() { return this.editProfileForm.controls; }

  onSubmit(){
    this.submitted = true;
      
    if (this.editProfileForm.invalid ) {
     return;
    }
    else{
      this.ngxService.start();
      this.profileService.updateProfile(this.agentModel)
      .subscribe((data:UserProfileResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.isSuccess){     
          if(data.error.message){
            this.errors = data.error.message;
          }
          else{
            this.errors = Utility.GetLocalErrorString(data.error.errorCode);
          }
          this.toastr.error('Error on updating profile.');      
        }
        else{
          localStorage.setItem('currentUser', JSON.stringify(data.user));
          localStorage.setItem('CurrentUserId', JSON.stringify(data.user.id));
          let host = this.domain;
          this.cookieService.set( 'CurrentUserId', data.user.id, 365,'/',host );
          let currentUserCookie = data.user;
          this.cookieService.set( 'currentUser', JSON.stringify(currentUserCookie), 365,'/',host );
          this.cookieService.set( 'firstName', currentUserCookie.firstName, 365,'/',host );
          this.cookieService.set( 'userName', currentUserCookie.firstName, 365,'/',host );
          this.cookieService.set( 'lastName', currentUserCookie.lastName, 365 ); 
          this.cookieService.set( 'userType', JSON.stringify(currentUserCookie.userType), 365,'/',host ); 

          let user = new User();
          user.userName = currentUserCookie.firstName;
          user.userType = 1;
          
          this.toastr.success('Profile updated successfully.');
          this.router.navigate(['account'], { queryParams: {business: this.business_id }}); //folder_name
        }      
      },err=>{   
        console.log(err);
        this.ngxService.stop();
        this.toastr.error('Error on updating data.');
      });
          
   }
   
   }

  openPasswordModal(template: TemplateRef<any>,agent) {
    this.agent = agent;
    this.modalRef = this.modalService.show(template, { 
      class: 'modal-lg', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }

  onSubmitPassword(){
    
  }

}
