<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document"> -->

 <form [formGroup]="addNoticeForm" novalidate class="form " (ngSubmit)="onSubmit()" >
    <div class="modal-content">
    <div class="modal-header border-bottom-0 pb-0">
        <button type="button" class="close" (click)="modalRehide()"> <span aria-hidden="true">&times;</span> </button>
    </div>
    <div class="modal-body pt-0 pb-0 pl-4 pr-4">
        <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">{{heading_title}}</h4>

        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Post Title</label>
                    <input type="text" required formControlName="title" [(ngModel)]='noticeBoardModel.title' class="form-control" placeholder="Type here">
                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required">Title is required</div>
                    </div>
                </div>

                

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group res_attach">
                            <label for="message-text" class="col-form-label d-flex font-weight-normal">Post Cover Image &nbsp; <span style="font-size: 11px; margin-top: 5px;">(Optional)</span></label>
                            <div class="custom-file">
                                <input formControlName="document" accept="{{acceptDocType}}" (change)="onFileChange($event)" type="file" [(ngModel)]="noticeBoardModel.document" class="custom-file-input" id="document" >
                                <i *ngIf="coverExist" (click)="removeCoverImage()" class="fa fa-close close_icon"></i>
                                <label class="custom-file-label d-flex text-truncate" [ngClass]="[imageAddStyle ? 'file-upload-color' : '']" for="validatedCustomFile">{{documentLabel}}</label>
                            </div>
                            <div *ngIf="submitted && f.document.errors" class="invalid-feedback">
                                <div *ngIf="f.document.errors.required">Cover Image is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="message-text" class="col-form-label d-flex font-weight-normal">Post Valid Through</label>
                            <div class="input-group">
                                    <!-- [minDate]="minDate" -->
                                     
                                <ng-container *ngIf="buttonText == 'PUBLISH NOW'" >
                                    <input type="text" [minDate]="minDate" required formControlName="expiredDate"  [(ngModel)]='noticeBoardModel.expiredDate' 
                                    bsDatepicker 
                                    placement="top"
                                    readonly="true" [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}" class="form-control border-right-0" placeholder="dd/mm/yyyy">
                                </ng-container> 
                                <ng-container *ngIf="buttonText != 'PUBLISH NOW'" >
                                        <input type="text" required formControlName="expiredDate"  [(ngModel)]='noticeBoardModel.expiredDate' 
                                        bsDatepicker
                                        placement="top"
                                        readonly="true" [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}" class="form-control border-right-0" placeholder="dd/mm/yyyy">
                                    </ng-container>       
                                <div class="input-group-prepend"> <span class="input-group-text" style="background: #ced4da;"><i class="fa fa-calendar"></i></span> </div>
                            </div>
                            <div *ngIf="submitted && f.expiredDate.errors" class="invalid-feedback">
                                <div *ngIf="f.expiredDate.errors.required">Valid through is required</div>
                                <div *ngIf="f.expiredDate.errors.bsDate">Valid through must be a future date</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- <label for="message-text" class="col-form-label d-flex font-weight-normal">Post Attachment &nbsp; <span style="font-size: 11px; margin-top: 5px;">(Optional)</span></label>
                        <file-pond #myPond
                            [formControlName]="postDocument"
                            [options]="pondOptions" 
                            [files]="pondFiles"
                            (oninit)="pondHandleInit()"
                            (onaddfile)="pondHandleAddFile($event)">
                        </file-pond> -->

                        <label for="message-text" class="col-form-label d-flex font-weight-normal">Post Attachments &nbsp; <span style="font-size: 11px; margin-top: 5px;">(Optional)</span></label>
                        <div class="custom-file">
                            <input formControlName="postDocuments" (change)="onPostDocumentChange($event)" type="file" [(ngModel)]="noticeBoardModel.postDocuments" accept=".pdf,.jpg,.jpeg,.png,.doc,.docx" multiple class="custom-file-input" id="postDocuments"  >
                            <!-- <i *ngIf="fileExist"  (click)="removeAttachment()" class="fa fa-close close_icon"></i> -->
                            <label class="custom-file-label d-flex text-truncate " [ngClass]="[attachmentAddStyle ? 'file-upload-color' : '']" for="validatedCustomFile">{{postDocumentLabel}}</label>
                        </div>

                        <div  class="multiple_uploads"> 
                            <ng-container *ngIf="files && files.length >0">
                                <ng-container *ngFor="let file of files; let i = index">
                                    <div class="multiple_uploads_item"> <i (click)="removeAttachment(i);" class="fa fa-times-circle" aria-hidden="true"></i> <span>{{ file.name }} </span></div>
                                </ng-container>
                            </ng-container>
                            
                            <ng-container *ngIf="noticeBoardModel && noticeBoardModel.attachments && noticeBoardModel.attachments.length >0">
                                <ng-container *ngFor="let attachment of noticeBoardModel.attachments; let j = index">
                                    <div class="multiple_uploads_item"> <i (click)="removeAttachmentLi(attachment);" class="fa fa-times-circle" aria-hidden="true"></i> <span>{{ attachment.name }} </span></div>
                                </ng-container>
                            </ng-container>
                            
                            <!-- <div class="multiple_uploads_item"> <i class="fa fa-times-circle" aria-hidden="true"></i> <span>Sample 02 </span></div> -->
                        </div>

                        <div *ngIf="submitted && f.postDocuments.errors" class="invalid-feedback">
                            <div *ngIf="f.postDocuments.errors.required">Post Attachment is required</div>
                        </div>
                    </div>
                </div>

               
                
                <!-- <div class="row">
                    <div class="form-group col-md-6 res_attach">
                        <label for="message-text" class="col-form-label d-flex font-weight-normal">Post Attachment &nbsp; <span style="font-size: 11px; margin-top: 5px;">(Optional)</span></label>
                        <div class="custom-file">
                            <input formControlName="postDocument" (change)="onPostDocumentChange($event)" type="file" [(ngModel)]="noticeBoardModel.postDocument" class="custom-file-input" id="postDocument"  >
                            <i *ngIf="fileExist"  (click)="removeAttachment()" class="fa fa-close close_icon"></i>
                            <label class="custom-file-label d-flex text-truncate " [ngClass]="[attachmentAddStyle ? 'file-upload-color' : '']" for="validatedCustomFile">{{postDocumentLabel}}</label>
                        </div>
                        <div *ngIf="submitted && f.postDocument.errors" class="invalid-feedback">
                            <div *ngIf="f.postDocument.errors.required">Post Attachment is required</div>
                        </div>
                    </div>
    
                    <div class="form-group col-md-6 res_attach">
                        <label for="message-text" class="col-form-label d-flex font-weight-normal">Post Cover Image &nbsp; <span style="font-size: 11px; margin-top: 5px;">(Optional)</span></label>
                        <div class="custom-file">
                            <input formControlName="document" accept="{{acceptDocType}}" (change)="onFileChange($event)" type="file" [(ngModel)]="noticeBoardModel.document" class="custom-file-input" id="document" >
                            <i *ngIf="coverExist" (click)="removeCoverImage()" class="fa fa-close close_icon"></i>
                            <label class="custom-file-label d-flex text-truncate" [ngClass]="[imageAddStyle ? 'file-upload-color' : '']" for="validatedCustomFile">{{documentLabel}}</label>
                        </div>
                        <div *ngIf="submitted && f.document.errors" class="invalid-feedback">
                            <div *ngIf="f.document.errors.required">Cover Image is required</div>
                        </div>
                    </div>
                </div> -->

                <!-- <file-pond #myPond 
                    [options]="pondOptions" 
                    [files]="pondFiles"
                    (oninit)="pondHandleInit()"
                    (onaddfile)="pondHandleAddFile($event)">
                </file-pond> -->
               
                
            </div>
            <div class="col-md-4">   
                <img src="{{imagePreview}}" width="343" style="height: 206px !important;width:343px !important; object-fit: cover; border: 2.5px dotted #cecece;" class="d-flex mt-3 img-fluid"> 
                <ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label for="message-text" class="col-form-label d-flex font-weight-normal">Post Description</label>
                    <ejs-richtexteditor formControlName="description" [(ngModel)]='noticeBoardModel.description' [toolbarSettings]='tools' placeholder="Insert text here.."></ejs-richtexteditor>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                        <div *ngIf="f.description.errors.required">Description is required</div>
                    </div>
                </div>
            </div>
        </div>

        </div>
    <div class="modal-footer justify-content-start border-top-0 nb-footer mb-3">
        <button class="btn clr_button upload_button font-weight-normal text-uppercase" style="font-size: 12px;">{{buttonText}}</button> &nbsp; OR &nbsp;
        <button type="button" (click)="modalRehide()" class="btn pl-0 pr-0 nb_can_btn text-uppercase" data-dismiss="modal">CANCEL</button>
    </div>
</div>
</form> 
<ngx-ui-loader  ></ngx-ui-loader>             
        <!-- </div>
    </div> -->

   