import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { DocumentEditModel, FolderSearchModel } from '../../shared/models/document.model';
import { Router } from '@angular/router'; 
import { FileUploadService } from '../../file-upload/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { FavouriteResponse, FolderSearchResponse, UpdateDocumentFavouriteResponse } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FavouritesService } from '../favourites.service';
import { FavouriteAddModel } from 'src/app/shared/models/favourite.model';
import { LibraryService } from 'src/app/library/library.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-add-favouite',
  templateUrl: './add-favouite.component.html',
  styleUrls: ['./add-favouite.component.css']
})
export class AddFavouiteComponent implements OnInit {

  favouriteModel : FavouriteAddModel ;
  name;
  data;
  errors;
  addFavouriteForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  folder_id: string;
  uploadDocumentEnable:boolean = true;
  disableDocumentType:boolean = false;
  acceptDocType : string;
  documentLabel = "Choose file";
  showUrlField:boolean = false; 
  showFilesearchField:boolean = false; 
  showOptions: boolean = false;

  isSidebarToggeled: boolean = false;
  searchLoaded: boolean = false;
  searchText:string = null;
  searchResults: FolderSearchModel[]=[];
  searchStr:any="";
  selectedMenu : any;
  documentId : any ="";
  
  constructor(
    private libraryService: LibraryService,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private favouriteService: FavouritesService,
    public modalRef: BsModalRef,
    private fileUploadService: FileUploadService,
    private toastr: ToastrService
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;   
    this.searchText = ""; 
    this.favouriteModel = new FavouriteAddModel();  
    this.favouriteModel.docType1 = '0';
    this.selectedMenu = JSON.parse(localStorage.getItem('selectedMenu'));
    // console.log(this.selectedMenu);

    if(this.data.favourite !=null){
      Object.assign(this.favouriteModel, this.data.favourite);
    }

    if(this.data.business_id !=null){
      this.business_id = this.data.business_id;
    }
    
    this.addFavouriteForm = this.formBuilder.group({  
      name: ['', Validators.required], 
      url: ['', Validators.required],
      docType1: [''],
      docType: ['', Validators.required],
      searchText1: [''],
    }); 
    
  }

  get f() { return this.addFavouriteForm.controls; }

onSubmit(){
 this.submitted = true;
 let name = this.favouriteModel.name;
 let url = this.favouriteModel.url;

//  console.log(666666666666666666);
//  console.log(this.favouriteModel);

  const urlControl = this.addFavouriteForm.get('url');
  //console.log("(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+");
  if (this.favouriteModel.docType1 && this.favouriteModel.docType1 == '1') {
    //var patt= new RegExp("^(?:http(s)?:\/\/)?[\\w\.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)*\+,;=.]+$");
    var patt = /^(?:http(s)?:\/\/)?[\w\.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)*\+,;=.]+$/
    if(!patt.test(this.favouriteModel.url)){
      this.addFavouriteForm.controls['url'].setErrors({'pattern': true});
    }
    // console.log(666666666666666666);
    // this.f.url.setValidators([Validators.pattern("(?:http(s)?:\\/\\/)?[\\w\\.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)*\\+,;=.]+")]);
    // console.log(5555555555555);
    this.favouriteModel.docType = 3
  }

  if (this.favouriteModel.docType1 && this.favouriteModel.docType1 == '2' && this.searchStr == "") {
    this.addFavouriteForm.controls['searchText1'].setErrors({'required': true});
  }

 if (this.addFavouriteForm.invalid || (name && name.trim()=="") || (url && url.trim()=="" || this.favouriteModel.docType1 == "0") ) {

  if(name && name.trim()==""){
    this.addFavouriteForm.controls['name'].setErrors({'required': true});
  }

  if(this.favouriteModel.docType1 == "0"){
    this.f['docType'].setErrors({'required': true});
  }

  if(url && url.trim()==""){
    this.addFavouriteForm.controls['url'].setErrors({'required': true});
  }

  // console.log(this.f);
  // console.log(this.addFavouriteForm.invalid);
  return;
}
else{

if(this.data.favourite !=null){


}else{  
  this.ngxService.start();
  this.favouriteModel.businessId = this.business_id;  
  
  // console.log(this.favouriteModel);
  if(this.documentId && this.documentId !=""){
    this.favouriteModel.documentId = this.documentId;
  }
  this.favouriteService.addFavourite(this.favouriteModel, this.business_id, this.documentId)
  .subscribe((data:FavouriteResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){     
      // if(data.error.message){
      //   this.errors = data.error.message;
      // }
      // else{
      //   this.errors = Utility.GetLocalErrorString(data.error.errorCode);
      // }
      this.toastr.error('Error on saving favourite link.');      
    }
    else{
      //this.documentId = "";

      if(this.documentId && this.documentId !=""){
        this.favouriteService.noticeIsfavourite(this.documentId, true).subscribe((data:UpdateDocumentFavouriteResponse)=>{
          this.ngxService.stop();
          if(data.error && !data.success){     
            if(data.error.message){
              this.errors = data.error.message;
            }      
            this.toastr.error('Error on saving favourite link.');      
          }
          else{
            this.toastr.success('Favourite link added successfully.');
            this.event.emit('OK');
            this.modalRef.hide();
          }
          
          },err=>{   
            console.log(err);
            this.ngxService.stop();
            this.toastr.error('Error on saving favourite link.');
          });
      }
      else{
        this.toastr.success('Favourite link added successfully.');
        this.event.emit('OK');
        this.modalRef.hide();
      }
      
      // this.toastr.success('Favourite link added successfully.');
      // this.event.emit('OK');
      // // console.log(this.event);
      // this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving favourite link.');
    });

  }

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addFavouriteForm.reset();
}

onFavouriteTypeChange(favType){
  this.documentId = "";
  this.favouriteModel.url= "";
  this.favouriteModel.docType = parseInt(this.favouriteModel.docType1);
  if(favType==1){    
    this.showUrlField = true;
    this.showFilesearchField = false;
  }else if(favType==2){
    this.showUrlField = false;
    this.showFilesearchField = true;
  }
  else{
    this.showUrlField = false;
    this.showFilesearchField = false;
  }
}

searchDocuments(searchText){  
  this.searchText = searchText;
  //this.searchResults = [];
  this.searchLoaded = false;
  if(searchText && searchText.length > 2 ){ // && this.showOptions
    this.libraryService.searchDocumentsForBusiness(this.business_id,searchText).pipe(delay(1000)).subscribe((data:FolderSearchResponse)=>{ 
      if(data.response){
        this.searchLoaded = true;
        this.searchResults = data.response;          
      }         
     },
     (err: HttpErrorResponse)=>{
       console.log("Error"+ err);
     });
  }         
}

setDocumentDocument(document){
  this.documentId = "";
  if(document){
    // console.log(document);
    this.favouriteModel.url= document.s3Key;
    this.favouriteModel.docType= document.docType;
    this.searchStr = document.fileName;
    this.showOptions = false;
    this.f['url'].setValue(document.s3Key);
    this.documentId = document.id;
  }
}

showOptionList(){
  this.showOptions = true;
  // console.log(this.showOptions);
}

}


