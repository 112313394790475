import { Component, OnInit, EventEmitter, TemplateRef, ViewChild   } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { NoticeBoardEditModel } from '../../shared/models/notice-board.model';
import { Router } from '@angular/router'; 
import { NoticeBoardService } from '../notice-board.service';
import { FileUploadService } from '../../file-upload/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { DocumentResponse, DocumentAddResponse, FileUploadResponse, NoticeBoardResponse } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FileUploadAddModel, FileUploadAddNoticeModel } from 'src/app/shared/models/file-upload.model';
import { UploadResult } from "../../file-upload/file-upload.service";


@Component({
  selector: 'app-add-notice',
  templateUrl: './add-notice.component.html',
  styleUrls: ['./add-notice.component.css']
})
export class AddNoticeComponent implements OnInit {

  noticeBoardModel : NoticeBoardEditModel ;
  heading_title;
  data;
  editData:any= null;
  errors;
  addNoticeForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  folder_id: string;
  uploadNoticeEnable:boolean = true;
  disableNoticeType:boolean = false;
  acceptDocType : string = ".jpg,.jpeg";
  documentLabel = "Choose file";
  postDocumentLabel = "Choose file";
  //fileUploadAddModel:FileUploadAddNoticeModel;
  fileUploadNotice:FileUploadAddNoticeModel;
  documentUploadNotice:FileUploadAddNoticeModel;
  minDate: Date;
  imagePreview : string = "../../../assets/img/blank_img.png";
  buttonText : string = "PUBLISH NOW";
  //action: number=1;
  attachmentAddStyle:boolean = false;
  imageAddStyle:boolean = false;
  fileExist:boolean = false;
  coverExist:boolean = false;
  notice:any;
  uploads: UploadResult[];
  files: File[] = [];
  deletedAttachmentList =[];

  @ViewChild('myPond', { static: true })  myPond: any;
 
  pondOptions = {
    class: 'my-filepond',
    multiple: true,
    labelIdle: 'Browse',
    acceptedFileTypes: 'image/jpeg, image/png'
  }
 
  pondFiles = []
 
  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private noticeBoardService: NoticeBoardService,
    public modalRef: BsModalRef,
    private fileUploadService: FileUploadService,
    private toastr: ToastrService
    ) {
      this.minDate = new Date();
      this.minDate.setDate(this.minDate.getDate()); // this.minDate.setDate(this.minDate.getDate() + 1);
      this.uploads = [];
     }

    public tools: object = {
      type: 'Expand',
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
             'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
             'LowerCase', 'UpperCase', '|', 'Undo', 'Redo', '|',
             'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
             'Indent', 'Outdent', '|', 'CreateLink','CreateTable','|', 'FullScreen']
     };
  

    ngOnInit() {
      this.Isvalid = true;    
      this.noticeBoardModel = new NoticeBoardEditModel();  
      this.noticeBoardModel.attachments =[];
  
      this.addNoticeForm = this.formBuilder.group({  
        description: ['', Validators.required],
        expiredDate: ['', Validators.required],
        title: ['', Validators.required],
        document: [''],  
        postDocuments: [''],  
      });

      if(this.data.notice !=null){
        this.editData = this.data.notice;
        Object.assign(this.noticeBoardModel, this.data.notice);
        this.noticeBoardModel.expiredDate= new Date(this.noticeBoardModel.expiredDate+'Z');
        if(this.noticeBoardModel.urlKey  != null){
          this.imagePreview = this.noticeBoardModel.urlKey;
        }
        this.buttonText = "UPDATE NOW";
        //console.log(this.noticeBoardModel.expiredDate);

        if(this.noticeBoardModel.urlKey && this.noticeBoardModel.urlKey !="../../../assets/img/default_cover_image.jpg"){
          this.documentLabel = this.noticeBoardModel.urlKey; //"Update existing cover image";
          this.coverExist = true;
        }

        if(this.noticeBoardModel.attchementName){
          this.postDocumentLabel = this.noticeBoardModel.attchementName;
          this.fileExist = true;
        }

      }
      else{
        const documentControl = this.addNoticeForm.get('document');
        //documentControl.setValidators([Validators.required]);
      }
  
      if(this.data.business_id !=null){
        this.business_id = this.data.business_id;
      }
      if(this.data.heading_title !=null){
        this.heading_title = this.data.heading_title;
      }
  
      //this.noticeBoardModel.docType = "0";
      //this.fileUploadAddModel = new FileUploadAddNoticeModel();  
      this.fileUploadNotice = new FileUploadAddNoticeModel();
      this.documentUploadNotice = new FileUploadAddNoticeModel();
    }

    get f() { return this.addNoticeForm.controls; }

onSubmit(){
 this.submitted = true;
 let date = this.noticeBoardModel.expiredDate;
 
 const documentControl = this.addNoticeForm.get('document');

//  var ddd = new Date(this.noticeBoardModel.expiredDate).toISOString();
//  console.log(this.f);
//  console.log("expire date ---------");
//  console.log(date);
//  console.log(this.noticeBoardModel.expiredDate);
//  console.log(ddd);
this.noticeBoardModel.expiredDate = new Date(date.setHours(23,59,0,0));
//  console.log(this.noticeBoardModel.expiredDate);
 
//  if (this.editData == null || this.editData == 'undefined') {
//     documentControl.setValidators([Validators.required]);
//   }

 let title = this.noticeBoardModel.title;
 let description = this.noticeBoardModel.description;

 if (this.addNoticeForm.invalid || (title && title.trim()=="") || (description && description.trim()=="") ) { //|| this.noticeBoardModel.docType == "0"

 if(title && title.trim()==""){
  this.addNoticeForm.controls['title'].setErrors({'required': true});
 }

 if(description && description.trim()==""){
  this.addNoticeForm.controls['description'].setErrors({'required': true});
 }

  // if(this.noticeBoardModel.docType == "0"){
  //   this.f['docType'].setErrors({'required': true});
  // }
  //console.log(this.f);
  //console.log(this.addNoticeForm.invalid);
  return;
}
else{
  this.noticeBoardModel.title = title.trim();

if(this.data.notice !=null){
  this.ngxService.start();

  //console.log(this.fileUploadNotice.file);
  this.uploadDocument(0);
  
  
}else{  
  this.ngxService.start();   
  //this.fileUploadNotice.fileName = this.documentLabel; //this.noticeBoardModel.fileName;
  this.uploadDocument(1);
  }

}

}

updateNotice(){
  // console.log("+++++========++++++");
  // console.log(this.noticeBoardModel);
  this.noticeBoardService.noticeEdit(this.noticeBoardModel, this.data.notice.id)
      .subscribe((data:NoticeBoardResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.success){     
          if(data.error.message){
            this.errors = data.error.message;
          }
          else{
            this.errors = Utility.GetLocalErrorString(data.error.errorCode);
          }
          this.toastr.error('Error on saving notice.');      
        }
        else{
          this.toastr.success('Notice updated successfully.');
          this.event.emit('OK');
        // console.log(this.event);
          this.modalRef.hide();
        }
        
        },err=>{   
          console.log(err);
          this.ngxService.stop();
          this.toastr.error('Error on saving notice.');
        });
}

createNotice(){
  // console.log(this.noticeBoardModel);
  this.noticeBoardService.noticeAdd(this.noticeBoardModel, this.business_id)
      .subscribe((data:NoticeBoardResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.success){     
          if(data.error.message){
            this.errors = data.error.message;
          }
          else{
            this.errors = Utility.GetLocalErrorString(data.error.errorCode);
          }
          this.toastr.error('Error on saving notice.');      
        }
        else{
          this.toastr.success('Notice added successfully.');
          this.event.emit('OK');
        // console.log(this.event);
          this.modalRef.hide();
        }
        
        },err=>{   
          console.log(err);
          this.ngxService.stop();
          this.toastr.error('Error on saving notice.');
        });
}

uploadDocument(create){
  if(this.files && this.files.length >0){
  // this.documentUploadNotice.businessId = this.business_id; 
  // this.documentUploadNotice.fileName = this.postDocumentLabel;

  this.uploadFiles(this.files).then(() => this.afterAttachmentsUpload(create));

    // this.uploadFiles(this.files).then(function(value) {
    //   console.log(this.uploads);
    //   this.noticeBoardModel.attachments = this.uploads;
    //   console.log(this.noticeBoardModel);
    //   this.uploadPreview(create); 
    // });


    // this.fileUploadService.uploadFileNotice(this.documentUploadNotice).subscribe((data:FileUploadResponse)=>{  
    //   if(data.error && !data.success){
    //     this.ngxService.stop();  
    //     this.toastr.error('Error on saving notice.');
    //   }
    //   else{
    //     this.noticeBoardModel.attchementURL = data.response;
    //     this.noticeBoardModel.attchementName = this.postDocumentLabel;//data.response; 
    //     this.noticeBoardModel.businessId = this.business_id;   
    //     this.uploadPreview(create);        
    //     }      
    //   },err=>{
    //     this.ngxService.stop();   
    //     this.toastr.error('Error on saving notice.');
    //   });

    }else{
      // this.uploadPreview(create); 
      this.afterAttachmentsUpload(create);
    }    
}

afterAttachmentsUpload(create){
  // console.log("create");
  // console.log(create);
  // console.log(this.uploads);
  // console.log(this.files);
  
  // console.log(this.noticeBoardModel);
  if(create){
    this.noticeBoardModel.attachments = this.uploads;       
  }
  else{
    //this.noticeBoardModel.attachments = this.uploads;
    this.files.length =0
    if(this.uploads && this.uploads.length >0){
      this.uploads.forEach(upl => {
        this.noticeBoardModel.attachments.push(upl);
      });
    }

    // console.log("===this.deletedAttachmentList---");
    // console.log(this.deletedAttachmentList);
    if(this.deletedAttachmentList && this.deletedAttachmentList.length >0 ){      
      this.deletedAttachmentList.forEach(attachment => {
        this.deleteAttachment(attachment);
      });
    }
    //this.noticeBoardModel.attachments = this.uploads;
  }
  this.uploadPreview(create); 
}

uploadPreview(create){
  // if(this.fileUploadNotice.file){
  //   this.fileUploadNotice.businessId = this.business_id; 
  //   this.fileUploadNotice.fileName = this.documentLabel;
  //   this.fileUploadService.uploadFileNotice(this.fileUploadNotice).subscribe((data:FileUploadResponse)=>{  
  //     if(data.error && !data.success){
  //       this.ngxService.stop();  
  //       this.toastr.error('Error on saving notice.');
  //     }
  //     else{
  //       this.noticeBoardModel.urlKey = data.response;
  //       this.noticeBoardModel.businessId = this.business_id;

  //       if(create){
  //         this.createNotice();       
  //       }
  //       else{
  //         this.updateNotice();
  //       }

  //       }      
  //     },err=>{
  //       this.ngxService.stop();   
  //       this.toastr.error('Error on saving notice.');
  //     });
  // }
  if(this.fileUploadNotice.file){
    this.fileUploadNotice.businessId = this.business_id; 
    this.fileUploadNotice.fileName = this.documentLabel;
    this.fileUploadService.uploadFileNotice(this.fileUploadNotice).subscribe((data:FileUploadResponse)=>{  
      // if(data.error && !data.success){
      if(!data.success){
        this.ngxService.stop();  
        this.toastr.error('Error on saving notice.');
      }
      else if(data.error && data.error.message){
        this.ngxService.stop();  
        this.toastr.error('Error on saving notice.');
      }
      else{
        this.noticeBoardModel.urlKey = data.response;
        this.noticeBoardModel.businessId = this.business_id;

        if(create){
          this.createNotice();       
        }
        else{
          this.updateNotice();
        }

        }      
      },err=>{
        this.ngxService.stop();   
        this.toastr.error('Error on saving notice.');
      });
  }
  else{
    this.noticeBoardModel.businessId = this.business_id; 
    if(create){
      this.createNotice();       
    }
    else{
      this.updateNotice();
    }
  }
}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addNoticeForm.reset();
}

onDocumentTypeChange(docType){
  this.acceptDocType = ".jpg,.jpeg";
  this.documentLabel = "Choose file";
}

onFileChange(event){ // Cover Image
  if(event.target.files[0]){
    this.ngxService.startLoader('loader-01');
  
    // console.log(this.fileUploadNotice);
    this.fileUploadNotice.file = <File>event.target.files[0];
    // console.log(this.fileUploadNotice.file);
    this.documentLabel = event.target.files[0].name;

    var reader = new FileReader(); 
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.imagePreview = <string>reader.result; 
    }

    this.imageAddStyle = true; 
    this.ngxService.stopLoader('loader-01');
    this.coverExist = true;
  } 
  else{
    if(!this.fileUploadNotice.file) {
      this.ngxService.startLoader('loader-01');
      this.documentLabel = "Choose file";
      this.imagePreview = "../../../assets/img/blank_img.png";
      this.fileUploadNotice.file = null;
      this.imageAddStyle = false;
      this.ngxService.stopLoader('loader-01');
      this.coverExist = false;
    } else {
      this.coverExist = true;
    }
  }  
}

removeCoverImage(){
  this.documentLabel = "Choose file";
    this.imagePreview = "../../../assets/img/blank_img.png";
    this.fileUploadNotice.file = null;
    this.imageAddStyle = false;
    this.ngxService.stopLoader('loader-01');
    this.coverExist = false;
    this.noticeBoardModel.urlKey = null
}

onPostDocumentChange(event){ // Post Attachment
  if(event.target.files && event.target.files.length >0){
    //this.files.length = 0;
    let files = event.target.files;

    // console.log(files);
    let i =0;
    //this.postDocumentLabel ="";
    for(let i=0;i<files.length;i++){
      //this.postDocumentLabel = this.postDocumentLabel+event.target.files[i].name+', ';
      //this.attachmentAddStyle = true;
      this.fileExist = true;
      this.files.push(files[i]);
    }

    // files.forEach(file => {
    //   //this.documentUploadNotice.file = <File>event.target.files[i];
    //   this.postDocumentLabel = this.postDocumentLabel+event.target.files[i].name+', ';
    //   this.attachmentAddStyle = true;
    //   this.fileExist = true;
    //   this.files.push(file);
    //   i++;
    // });

    // console.log(this.documentUploadNotice);
    // this.documentUploadNotice.file = <File>event.target.files[0];
    // console.log(this.documentUploadNotice.file);
    // this.postDocumentLabel = event.target.files[0].name;
    // this.attachmentAddStyle = true;
    // this.fileExist = true;
  } 
  else{
    this.postDocumentLabel = "Choose file";
    // this.imagePreview = "../../../assets/img/blank_img.png";
    this.documentUploadNotice.file = null;
    this.attachmentAddStyle = false;
    this.fileExist = false;
  }  

 }

 removeAttachment(j){
  // this.postDocumentLabel = "Choose file";
  // this.documentUploadNotice.file = null;
  // this.attachmentAddStyle = false;
  // this.fileExist = false;
  // this.noticeBoardModel.attchementName = null;
  // this.noticeBoardModel.attchementURL = null;
  // console.log(j);

  // const index = this.files.indexOf(j, 0);
  // console.log(index);
  // if (index > -1) {
  //   this.files.splice(index, 1);
  // }

  let i =0;
  let files=  this.files ;
  //this.files.length = 0;
  let new_files :File[] =[];

  //this.postDocumentLabel ="";
  for(let i=0;i<files.length;i++){
    if(i != j){
      //this.postDocumentLabel = this.postDocumentLabel+files[i].name+', ';
      //this.attachmentAddStyle = true;
      this.fileExist = true;
      new_files.push(files[i]);
    }      
  }

  this.files = new_files;

  // console.log(this.files);

  if(this.files.length ==0){
    this.postDocumentLabel = "Choose file";
    //this.documentUploadNotice.file = null;
    this.attachmentAddStyle = false;
    this.fileExist = false;
    this.noticeBoardModel.attchementName = null;
    this.noticeBoardModel.attchementURL = null;
  }

 }

 

 removeAttachmentLi(attachment){
  //this.ngxService.start();
  let deleted = this.noticeBoardModel.attachments.find(item => item.id == attachment.id);
  this.deletedAttachmentList.push(deleted);   
  this.noticeBoardModel.attachments = this.noticeBoardModel.attachments.filter(item => item.id !== attachment.id);       
 }

 deleteAttachment(attachment){
  this.fileUploadService.deleteAttachment(attachment.id).subscribe((data:any)=>{ 
    // this.ngxService.stop();  
    if(data.error && !data.success){
      //this.toastr.error('Error on removing attachment.');
    }
    else{
      
     // this.toastr.success('Attachment removed successfully.');     
      //this.router.navigate(['document'], { queryParams: {business: this.business_id }});   
    }            
    },
    (err: HttpErrorResponse)=>{
      // this.ngxService.stop();
     // this.toastr.error('Error on removing attachment.');
      console.log("Error"+ err.message);
    }); 
 }

 
//  @ViewChild('myPond') myPond: any;
pondHandleInit() {
  console.log('FilePond has initialised', this.myPond);
}

pondHandleAddFile(event: any) {
  // files
  // console.log('A file was added', event);
  // console.log(this.pondFiles);
  this.files.length = 0;
  let files = event.getFile;
  files.forEach(file => {
    this.files.push(file);
  });
  
  // this.uploadFiles(this.files).then(function(value) {
  //   console.log(value);
  // });

  // console.log('Files ', this.files);
}

public async uploadFiles( files: File[] ) : Promise<void> {
 
  // The given files collection is actually a "live collection", which means that
  // it will be cleared once the Input is cleared. As such, we need to create a
  // local copy of it so that it doesn't get cleared during the asynchronous file
  // processing within the for-of loop.
  for ( var file of Array.from( files ) ) {

      try {
        this.documentUploadNotice.businessId = this.business_id; 
        this.documentUploadNotice.fileName = file.name;
        this.documentUploadNotice.file = file;
        //this.fileUploadService.uploadFileNotice(this.fileUploadNotice)
          this.uploads.push(            
              await this.fileUploadService.uploadFileNoticeNew( this.documentUploadNotice )
          );         

      } catch ( error ) {
        if(file && file.name){
          this.toastr.error('Error uploading file '+file.name);
        }
        else{
          this.toastr.error('Error uploading file');
        }
        console.warn( "File upload failed." );
        console.error( error );
      }

  }

} 

}
