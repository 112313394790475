<form [formGroup]="addLeaveForm" novalidate class="form " (ngSubmit)="onSubmit()" >

<div class="modal-content">
    <div class="modal-header border-bottom-0 pb-0">
        <button type="button" class="close" (click)="modalRehide()" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
    </div>
    <div class="modal-body  pt-0 pb-0 pl-4 pr-4">
        <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">{{title}}</h4>
        <div class="form-group">
        <label for="message-text" class="col-form-label d-flex font-weight-normal">Leave Type</label>
       
        <select class="form-control " required name="leaveType" formControlName="leaveType" [(ngModel)]='leaveModel.leaveType' name="leaveType" id="leaveType" required>
            <option value="0">Please select</option>
            <option value="1">Sick Leave</option>
            <option value="2">Annual Leave</option>
            <option value="3">Parental Leave</option>
            <option value="4">Long Service Leave</option>
            <option value="5">Time in Lieu</option>
            <option value="6">Unpaid Leave</option>
            <option value="7">Study Leave</option>
            <option value="8">Bereavement Leave</option>
            <!-- <option value="6">Community Leave</option> -->

        </select>

        <div *ngIf="submitted && f.leaveType.errors" class="invalid-feedback">
                <div *ngIf="f.leaveType.errors.required">Leave type is required</div>
            </div>

        </div>


        <div class="form-group" style="margin-bottom: -15px;">
            <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Department</label>
               
            <div class="row">
                <div class="col-md-12 mb-4">

                    <ng-container *ngFor="let department of departmentList; let i = index">
                        <ng-container *ngIf="editLeave">
                        <label class="customradio mb-1 col-md-6" style="display: inline-block;"><span class="radiotextsty">{{ department.name }}</span>
                            <input type="radio" value="{{ department.id }}" [attr.disabled]="true" [(ngModel)]='leaveModel.departmentId' name="departmentId" formControlName="departmentId">
                            <span class="checkmark"></span>
                        </label>
                        </ng-container>  
                        <ng-container *ngIf="!editLeave">
                                <label class="customradio mb-1 col-md-6" style="display: inline-block;"><span class="radiotextsty">{{ department.name }}</span>
                                    <input type="radio" value="{{ department.id }}" [(ngModel)]='leaveModel.departmentId' name="departmentId" formControlName="departmentId">
                                    <span class="checkmark"></span>
                                </label>
                                </ng-container>    
                    </ng-container>                
                   
                    <!-- <label class="customradio mb-1 col-md-6"><span class="radiotextsty">Client Service</span>
                        <input type="radio" checked="checked" name="radio">
                        <span class="checkmark"></span>
                    </label> 
                    <label class="customradio mb-1 col-md-6"><span class="radiotextsty">Management/HOD</span>
                        <input type="radio" checked="checked" name="radio">
                        <span class="checkmark"></span>
                    </label> 
                    <label class="customradio mb-1 col-md-6"><span class="radiotextsty">Tax Accounting</span>
                        <input type="radio" checked="checked" name="radio">
                        <span class="checkmark"></span>
                    </label>  -->
                </div>

                <div *ngIf="submitted && f.departmentId.errors" class="invalid-feedback" style="margin-top: -26px; margin-left: 15px; margin-bottom: 33px;">
                    <div *ngIf="f.departmentId.errors.required">Department is required</div>
                </div>
                <!-- <div class="col-md-6">
                    <label class="customradio mb-1"><span class="radiotextsty">Business Accounting</span>
                        <input type="radio" name="radio">
                        <span class="checkmark"></span>
                    </label>
                    <label class="customradio mb-1"><span class="radiotextsty">Compliance</span>
                        <input type="radio" checked="checked" name="radio">
                        <span class="checkmark"></span>
                    </label> 
                    <label class="customradio mb-1"><span class="radiotextsty">ParaPlanning</span>
                        <input type="radio" checked="checked" name="radio">
                        <span class="checkmark"></span>
                    </label> 
                </div>				        -->
			</div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="message-text" class="col-form-label d-flex font-weight-normal">From</label>
                    <div class="input-group">
                            <!-- [minDate]="minDate" -->
                        <input type="text" required formControlName="startDate" [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}" [(ngModel)]='leaveModel.startDate' 
                        bsDatepicker 
                        placement="top"
                        readonly="true" class="form-control border-right-0 bg-white" placeholder="dd/mm/yyyy">
                        <div class="input-group-prepend"> <span class="input-group-text" style="background-color: #ced4da !important;"><i class="fa fa-calendar formicon"></i></span></div>
                    </div>
        
                    <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
                        <div *ngIf="f.startDate.errors.required">From date is required</div>
                        <div *ngIf="f.startDate.errors.bsDate">From date must be a future date</div>
                    </div>
                </div>
            </div>
        
            <div class="col-md-6">
                <div class="form-group">
                    <label for="message-text" class="col-form-label d-flex font-weight-normal">To</label>
                    <div class="input-group">
                            <!-- [minDate]="endDate" -->
                        <input type="text" formControlName="endDate" [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}" [(ngModel)]='leaveModel.endDate' 
                        bsDatepicker 
                        placement="top"
                        readonly="true" class="form-control border-right-0 bg-white" placeholder="dd/mm/yyyy">
                        <div class="input-group-prepend"> <span class="input-group-text" style="background-color: #ced4da !important;"><i class="fa fa-calendar formicon"></i></span> </div>
                    </div>
        
                    <div *ngIf="submitted && f.endDate.errors" class="invalid-feedback">
                        <div *ngIf="f.endDate.errors.required">To date is required</div>
                        <div *ngIf="f.endDate.errors.bsDate">To date must be a future date</div>
                        <div *ngIf="f.endDate.errors.minimum">To date must be greater than or equal to From date</div>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="form-group">
        <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Extra
            Details</label>
            <!-- (optional) -->
        <textarea type="text" maxlength="200" formControlName="extraDetails" [(ngModel)]='leaveModel.extraDetails' class="form-control" placeholder="Type here"></textarea>
            <div *ngIf="submitted && f.extraDetails.errors" class="invalid-feedback">
                <div *ngIf="f.extraDetails.errors.required">Extra details is required</div>
            </div>
        </div>

        <div class="modal-footer justify-content-start border-top-0 nb-footer pl-0 pb-4 float-left">

        <button class="btn clr_button upload_button font-weight-normal text-uppercase"
            style="font-size: 12px; border-radius: 100px;  padding: 7px 15px;">Submit Now</button> &nbsp; OR
        &nbsp;
        <button type="button" class="btn pl-0 pr-0 nb_can_btn text-uppercase" (click)="modalRehide()"
            data-dismiss="modal">Cancel</button>
        </div>

    </div>
    </div>
</form> 
<ngx-ui-loader  ></ngx-ui-loader>               