<app-header></app-header>
<div class="container-fluid pl-4 pr-4 mb-5">
    
    <div class="row">
        <div class="col-md-2" style="margin-top: 104px;">
            <app-sidebar></app-sidebar>
        </div>
        <div class="col-md-10">
            <div class="content-header row mt-4 mb-2 header_namer">
                <div class="content-header-left col-md-8 col-12">
                    <h3 class="content-header-title mb-0 font-weight-normal">{{menuName}}</h3>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb bredcrumb_new">
                            <li class="breadcrumb-item"><a href="/dashboard?business={{business_id}}">{{menuName}}</a> </li>
                            <li class="breadcrumb-item"><a href="/library?business={{business_id}}">Document Libraries</a> </li>
                            <li class="breadcrumb-item"><a href="/document?business={{business_id}}&folder={{parentFolderId}}&folder_name={{parentFolderName}}">{{parentFolderName}}</a> </li>
                            <!-- <ng-container *ngFor="let breadcrumb of breadcrumbArray; let i = index">
                                <li class="breadcrumb-item"><a href="/document?business={{breadcrumb.business}}&folder={{breadcrumb.folder}}&folder_name={{breadcrumb.folder_name}}">{{breadcrumb |json}}</a> </li>
                            </ng-container> -->
                            <li class="breadcrumb-item active"> <ng-container *ngIf="folder_name && folder_name.trim()!=''">
                                    {{folder_name  | limitTo : '60'}}
                                </ng-container> </li>
                        </ol>
                    </div>
                </div>
                <div class="content-header-right text-md-right col-md-4 col-12 mt-2 headerbn">
                    <!-- <div class="btn-group">
                        <button type="button" (click)="openAddLinkModal()"  class="btn btn-round font-weight-normal clr_button upload_button text-uppercase"> <i class="fa fa-plus fa-1x" aria-hidden="true"></i> &nbsp; Upload Link</button>
                    </div> -->
                    <div class="btn-group">
                        <ng-container *ngIf="selectedMenu && !selectedMenu.isNoticesReadOnly">
                            <button *ngIf="viewPortalPermission('Library_Create')" type="button" data-toggle="modal" (click)="openAddSubFolderModal()" class="btn btn-round font-weight-normal clr_button upload_button text-uppercase"> <i class="fa fa-plus fa-1x" aria-hidden="true"></i>
                                &nbsp; Create Sub Folder</button>
                            <button type="button" *ngIf="viewPortalPermission('Document_Create')" (click)="openAddModal()"  class="btn btn-round font-weight-normal clr_button upload_button text-uppercase"> <i class="fa fa-plus fa-1x" aria-hidden="true"></i> &nbsp; Upload File</button>
                        </ng-container>
                    </div>
                </div>
                
            </div>
            <div class="table-responsive tbl_res pt-3 doc_sm_tb">
                <div> 
                    <!-- <a  routerLink="/library" [queryParams]="{business: business_id}" class="text-left table_text_link"><i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp;
                        Back To Document Libraries List -->
                    <a href="javascript:void(0)" (click)="goBack()" class="text-left table_text_link"><i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp;Go Back
                                <!-- <ng-container *ngIf="folder_name && folder_name.trim()!=''">
                                    ({{folder_name  | limitTo : '60'}})
                                </ng-container> -->
                                
                    </a> 
                </div>


                <table *ngIf="isLoaded" datatable [dtOptions]="dtOptions" class="table doc-table" style="width: 100%;" id="mydatatable">
                    <thead>
                        <tr>
                            <th scope="col">Document Name</th>
                            <th scope="col">Uploaded Date</th>
                            <th scope="col">Last Modified</th>
                            <th scope="col">Uploaded By</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let document of documentList; let i = index">
                        <tr > 
                            <td class="table_new_data" title="{{ document.fileName }}"> 
                                <ng-container *ngIf="document && document.docType !=5">
                                    <a href="javascript:void(0);" download (click)="downloadDocument(document)" class="btn secondbtn pl-0 downimg ">
                                        <img *ngIf="document && document.docType==1" src="../../assets/img/news_pdf.png" alt="pdf" height="28">
                                        <img *ngIf="document && document.docType==4" src="../../assets/img/news_doc.png" alt="doc" height="28">
                                        <img *ngIf="document && document.docType==2" src="../../assets/img/news_img.png" alt="img" height="28">
                                        <img *ngIf="document && document.docType==3" src="../../assets/img/news_link.png" alt="link" height="28">
        
                                        &nbsp;  {{document.fileName | limitTo : '100'}} 
                                    </a>
                                </ng-container>
                                
                                <ng-container *ngIf="document && document.docType ==5">
                                    <a (click)="viewSubFolder(document)" href="javascript:void(0);"  class="btn secondbtn pl-0 downimg ">
                                        <img src="../../assets/img/folder_new.png" alt="folder" height="28"> &nbsp; {{ document.fileName | limitTo : '100' }}
                                    </a> 
                                </ng-container>
                                
                            </td>
                            <td class="table_datan">{{ document.createdAt+'Z'  | date:"dd/MM/yyy" }}</td> <!-- date:"mediumDate" -->
                            <!-- 20/06/2019 -->
                            <td class="table_datan">{{ document.lastModified | dateAgo }}</td>
                            <td class="table_datan">
                                <ng-container *ngIf="document.createdBy && document.createdBy.firstName">
                                    {{document.createdBy.firstName}}
                                </ng-container>    
                                <ng-container *ngIf="document.createdBy && document.createdBy.lastName">
                                    {{document.createdBy.lastName}}
                                </ng-container> 
                            </td>
                            <td class="btn_table table_datan" style="padding-top: 19px;">
                                <button type="button" *ngIf="viewPortalPermission('Library_Create') && document.docType ==5" (click)="openEdit(document)" class="btn secondbtn ml-2 btntabel_icon" ><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <ng-container *ngIf="document.docType && document.docType != 5">
                                    <a *ngIf="document.docType && document.docType != 3" href="javascript:void(0);">
                                        <a href="javascript:void(0);" download (click)="downloadDocument(document)" class="btn secondbtn ml-2 btntabel_icon_d"><i class="fa fa-download" aria-hidden="true"></i></a>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="selectedMenu && !selectedMenu.isNoticesReadOnly">
                                    <button type="button" *ngIf="viewPortalPermission('Document_Delete')" (click)="openDeleteDialog(template,document)" class="btn btntabel_icon ml-2"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                                </ng-container>
                                <!-- <div class="modal fade" id="exampledelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body text-center">
                                                <h4 class="del_text font-weight-bold">What do you want to do with this file?</h4> </div>
                                            <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-3">
                                                <button type="button" class="clr_button_delete font-weight-bold" style="font-size: 12px;">Delete</button>
                                                <button type="button" class="clr_button trash_bn font-weight-bold" style="font-size: 12px;">MOVE TO TRASH</button> &nbsp; OR &nbsp;
                                                <button type="button" class="pl-0 pr-0 nb_can_btn" data-dismiss="modal">CANCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                               
                                 <!-- <a href="javascript:void(0);">
                                    <button type="button" (click)="viewDocument(document)" class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                                </a>  -->
                            </td>
                        </tr>
                        </ng-container>
                        <!-- <tr>
                            <td scope="row"> <img src="../../assets/img/news_doc.png" alt="pdf" height="38"> &nbsp; MLC Approved List</td>
                            <td>20/06/2019</td>
                            <td>1 Day ago</td>
                            <td>John Roman</td>
                            <td class="btn_table">
                                <a href="">
                                    <button type="button" class="btn btntabel_icon"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                                </a>
                                <a href="">
                                    <button type="button" class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-download" aria-hidden="true"></i></button>
                                </a>
                                <a href="/document">
                                    <button type="button" class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row"> <img src="../../assets/img/news_pdf.png" alt="pdf" height="38"> &nbsp; Go-To-Meeting List 01</td>
                            <td>20/06/2019</td>
                            <td>1 Day ago</td>
                            <td>Tucker Emmanuel</td>
                            <td class="btn_table">
                                <a href="">
                                    <button type="button" class="btn btntabel_icon"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                                </a>
                                <a href="">
                                    <button type="button" class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-download" aria-hidden="true"></i></button>
                                </a>
                                <a href="/document">
                                    <button type="button" class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row"> <img src="../../assets/img/news_pdf.png" alt="pdf" height="38"> &nbsp; Go-To-Meeting List 02</td>
                            <td>20/06/2019</td>
                            <td>1 Day ago</td>
                            <td>Tucker Emmanuel</td>
                            <td class="btn_table">
                                <a href="">
                                    <button type="button" class="btn btntabel_icon"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                                </a>
                                <a href="">
                                    <button type="button" class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-download" aria-hidden="true"></i></button>
                                </a>
                                <a href="/document">
                                    <button type="button" class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row"> <img src="../../assets/img/news_pdf.png" alt="pdf" height="38"> &nbsp; Go-To-Meeting List 03</td>
                            <td>20/06/2019</td>
                            <td>20 hours ago</td>
                            <td>Tucker Emmanuel</td>
                            <td class="btn_table">
                                <a href="">
                                    <button type="button" class="btn btntabel_icon"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                                </a>
                                <a href="">
                                    <button type="button" class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-download" aria-hidden="true"></i></button>
                                </a>
                                <a href="/document">
                                    <button type="button" class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row"> <img src="../../assets/img/news_pdf.png" alt="pdf" height="38"> &nbsp; Go-To-Meeting List 03</td>
                            <td>20/06/2019</td>
                            <td>20 hours ago</td>
                            <td>Tucker Emmanuel</td>
                            <td class="btn_table">
                                <a href="">
                                    <button type="button" class="btn btntabel_icon"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                                </a>
                                <a href="">
                                    <button type="button"  class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-download" aria-hidden="true"></i></button>
                                </a>
                                <a href="/document">
                                    <button type="button" class="btn secondbtn ml-2 btntabel_icon"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                                </a>
                            </td>
                        </tr> -->
                       
                    </tbody>
                </table>

                <table datatable *ngIf="!isLoaded" class="table " style="width: 100%;" id="mydatatable">
                        <thead>
                            <tr>
                                <th scope="col">Document Name</th>
                                <th scope="col">Uploaded Date</th>
                                <th scope="col">Last Modified</th>
                                <th scope="col">Uploaded By</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                    <tbody>
                        <!-- <tr class="odd"><td valign="top" colspan="4" class="dataTables_empty">No data available in table</td></tr> -->
                    </tbody>
                    </table>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>

<ngx-ui-loader  ></ngx-ui-loader>

<ng-template #template>
    <div class="modal-body text-center">
        
            <h4 class="del_text font-weight-normal">What do you want to do with this file?</h4>
            <ng-container *ngIf="document && document.isFavorite">
                <span class="dev_fav"> Warning, This file is in favourite links, Deleting this will delete <br> favourite links related to this.</span>
            </ng-container>

        </div>
        <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-5" >
            <ng-container *ngIf= "document && document.docType !=5">
                <button (click)="deleteDocument()" type="button" class="clr_button_delete font-weight-normal text-uppercase" style="font-size: 12px;">Delete</button> &nbsp; 
            </ng-container>
            <button (click)="moveToThrash()" type="button" class="clr_button trash_bn font-weight-normal text-uppercase" style="font-size: 12px;">Move To Trash</button> &nbsp; OR &nbsp;
            <button (click)="decline()" type="button" class="pl-0 pr-0 nb_can_btn text-uppercase"  data-dismiss="modal">Cancel</button>
        </div>
        <!-- <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-3">
                <button type="button" class="clr_button_delete font-weight-bold" style="font-size: 12px;">Delete</button>
                <button type="button" class="clr_button trash_bn font-weight-bold" style="font-size: 12px;">MOVE TO TRASH</button> &nbsp; OR &nbsp;
                <button type="button" class="pl-0 pr-0 nb_can_btn" data-dismiss="modal">CANCEL</button>
            </div> -->
</ng-template>